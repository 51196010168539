import React from "react";
import PropTypes from "prop-types";
import styles from "./Primary.style";
import CircularLoader from "components/core/loaders/circular/Circular";
const PrimaryButton = ({
  type,
  text,
  style,
  cy,
  onClick,
  loading,
  disabled,
}) => {
  let loadingStyle = {};
  if (loading) {
    loadingStyle = styles.loading;
  }

  let disabledStyle = {};
  if (disabled) {
    disabledStyle = styles.disabled;
  }

  return (
    <button
      type={type}
      data-cy={cy}
      disabled={loading || disabled}
      style={{
        ...styles.button,
        ...style,
        ...loadingStyle,
        ...disabledStyle,
      }}
      onClick={onClick}
    >
      {text}
      {loading && (
        <CircularLoader sx={styles.loader} cy="primary-button-loader" />
      )}
    </button>
  );
};

PrimaryButton.defaultProps = {
  type: "button",
  style: undefined,
  cy: "submit-button",
  onClick: undefined,
  loading: false,
  disabled: false,
};

PrimaryButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.shape({}),
  cy: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default PrimaryButton;
