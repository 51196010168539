import React from "react";
import PropTypes from "prop-types";
import { Container, Box } from "@mui/material";
import ExternalLink from "components/core/links/External/ExternalLink";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import RFLogo from "assets/Logo-RF-Colour.svg";
import Img from "components/core/img/Img";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import styles from "./RegistrationResponseWidget.style";
export const RegistrationResponseWidget = ({
  href,
  heading,
  bodyText,
  icon,
}) => {
  return (
    <Container sx={styles.container}>
      <Box>
        <Img style={styles.logo} src={RFLogo} alt="Resilience First Logo" />
        <HeadingLarge cy="header"> {heading} </HeadingLarge>
        <Paragraph cy="body-text"> {bodyText} </Paragraph>
        {icon && (
          <img
            className="registration-response-widget__icon"
            src={icon}
            alt="response icon"
          />
        )}
      </Box>
      <Box sx={styles.buttonWrapper}>
        <ExternalLink
          cy="return-to-sign-in-button"
          href={href}
          theme="primaryButton"
        >
          Go to login
        </ExternalLink>
      </Box>
    </Container>
  );
};

RegistrationResponseWidget.defaultProps = {
  icon: "",
};

RegistrationResponseWidget.propTypes = {
  href: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  icon: PropTypes.string,
};
