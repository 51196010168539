import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import CloseButton from "components/core/buttons/Close/Close";
import Modal from "@mui/material/Modal";
import styles from "./Modal.style";

const BasicModal = ({
  buttonCy,
  buttonText,
  style,
  children,
  headingText,
  closeModal,
  updateModalState,
  buttonStyle,
  modalStyle,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleClose(false);
    updateModalState(false);
  }, [updateModalState, closeModal]);

  return (
    <Box sx={style}>
      <PrimaryButton
        onClick={handleOpen}
        cy={buttonCy}
        text={buttonText}
        style={buttonStyle}
      ></PrimaryButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styles.modal, ...modalStyle }} data-cy="modal-content">
          <Box sx={styles.header}>
            {headingText && <HeadingSmall text={headingText} />}
            <CloseButton onClick={handleClose} style={styles.closeButton} />
          </Box>
          {children}
        </Box>
      </Modal>
    </Box>
  );
};

BasicModal.defaultProps = {
  closeModal: undefined,
  updateModalState: () => {},
  buttonStyle: undefined,
  modalStyle: undefined,
};

BasicModal.propTypes = {
  closeModal: PropTypes.bool,
  updateModalState: PropTypes.func,
  buttonStyle: PropTypes.shape({}),
  modalStyle: PropTypes.shape({}),
};

export default BasicModal;
