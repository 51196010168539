import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import OrganisationScoresTable from "components/reporting/OrganisationScoresTable/OrganisationScoresTable";
import OrganisationsOverview from "components/reporting/OrganisationsOverview/OrganisationsOverview";
import ResilienceOverview from "components/reporting/ResilienceOverview/ResilienceOverview";
import styles from "./SuperAdminDashboard.style";
const HomePage = () => {
  return (
    <LoggedIn heading="Home">
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={styles.row}>
            <Grid item xs={12} lg={6} xl={8}>
              <ResilienceOverview />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <OrganisationsOverview />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrganisationScoresTable />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </LoggedIn>
  );
};

export default HomePage;
