import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import NewPasswordForm from "components/forms/auth/NewPassword/NewPasswordForm";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import qs from "qs";
import registrationService from "services/registration/registration-service";
import SinglePanel from "components/layouts/single-panel/SinglePanel.jsx";
import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";
import Img from "components/core/img/Img";
import styles from "./CompleteRegistration.style";
import { GlobalContext } from "context/GlobalContext";
export const CompleteRegistrationPage = () => {
  const [token, setToken] = useState("false");
  const history = useHistory();
  const location = useLocation();
  const globalContext = useContext(GlobalContext);

  const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });
  let autoLogin = qsToken.autoLogin;

  useEffect(() => {
    async function validateAndSetToken() {
      // Redirect to invalid token page if no token in query params.
      const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (!qsToken.token) {
        history.push("/invalid-registration-token");
        // we return a clean up function to stop further state changes
        return () => {};
      }

      // Redirect to invalid token page if the token is not valid
      const { valid } = await registrationService.validateToken(qsToken.token);

      if (!valid) {
        history.push("/invalid-registration-token");
        return () => {};
      }

      // All appears OK - Set the token!
      setToken(qsToken.token);
    }

    validateAndSetToken();
  }, [setToken, location.search, history]);

  const setPasswordHandler = async (password) => {
    let regResponse;

    try {
      regResponse = await registrationService.completeRegistration(
        token,
        password,
        autoLogin
      );
    } catch (registrationError) {
      return registrationError;
    }

    if (autoLogin === "true") {
      await globalContext.automaticLoginHandler(regResponse.user);
      return window.location.replace("/");
    } else {
      // Password setting successful, redirect to registration completed.
      return history.push(
        `/registration-completed?signInLink=${regResponse.signInLink}`
      );
    }
  };

  const submitButtonText =
    autoLogin === "true" ? "Confirm and Login" : "Confirm";

  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <Container>
          <Img style={styles.logo} src={RFLogo} alt="Resilience First Logo" />
          <HeadingLarge cy="header">Create Password</HeadingLarge>
          <NewPasswordForm
            token={token}
            onComplete={setPasswordHandler}
            submitButtonText={submitButtonText}
          />
        </Container>
      }
    />
  );
};
