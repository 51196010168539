import reducers from "reducers/form-errors-reducer";
//import errorCodes from "lib/error-codes";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    //  const errorData = err.data;

    return reducers.createFormErrorsHandler(
      "note",
      "There was a problem adding a note",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
