import api from "lib/http";
import _ from "lodash";
const createAnswerSet = async (orgId, questionnaireId) => {
  const result = await api(
    "post",
    `answers/new/organisation/${orgId}/questionnaire/${questionnaireId}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const getAnswerSet = async (orgId, queryParams = {}) => {
  const wheres = [];
  if (_.has(queryParams, "questionnaireId")) {
    wheres.push(`questionnaireId=${queryParams.questionnaireId}`);
  }

  if (_.has(queryParams, "answerId")) {
    wheres.push(`id=${queryParams.answerId}`);
  }

  let query = "";
  if (wheres.length) {
    query = `?${wheres.join("&")}`;
  }

  const result = await api(
    "get",
    `answers/organisation/${orgId}${query}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const patchAnswerSet = async (answerId, orgId, data = {}) => {
  const payload = {};

  if (_.has(data, "complete")) {
    payload.complete = data.complete;
  }

  if (_.has(data, "answers")) {
    payload.answers = data.answers;
  }

  const result = await api(
    "patch",
    `answers/${answerId}/organisation/${orgId}`,
    payload,
    true,
    true,
    true
  );
  return result.data;
};

const service = {
  getAnswerSet,
  createAnswerSet,
  patchAnswerSet,
};

export default service;
