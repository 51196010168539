import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import PrimaryButton from "components/core/buttons/Primary/Primary";

const ExportCSV = ({ text, headers, filename, dataProvider, pageOnly, cy }) => {
  const [data, setData] = useState([]);
  const csvLinkRef = useRef();
  const onDownload = async () => {
    setData(await dataProvider(pageOnly));
    csvLinkRef.current.link.click();
  };

  return (
    <>
      <PrimaryButton
        onClick={async () => {
          await onDownload();
        }}
        cy={cy}
        text={text}
      />
      {data.length > 0 && (
        <CSVLink
          headers={headers}
          filename={filename}
          data={data}
          ref={csvLinkRef}
        />
      )}
    </>
  );
};

export default ExportCSV;
