import React, { useReducer, useState } from "react";
import reducers from "reducers/form-errors-reducer";
import PropTypes from "prop-types";
import HeadingVerySmall from "components/core/typography/headings/form/HeadingForm";
import Input from "components/core/form/Input/Input";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "./CreateNewOrganisation.style";
import organisationService from "services/organisation/organisation-service";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./CreateNewOrg.error.handler";
const intialErrorState = {
  email: null,
  organisation: null,
  website: null,
  firstName: null,
  lastName: null,
};

const NewOrganisation = ({ onComplete }) => {
  const [loading, updateLoading] = useState(false);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );
  const onSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);
    try {
      const orgData = {
        email: e.target.email.value,
        organisation: e.target.organisation.value,
        website: e.target.website.value,
        firstName: e.target.firstName.value,
        lastName: e.target.lastName.value,
      };

      await organisationService.createOrganisation(orgData);
      onComplete();
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
    updateLoading(false);
  };
  return (
    <>
      <HeadingVerySmall text="Contact Details" />
      <form onSubmit={onSubmit} style={styles.form}>
        <Input
          id="organisation"
          cy="organisation-field"
          label="Company Name"
          autoComplete="organization"
          required
          sx={styles.input}
          error={errorState.organisation ? true : false}
          onChange={() =>
            reducers.clearFieldError("organisation", errorState, errorDispatch)
          }
          helperText={errorState.organisation}
          inputProps={{ maxLength: 50 }}
        />
        <Input
          id="website"
          cy="website-field"
          label="Website"
          autoComplete="url"
          sx={styles.input}
          error={errorState.website ? true : false}
          onChange={() =>
            reducers.clearFieldError("website", errorState, errorDispatch)
          }
          helperText={errorState.website}
          inputProps={{ maxLength: 255 }}
        />
        <Input
          id="firstName"
          cy="first-name-field"
          label="Primary First Name"
          autoComplete="given-name"
          required
          sx={styles.input}
          error={errorState.firstName ? true : false}
          onChange={() =>
            reducers.clearFieldError("firstName", errorState, errorDispatch)
          }
          helperText={errorState.firstName}
          inputProps={{ maxLength: 255 }}
        />
        <Input
          id="lastName"
          cy="last-name-field"
          label="Primary Last Name"
          autoComplete="family-name"
          required
          sx={styles.input}
          error={errorState.lastName ? true : false}
          onChange={() =>
            reducers.clearFieldError("lastName", errorState, errorDispatch)
          }
          helperText={errorState.lastName}
          inputProps={{ maxLength: 255 }}
        />
        <Input
          id="email"
          cy="email-field"
          type="email"
          label="Primary Email"
          autoComplete="email"
          required
          sx={styles.input}
          error={errorState.email ? true : false}
          onChange={() =>
            reducers.clearFieldError("email", errorState, errorDispatch)
          }
          helperText={errorState.email}
          inputProps={{ maxLength: 255 }}
        />
        <PrimaryButton
          type="submit"
          text="Add Company"
          loading={loading}
          style={styles.button}
        />
      </form>
    </>
  );
};

NewOrganisation.defaultProps = {
  onComplete: undefined,
};

NewOrganisation.propTypes = {
  onComplete: PropTypes.func,
};
export default NewOrganisation;
