import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RaisedBox from "components/core/raised-box/RaisedBox";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import HeadingVerySmall from "components/core/typography/headings/very-small/HeadingVerySmall";
import Bold from "components/core/typography/bold/Bold";
import organisationService from "services/organisation/organisation-service";
import CircularLoader from "components/core/loaders/circular/Circular";
import styles from "./Info.style";

const makeSizeString = (size) => {
  if (size === "medium") {
    return "medium-sized";
  }
  return size;
};

const Info = () => {
  const [organisation, updateOrganisation] = useState(undefined);
  const [loading, updateLoading] = useState(true);
  const { user } = useContext(GlobalContext);
  useEffect(() => {
    const getOrgData = async () => {
      const response = await organisationService.getOrganisation(
        user.organisation
      );
      updateOrganisation(response);
      updateLoading(false);
    };

    getOrgData();
  }, [user.organisation]);

  let loadingStyles = {};
  if (loading) {
    loadingStyles = styles.loading;
  }
  return (
    <>
      <HeadingSmall sx={styles.heading}>My Business</HeadingSmall>

      <RaisedBox sx={{ ...styles.panel, ...loadingStyles }}>
        <>
          {loading ? (
            <CircularLoader />
          ) : (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <HeadingVerySmall cy="organisation-name">
                    {" "}
                    {organisation.name}
                  </HeadingVerySmall>
                </Grid>
                <Grid item xs={12} sx={styles.row}>
                  <Grid container spacing={0} data-cy="organisation-turnover">
                    <Grid item xs={12} sm={6} sx={styles.data}>
                      <Bold>Annual Revenue</Bold>
                      {organisation.turnover}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={styles.data}
                      data-cy="organisation-industry"
                    >
                      <Bold>Industry</Bold>
                      {organisation.industry}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={styles.data}
                  data-cy="organisation-employees"
                >
                  <Bold>No Of Employees</Bold>
                  {organisation.employees}
                </Grid>
                <Grid item xs={12} sx={styles.data} data-cy="organisation-size">
                  <HeadingVerySmall> Results </HeadingVerySmall>
                  Based on your answers, your organisation is a
                  <Box component="span" sx={styles.size}>
                    {" "}
                    {makeSizeString(organisation.size)}{" "}
                  </Box>
                  business.
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      </RaisedBox>
    </>
  );
};

export default Info;
