import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "name") {
      return reducers.createFormErrorsHandler(
        "name",
        "Is not a valid name.",
        errorDispatch
      );
    }

    if (errorData.field === "type") {
      return reducers.createFormErrorsHandler(
        "type",
        "Is not a valid type.",
        errorDispatch
      );
    }

    if (errorData.field === "size") {
      return reducers.createFormErrorsHandler(
        "size",
        "Is not a valid size.",
        errorDispatch
      );
    }
  } else {
    throw err;
  }
};

export default errorHandler;
