import React, { useState } from "react";
import Box from "@mui/material/Box";
import Description from "components/core/typography/description/Description";
import PasswordField from "components/core/form/PasswordInput/PasswordInput";
import Button from "components/core/buttons/Primary/Primary";

import styles from "./NewPasswordForm.styles";

const NewPasswordForm = ({ onComplete, submitButtonText = "Confirm" }) => {
  const [error, setError] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    const password = e.target.password.value;
    const passwordConfirm = e.target["password-confirm"].value;

    if (password !== passwordConfirm) {
      return setError("Passwords do not match");
    }

    const onCompleteError = await onComplete(password);

    if (onCompleteError) {
      setError(onCompleteError.data.message);
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Description>
          Please create a new password for your Resilience First account. Your
          password must:
        </Description>
        <Description>
          <li>Be at least eight characters long.</li>
          <li>Contain a number.</li>
          <li>Contain a special character.</li>
        </Description>
      </div>
      <PasswordField
        id="password"
        cy="password-field"
        label="New Password"
        autoComplete="new-password"
        required
        error={error ? true : false}
        onChange={() => setError("")}
        inputProps={{ maxLength: 246 }}
        type="password"
      />
      <PasswordField
        id="password-confirm"
        cy="password-confirm-field"
        label="Confirm New Password"
        autoComplete="new-password"
        required
        inputProps={{ maxLength: 500 }}
        sx={{ paddingBottom: "1.5rem" }}
        error={error ? true : false}
        onChange={() => setError("")}
        helperText={error}
        type="password"
      />
      <Box sx={styles.formFooter}>
        <Button type="submit" text={submitButtonText} />
      </Box>
    </form>
  );
};

export default NewPasswordForm;
