import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;
    if (errorData.field === "tel") {
      return reducers.createFormErrorsHandler(
        "tel",
        "Field value should be a valid phone number.",
        errorDispatch
      );
    }

    if (errorData.field === "position") {
      return reducers.createFormErrorsHandler(
        "position",
        "Field is not a valid position.",
        errorDispatch
      );
    }
  } else {
    throw err;
  }
};

export default errorHandler;
