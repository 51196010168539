import React from "react";
import PropTypes from "prop-types";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Box from "@mui/material/Box";
import QuestionList from "../QuestionList/QuestionList";
import styles from "./CategoryPanel.style";

const CategoryPanel = ({ categoryData }) => {
  return (
    <Box>
      <HeadingSmall sx={styles.heading}>{categoryData.name}</HeadingSmall>
      <QuestionList
        categoryId={categoryData.id}
        categoryName={categoryData.name}
        themes={categoryData.themes}
      />
    </Box>
  );
};

CategoryPanel.propTypes = {
  categoryData: PropTypes.shape({}),
};

export default CategoryPanel;
