import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import styles from "./HeadiingForm.style";
const HeadingVerySmall = ({ text, cy, children, sx }) => {
  return (
    <Typography
      component="h6"
      variant="h6"
      data-cy={cy}
      sx={{
        ...styles.heading,
        ...sx,
      }}
    >
      {children}
      {text}
    </Typography>
  );
};

HeadingVerySmall.defaultProps = {
  cy: "heading-large",
  sx: undefined,
};

HeadingVerySmall.propTypes = {
  text: PropTypes.string,
  cy: PropTypes.string,
  sx: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default HeadingVerySmall;
