import stylesConfig from "theme/config";

const styles = {
  button: stylesConfig.buttons.primary,
  loading: {
    ...stylesConfig.buttons.loading,
    paddingLeft: stylesConfig.spacing.small,
    paddingRight: stylesConfig.spacing.small,
  },
  disabled: {
    ...stylesConfig.buttons.disabled,
  },
  loader: {
    color: "inherit",
    marginLeft: stylesConfig.spacing.small,
    height: "20px !important",
    width: "20px !important",
  },
};

export default styles;
