import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { PermissionsGated } from "components/context-aware/PermissionsGated/PermissionsGated";
import SidebarLink from "components/core/links/SidebarLink/SidebarLink";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import MenuBookOutlined from "@mui/icons-material/MenuBookOutlined";
import Permissions from "lib/permissions";

const AdminLinks = () => {
  return (
    <>
      <PermissionsGated activities={[Permissions.activities.ADMIN_DASHBOARD]}>
        <SidebarLink to="/" text="Home" Icon={HomeOutlinedIcon} />
      </PermissionsGated>
      <PermissionsGated activities={[Permissions.activities.SURVEY_COMPLETION]}>
        <SidebarLink
          to="/self-assessment"
          text="Self Assessment"
          Icon={InsertChartOutlinedIcon}
        />
      </PermissionsGated>
      <PermissionsGated
        activities={[Permissions.activities.ADMIN_USER_MANAGEMENT]}
      >
        <SidebarLink
          to="/users"
          text="Collaborators"
          Icon={PeopleAltOutlinedIcon}
          cy="admin-collaborators"
        />
      </PermissionsGated>
      <SidebarLink
        to="/neds-guide"
        text="Non Executive Directors Guide"
        Icon={MenuBookOutlined}
      />
      <SidebarLink
        to="/settings"
        text="Settings"
        cy="settings"
        Icon={SettingsOutlinedIcon}
      />
    </>
  );
};

AdminLinks.propTypes = {};
export default AdminLinks;
