import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import styles from "./SwitchPrimaryUser.style";
import organisationService from "services/organisation/organisation-service";
import Box from "@mui/material/Box";
import CircularLoader from "components/core/loaders/circular/Circular";

const DisplayPrimaryUser = ({ id }) => {
  const [dataLoading, updateDataLoading] = useState(true);
  const [userData, updateUserData] = useState({});

  useEffect(() => {
    const getData = async () => {
      const referenceDataResponse = await organisationService.getOrganisation(
        id
      );
      updateUserData(referenceDataResponse.primaryUser);
      updateDataLoading(false);
    };

    getData();
  }, [id]);

  return (
    <>
      <Box>
        {dataLoading ? (
          <CircularLoader contanierSx={styles.loader} />
        ) : (
          <Box sx={styles.displayOnly}>
            <Box>
              Name:{" "}
              <Box component="span" sx={styles.label}>
                {`${userData.firstName} ${userData.lastName}`}
              </Box>
            </Box>
            <Box>
              Email:{" "}
              <Box component="span" sx={styles.label}>
                {userData.email}
              </Box>
            </Box>
            <Box>
              {" "}
              User Status:{" "}
              <Box component="span" sx={styles.label}>
                {userData.status}
              </Box>
            </Box>
            <Box>
              {" "}
              Job Role:{" "}
              <Box component="span" sx={styles.label}>
                {userData.positionString}
              </Box>
            </Box>
            <Box>
              {" "}
              Tel:{" "}
              <Box component="span" sx={styles.label}>
                {userData.tel}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

DisplayPrimaryUser.propTypes = {
  id: PropTypes.string,
};
export default DisplayPrimaryUser;
