import React from "react";
import { Container } from "@mui/material";
import SinglePanel from "components/layouts/single-panel/SinglePanel.jsx";
import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import Img from "components/core/img/Img";
import Box from "@mui/material/Box";
import CompanyOnboardingForm from "components/forms/reference/organisation/onboarding/OnboardingForm";
import styles from "./CompanyOnboarding.style";
import { useHistory } from "react-router-dom";

export const CompanyOnboarding = () => {
  const history = useHistory();
  const onComplete = async () => {
    history.push("/info");
  };
  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              maxWidth: { xs: "550px", md: "98%" },
            }}
          >
            <Img src={RFLogo} alt="Resilience First Logo" style={styles.logo} />
            <HeadingLarge text="A little bit about your business." />
            <Paragraph text="Please answer the questions below." />
            <CompanyOnboardingForm onComplete={onComplete} />
          </Container>
        </Box>
      }
    />
  );
};

export default CompanyOnboarding;
