import FIELD_TYPES from "../answer-field-types";

const shapeAnswers = (answers) => {
  const answersObject = {};
  answers.map((answer) => {
    answersObject[answer.id] = {
      id: answer.id,
      value: answer.value,
    };
    return answer;
  });
  return answersObject;
};
const isAnswerSetComplete = (answersPayload, questions) => {
  // create an array of just the required questions' ids
  const requiredQuestions = Object.values(questions).filter(
    (question) => question.isOptional !== true
  );

  // make the answers payload into an object of objects where the id is the key
  const answers = shapeAnswers(answersPayload);
  const errors = [];

  // loop through our required questions
  requiredQuestions.forEach((question) => {
    const answerValue = answers[question.id].value;

    // if we don't have a value set for a given question set, we can add it to the errors (not valid) array.
    if (!answerValue) {
      errors.push(question);
    }
  });

  if (errors.length) {
    return { complete: false, errors: errors };
  }
  return {
    complete: true,
    errors: [],
  };
};

const calculateMaximumQuestionTotal = (questionsArray) => {
  let maximumScore = 0;
  questionsArray.forEach((question) => {
    if (question.isOptional) {
      return;
    }

    if (question.fieldType === FIELD_TYPES.FIVE_POINT_LIKERT_SCALE) {
      maximumScore = maximumScore + 5;
    }
  });
  return maximumScore;
};

const calculateScore = (questions, answers) => {
  let score = 0;
  questions.forEach((question) => {
    if (question.isOptional) {
      return;
    }
    score = score + answers[question.id].value;
  });

  return score;
};

const helpers = {
  isAnswerSetComplete,
  calculateMaximumQuestionTotal,
  calculateScore,
};

export default helpers;
