import React from "react";
import PropTypes from "prop-types";

const Img = ({ src, alt, className, style }) => {
  return <img src={src} alt={alt} className={className} style={style} />;
};

Img.defaultProps = {
  className: undefined,
  alt: "Resilience First Image",
  style: undefined,
};

Img.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  styles: PropTypes.shape({}),
};
export default Img;
