import React from "react";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";
const ErrorHelperText = ({ sx, text }) => {
  return (
    <FormHelperText error sx={sx}>
      {text}
    </FormHelperText>
  );
};

ErrorHelperText.defaultProps = {
  sx: undefined,
  text: "",
};

ErrorHelperText.propTypes = {
  sx: PropTypes.shape({}),
  text: PropTypes.string,
};
export default ErrorHelperText;
