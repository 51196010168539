import options from "components/data-display/Table/Options/Options.style";
import stylesConfig from "theme/config";
const styles = {
  ...options,
  editModal: {
    width: { xs: "calc(100vw - 20px)", lg: "1016px" },
  },
  error: {
    marginBottom: stylesConfig.spacing.standard,
  },
};

export default styles;
