import React from "react";
import PropTypes from "prop-types";
import stylesConfig from "theme/config";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const CloseButton = ({ type, style, cy, onClick }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      data-cy={cy}
      style={{
        ...stylesConfig.buttons.close,
        ...style,
      }}
    >
      <CloseOutlinedIcon />
    </button>
  );
};

CloseButton.defaultProps = {
  type: "button",
  style: undefined,
  cy: "close-button",
  onClick: undefined,
};

CloseButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.shape({}),
  cy: PropTypes.string,
  onClick: PropTypes.func,
};
export default CloseButton;
