import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { GlobalContext } from "context/GlobalContext";
import Permissions from "../../lib/permissions.js";
import NoRouteFound from "pages/errors/LoggedIn/404";

const ProtectedRoute = (props) => {
  const globalContext = useContext(GlobalContext);
  const { activities } = props;

  const { user } = globalContext;
  if (!user) {
    return <Redirect to="/" />;
  }

  const hasPermission =
    activities &&
    Permissions.doesUserHaveActivities(user.activities, activities);

  return (
    <>
      {hasPermission ? (
        <Route {...props} />
      ) : (
        <Route path="*" component={NoRouteFound} />
      )}
    </>
  );
};

export default ProtectedRoute;
