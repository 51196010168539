import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import LoggedIn from "components/layouts/logged-in/LoggedIn";
import SuperAdminEditUserForm from "components/forms/user/SuperAdminEditUser/SuperAdminEditUser";
import InternalLink from "components/core/links/Internal/InternalLink";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";

import styles from "./SuperAdminEditUser.style";

const SuperAdminEditUserPage = () => {
  return (
    <LoggedIn>
      <InternalLink style={styles.link} to="/users">
        <KeyboardArrowLeftIcon />
        Back
      </InternalLink>
      <HeadingLarge text="Account Settings" />
      <SuperAdminEditUserForm />
    </LoggedIn>
  );
};

export default SuperAdminEditUserPage;
