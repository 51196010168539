import stylesConfig from "theme/config";

const styles = {
  editButton: {
    color: stylesConfig.colors.primary,
    textTransform: "none",
    fontWeight: "bold",
  },
  cancelButton: {
    color: stylesConfig.colors.red,
    textTransform: "none",
    fontWeight: "bold",
  },
};

export default styles;
