const utils = {
  getUIDateString: (date) => {
    const dateAsObject = new Date(date);
    return dateAsObject.toLocaleDateString("en-GB");
  },
  uniqueString: () => Math.random().toString(36).slice(2),
  getUIQuestionnaireStatus: (status) => {
    if (typeof status === "boolean") {
      if (status) return "Active";
      return "Inactive";
    }
    return "";
  },
};

export default utils;
