import { create } from "apisauce";
import Cookie from "universal-cookie";
import HTTPError from "../lib/errors/http-error";

const cookies = new Cookie();

/**
 *
 * api function()
 * wrapper around the api sauce create object
 * need to check on each call whether the auth cookie is set,
 * if it is, we want to send the token up with each request
 * if it isn't, our AuthContext context will bounce the user
 * out of the app.
 *
 * method: get, post, put, delete
 * endpoint: the api we are looking to hit eg. "/auth/verify"
 * data: object containing our request body data
 *
 * @param {string} method
 * @param {string} endpoint
 * @param {obj} data
 */

const api = async (
  method,
  endpoint,
  data,
  throwOnError,
  redirectOnAuthIssue = true,
  useAuthToken = false
) => {
  let apiInit;

  if (useAuthToken) {
    const cookie = cookies.get("rf-token") || "";

    let headers = {};

    if (cookie) headers = { Authorization: `Bearer ${cookie}` };

    apiInit = create({
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
      withCredentials: true,
      headers,
    });
  } else {
    apiInit = create({
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
    });
  }

  const result = await apiInit[method](endpoint, data);

  if (redirectOnAuthIssue && (result.status === 401 || result.status === 403)) {
    window.location = "/logout";
  }

  // If we prefer throwing errors, rather than depending on checking
  // response status, then throw.
  if (throwOnError && !result.ok) {
    throw new HTTPError(result.status, result.data);
  }

  return result;
};

export default api;
