import stylesConfig from "theme/config";

const styles = {
  container: {
    minHeight: "198px",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    marginBottom: stylesConfig.spacing.standard,
  },
  size: {
    marginBottom: stylesConfig.spacing.standard,
    display: "flex",
    alignItems: "baseline",
    paddingBottom: stylesConfig.spacing.small,
    borderBottom: `1px solid ${stylesConfig.colors.lightGrey}`,
    "&:last-child": {
      marginBottom: "0px",
    },
  },
  label: {
    marginRight: stylesConfig.spacing.standard,
  },
  completedOnboarding: {
    font: "normal normal bold 20px/36px Montserrat",
  },
  total: {
    marginRight: stylesConfig.spacing.standard,
    font: "normal normal bold 20px/36px Montserrat",
    color: stylesConfig.colors.darkGrey,
  },
};

export default styles;
