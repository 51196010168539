import stylesConfig from "theme/config";

const styles = {
  scoreContainer: {
    display: "flex",
  },
  heading: {
    flex: 8,
  },
  headingContainer: {
    marginBottom: stylesConfig.spacing.standard,
  },
  totalScore: {
    flex: 4,
    display: "flex",
    justifyContent: "flex-end",
  },
  categoryContainer: {
    marginBottom: stylesConfig.spacing.small,
  },
  scoreCategoryName: {
    marginRight: stylesConfig.spacing.standard,
    color: stylesConfig.colors.description,
    fontWeight: "bold",
  },
  score: {
    color: stylesConfig.colors.description,
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "140px",
  },
};

export default styles;
