import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { Box } from "@mui/system";
import usersService from "services/users/users-service";
import organisationService from "services/organisation/organisation-service";
import styles from "./AdminUserManagement.style";
import NewUserForm from "components/forms/organisation/NewCollaborator/NewCollaborator";
import Table from "components/data-display/Table/Table";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import BasicModal from "components/core/modal/basic-modal/Modal";
import Options from "components/data-display/Table/Options/UserOptions/UserOptions";
import utils from "lib/utils";

const AdminUserManagementPage = () => {
  const { user } = useContext(GlobalContext);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [closeModal, updateCloseModal] = useState(false);

  const getUsersFunc = async (nextPage = 0, updateMessage) => {
    const response = await usersService.getOrganisationUsers(nextPage, 10);
    if (!response || response.message) {
      setNoResultsMessage(
        "There was a problem fetching from the server, please try again. If the issue persists, please contact the Social Good Connect Team"
      );
      return;
    }
    setPage(nextPage);
    setTotal(response.total);
    setRowsPerPage(response.pageSize);
    const usersArray = response.users.map((user) => {
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        status: user.status,
        fields: [
          utils.getUIDateString(user.dateCreated),
          `${user.firstName} ${user.lastName}`,
          user.email,
          user.status,
        ],
      };
    });
    setUsers(usersArray);
    if (updateMessage && response && response.users.length === 0)
      setNoResultsMessage("Your search did not match any users.");
  };

  useEffect(() => {
    const getUsers = async () => {
      await getUsersFunc();
      setIsPageLoading(false);
    };

    getUsers();
  }, []);

  const getUsersHandler = async (nextPage) => {
    await getUsersFunc(nextPage);
  };

  const deleteUser = async (id) => {
    setIsPageLoading(true);
    await usersService.deleteOrganisationUser(id);
    await getUsersHandler();
    setIsPageLoading(false);
  };

  const onComplete = async () => {
    updateCloseModal(false);
    setIsPageLoading(true);
    await getUsersFunc();
    setIsPageLoading(false);
    updateCloseModal(true);
  };

  const tableFormat = ["Date Added", "Contact Name", "Contact Email", "Status"];

  return (
    <LoggedIn heading="Collaborators" loading={isPageLoading}>
      <Box sx={styles.actions}>
        <BasicModal
          closeModal={closeModal}
          updateModalState={updateCloseModal}
          buttonCy="new-user"
          buttonText="New User"
          style={styles.actionButton}
          headingText="Add User"
        >
          <NewUserForm
            onComplete={onComplete}
            service={(data) =>
              organisationService.createUser(user.organisation, data)
            }
          />
        </BasicModal>
      </Box>
      {!isPageLoading && (
        <Table
          data={users}
          page={page}
          total={total}
          rowsPerPage={rowsPerPage}
          getDataHandler={getUsersHandler}
          noDataComponent={noResultsMessage || "There are no Collaborators"}
          tableFormat={tableFormat}
          optionActions={(data) => (
            <Options data={data} handleDeleteData={deleteUser} />
          )}
        />
      )}
    </LoggedIn>
  );
};

export default AdminUserManagementPage;
