import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { GlobalContext } from "context/GlobalContext";
import SuperAdminRoutes from "./user-routes/super-admin/SuperAdminRoutes";
import NoRouteFound from "pages/errors/LoggedIn/404";
import { userTypes } from "lib/permissions";
import AdminUserRoutes from "./user-routes/admin/AdminRoutes";
import CollaboratorUserRoutes from "./user-routes/collaborator/CollaboratorRoutes";
import IndividualUserRoutes from "./user-routes/individual/IndividualUserRoutes";

export const LoggedInRoutes = () => {
  const globalContext = useContext(GlobalContext);
  const { user } = globalContext;
  return (
    <>
      <Switch>
        <Route exact path="/login">
          <Redirect to="/" />
        </Route>
        {user.type === userTypes.SUPER_ADMIN && <SuperAdminRoutes />}
        {user.type === userTypes.ADMIN && <AdminUserRoutes />}
        {user.type === userTypes.COLLABORATOR && <CollaboratorUserRoutes />}
        {user.type === userTypes.INDIVIDUAL && <IndividualUserRoutes />}
        <Route path="*" component={NoRouteFound} />
      </Switch>
    </>
  );
};
