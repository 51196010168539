import api from "lib/http";

const patchUser = async (id, userData = {}) => {
  // if present must be a number
  if (userData.position) {
    userData.position = parseInt(userData.position);
  }

  const result = await api("patch", `user/${id}`, userData, true, true, true);
  return result.data;
};

const getUser = async (id) => {
  const result = await api("get", `user/${id}`, {}, true, true, true);
  return result.data;
};

const userService = {
  patchUser,
  getUser,
};

export default userService;
