import React, { useReducer, useState, useEffect } from "react";
import reducers from "reducers/form-errors-reducer";
import PropTypes from "prop-types";
import Input from "components/core/form/Input/Input";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "./PatchOrg.style";
import organisationService from "services/organisation/organisation-service";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./PatchOrg.error.handler";

import CircularLoader from "components/core/loaders/circular/Circular";

const intialErrorState = {
  name: null,
  website: null,
};

const PatchOrg = ({
  onComplete,
  data,
  disableAll,
  hideSaveButton,
  service,
  formId,
}) => {
  const [loading, updateLoading] = useState(false);
  const [dataLoading, updateDataLoading] = useState(true);
  const [orgData, updateOrgData] = useState({});

  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  useEffect(() => {
    const getData = async () => {
      const orgDataResponse = await organisationService.getOrganisation(
        data.id
      );
      updateOrgData(orgDataResponse);
      updateDataLoading(false);
    };

    getData();
  }, [data.id]);

  const onSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);
    try {
      const orgData = {
        name: e.target.name.value,
        website: e.target.website.value,
      };

      await service(data.id, orgData);
      onComplete();
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
    updateLoading(false);
  };
  return (
    <>
      <form id={formId} onSubmit={onSubmit} style={styles.form}>
        {dataLoading ? (
          <CircularLoader contanierSx={styles.loader} />
        ) : (
          <>
            <Input
              id="name"
              cy="organisation-field"
              label="Company Name"
              required
              sx={styles.input}
              error={errorState.name ? true : false}
              onChange={() =>
                reducers.clearFieldError("name", errorState, errorDispatch)
              }
              helperText={errorState.name}
              inputProps={{ maxLength: 50 }}
              defaultValue={orgData.name}
              disabled={disableAll}
            />
            <Input
              id="website"
              cy="website-field"
              label="Website"
              sx={styles.input}
              error={errorState.website ? true : false}
              onChange={() =>
                reducers.clearFieldError("website", errorState, errorDispatch)
              }
              helperText={errorState.website}
              inputProps={{ maxLength: 255 }}
              defaultValue={orgData.website}
              disabled={disableAll}
            />
            {!hideSaveButton && (
              <PrimaryButton
                type="submit"
                text="Save Company"
                loading={loading}
                style={styles.button}
              />
            )}
          </>
        )}
      </form>
    </>
  );
};

PatchOrg.defaultProps = {
  onComplete: undefined,
  disableAll: false,
  hideSaveButton: false,
  service: (id, orgData) => organisationService.patchOrganisations(id, orgData),
  formId: undefined,
};

PatchOrg.propTypes = {
  onComplete: PropTypes.func,
  disableAll: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
  service: PropTypes.func,
  formId: PropTypes.string,
};
export default PatchOrg;
