import React from "react";
import { Route, Switch } from "react-router-dom";
import NedsPage from "pages/neds/NedsPage";
import NoRouteFound from "pages/errors/LoggedIn/404";
const IndividualUserRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={NedsPage} />
      <Route path="*" component={NoRouteFound} />
    </Switch>
  );
};

IndividualUserRoutes.propTypes = {};
export default IndividualUserRoutes;
