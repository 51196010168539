import React from "react";
import Popover from "@mui/material/Popover";

const Options = ({
  anchorElement,
  popoverOpen,
  handlePopoverClose,
  id,
  children,
}) => {
  return (
    <Popover
      data-cy={`popover-${id}`}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      anchorEl={anchorElement}
      open={popoverOpen}
      onClose={handlePopoverClose}
      PaperProps={{
        sx: { padding: "0.5rem", display: "flex", flexDirection: "column" },
      }}
    >
      {children ? children : <></>}
    </Popover>
  );
};

export default Options;
