import React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";

import authenticationService from "services/authentication/authentication-service";

import SinglePanel from "components/layouts/single-panel/SinglePanel";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import ForgottenPasswordForm from "components/forms/auth/ForgottenPassword/ForgottenPasswordForm";
import Img from "components/core/img/Img";

import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";

import styles from "./ForgottenPasswordPage.styles.js";

export const ForgottenPasswordPage = () => {
  const history = useHistory();

  const requestPasswordReset = async (email) => {
    await authenticationService.resetPassword(email);
    return history.push("/forgotten-password/link-sent");
  };

  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: { xs: "550px" },
            }}
          >
            <Img src={RFLogo} alt="Resilience First Logo" style={styles.logo} />
            <HeadingLarge text="Reset Your Password" />
            <Paragraph text="Enter the email address associated with your account below and we will email you instructions on how to reset your password." />
            <ForgottenPasswordForm onComplete={requestPasswordReset} />
          </Container>
        </Box>
      }
    />
  );
};
