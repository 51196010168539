import stylesConfig from "theme/config";

const styles = {
  container: {
    height: "600px",
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  logo: {
    maxWidth: "100%",
    alignSelf: "flex-start",
    margin: `${stylesConfig.spacing.veryLarge} 0`,
    paddingTop: stylesConfig.spacing.veryLarge,
  },
};

export default styles;
