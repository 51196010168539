import { createTheme } from "@mui/material";
const fontFamily = [
  "Montserrat",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: fontFamily,
        },
      },
    },
  },
  typography: {
    fontFamily: fontFamily,
  },
});

export default theme;
