import React, { useReducer, useState, useEffect } from "react";
import reducers from "reducers/form-errors-reducer";
import PropTypes from "prop-types";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "./SwitchPrimaryUser.style";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./SwitchPrimaryUser.error.handler";
import organisationsService from "services/organisations/organisations";
import Select from "components/core/form/Select/Select";
import CircularLoader from "components/core/loaders/circular/Circular";

const intialErrorState = {
  user: null,
};

const getPrimaryId = (userOptions) => {
  const filteredOptions = userOptions.filter(
    (option) => option.isPrimaryOrganisationUser
  );

  return filteredOptions[0].value;
};

const getReferenceData = async (id) => {
  const data = await organisationsService.getOrganisationUsers(id);
  const options = data
    .filter((option) => option.status === "Active")
    .map((option) => {
      return {
        label: `${option.firstName} ${option.lastName}`,
        value: option.id,
        isPrimaryOrganisationUser: option.isPrimaryOrganisationUser,
      };
    });

  return options;
};

const PatchOrg = ({ onComplete, data, disableAll, hideSaveButton, formId }) => {
  const [loading, updateLoading] = useState(false);
  const [dataLoading, updateDataLoading] = useState(true);
  const [referenceData, updateReferenceData] = useState([]);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  useEffect(() => {
    const getData = async () => {
      const referenceDataResponse = await getReferenceData(data.id);
      updateReferenceData(referenceDataResponse);
      updateDataLoading(false);
    };

    getData();
  }, [data.id]);

  const onSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);
    try {
      const orgData = {
        currentPrimaryUserId: getPrimaryId(referenceData),
        newPrimaryUserId: parseInt(e.target.user.value),
      };

      if (orgData.newPrimaryUserId === orgData.currentPrimaryUserId) {
        updateLoading(false);
        return reducers.createFormErrorsHandler(
          "user",
          "New Primary is the same as Current Primary, please select a new user to make the primary.",
          errorDispatch
        );
      }

      await organisationsService.switchPrimaryUser(data.id, orgData);
      onComplete();
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
    updateLoading(false);
  };
  return (
    <>
      <form id={formId} onSubmit={onSubmit} style={styles.form}>
        {dataLoading ? (
          <CircularLoader contanierSx={styles.loader} />
        ) : (
          <>
            {referenceData.length < 1 ? (
              <>
                {" "}
                At least two active users must exist before switching is
                possible.
              </>
            ) : (
              <>
                <Select
                  id={"user"}
                  error={errorState.user ? true : false}
                  label={"User"}
                  cy={`user-field`}
                  options={referenceData}
                  onChange={() =>
                    reducers.clearFieldError("user", errorState, errorDispatch)
                  }
                  value={getPrimaryId(referenceData)}
                  sx={styles.input}
                  helperText={errorState.user}
                  required
                  disabled={disableAll}
                />
                {!hideSaveButton && (
                  <PrimaryButton
                    type="submit"
                    text="Update Primary"
                    loading={loading}
                    style={styles.button}
                  />
                )}{" "}
              </>
            )}
          </>
        )}
      </form>
    </>
  );
};

PatchOrg.defaultProps = {
  onComplete: undefined,
  disableAll: false,
  hideSaveButton: false,
  formId: undefined,
};

PatchOrg.propTypes = {
  onComplete: PropTypes.func,
  disableAll: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
  formId: PropTypes.string,
};
export default PatchOrg;
