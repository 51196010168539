import stylesConfig from "theme/config";

const styles = {
  heading: {
    font: "normal normal bold 20px/24px Montserrat",
    letterSpacing: "0.15px",
    color: stylesConfig.colors.darkGrey,
    marginBottom: stylesConfig.spacing.standard,
  },
};

export default styles;
