import React, { useState, useEffect } from "react";
import _ from "lodash";
import RaisedBox from "components/core/raised-box/RaisedBox";
import Box from "@mui/material/Box";
import CircularLoader from "components/core/loaders/circular/Circular";
import HeadingMedium from "components/core/typography/headings/medium/HeadingMedium";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import reportsService from "services/reporting/reporting-service";
import styles from "./OrganisatiionsOverview.style";
const OrganisationsOverview = () => {
  const [loading, updateLoading] = useState(true);
  const [overview, updateOverview] = useState({});

  useEffect(() => {
    const getData = async () => {
      const reports = await reportsService.getOrganisationOverview();
      updateOverview(reports);
      updateLoading(false);
    };

    getData();
  }, []);

  let loadingStyles = {};
  if (loading) {
    loadingStyles = styles.loading;
  }
  return (
    <>
      <HeadingMedium sx={styles.heading}> Company Overview</HeadingMedium>
      <RaisedBox sx={{ ...loadingStyles, ...styles.container }}>
        {loading ? (
          <CircularLoader />
        ) : (
          <>
            {Object.values(overview).map((business, index) => {
              const label = Object.keys(overview)[index];
              return (
                <Box key={label} sx={styles.size}>
                  <HeadingSmall sx={styles.label}>
                    {_.capitalize(label)}
                  </HeadingSmall>
                  <Box component="span" sx={styles.completedOnboarding}>
                    {business.completedOnboarding}/
                  </Box>
                  <Box component="span" sx={styles.total}>
                    {business.total}
                  </Box>
                  <Box>Active</Box>
                </Box>
              );
            })}
          </>
        )}
      </RaisedBox>
    </>
  );
};

OrganisationsOverview.propTypes = {};
export default OrganisationsOverview;
