import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import utils from "lib/utils";
import TablePaginationActions from "./Pagination/Pagination";
import DataRow from "./DataRow";

const DataTable = ({
  sx,
  data,
  total,
  rowsPerPage,
  getDataHandler,
  tableFormat,
  optionActions,
  hidePageing,
  hideOptions,
  customFooter,
  noDataComponent,
  page,
}) => {
  const handleChangePage = (event, newPage) => {
    getDataHandler(newPage);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {tableFormat.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
            {!hideOptions && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length ? (
            data.map((row) => {
              return (
                <DataRow
                  sx={sx.tableCell}
                  key={`table-row-${row.id}-${utils.uniqueString()}`}
                  data={row}
                  tableFormat={tableFormat}
                  optionActions={optionActions}
                  hideOptions={hideOptions}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6}>{noDataComponent} </TableCell>
            </TableRow>
          )}
        </TableBody>
        {!hidePageing && (
          <TableFooter>
            <TableRow>
              <TablePagination
                data-cy="table-pagination"
                colSpan={tableFormat.length + 1}
                count={total}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
        {customFooter && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>{customFooter}</TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

DataTable.defaultProps = {
  hidePageing: false,
  customFooter: undefined,
  noDataComponent: "No Data",
  sx: {
    tableCell: {},
  },
};

DataTable.propTypes = {
  hidePageing: PropTypes.bool,
  customFooter: PropTypes.node,
  noDataComponent: PropTypes.node,
  sx: PropTypes.shape({ tableCell: PropTypes.shape({}) }),
};

export default DataTable;
