import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";

import LoggedIn from "components/layouts/logged-in/LoggedIn";
import NewOrganisationForm from "components/forms/organisation/CreateNewOrg/CreateNewOrganisation";
import BasicModal from "components/core/modal/basic-modal/Modal";
import Table from "components/data-display/Table/Table";

import organisationService from "services/organisation/organisation-service";
import organisationAdminService from "services/organisations/organisations";
import Options from "components/data-display/Table/Options/CompanyOptions/CompanyOptions";
import utils from "lib/utils";

import styles from "./Companies.style";

const Companies = () => {
  const [closeModal, updateCloseModal] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companies, setCompanies] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getCompaniesFunc = async (nextPage = 0, updateMessage) => {
    const response = await organisationService.getOrganisations(nextPage, 10);
    if (!response || response.message) {
      setNoResultsMessage(
        "There was a problem fetching from the server, please try again. If the issue persists, please contact the Resilience First team."
      );
      return;
    }
    setPage(nextPage);
    setTotal(response.total);
    setRowsPerPage(response.pageSize);
    const companiesArray = response.companies.map((company) => {
      return {
        id: company.id,
        name: company.name,
        status: company.status,
        userId: company.userId,
        fields: [
          utils.getUIDateString(company.dateCreated),
          company.name,
          company.size,
          company.website,
          `${company.primaryUser.firstName} ${company.primaryUser.lastName}`,
          company.primaryUser.email,
          company.status,
        ],
      };
    });
    setCompanies(companiesArray);
    if (updateMessage && response && response.users.length === 0)
      setNoResultsMessage("Your search did not match any companies.");
  };

  useEffect(() => {
    const getCompanies = async () => {
      await getCompaniesFunc();
      setIsPageLoading(false);
    };

    getCompanies();
  }, []);

  const getCompaniesHandler = async (nextPage) => {
    await getCompaniesFunc(nextPage);
  };

  const archiveCompany = async (id) => {
    setIsPageLoading(true);
    await organisationAdminService.archiveOrganisation(id);
    await getCompaniesHandler();
    setIsPageLoading(false);
  };

  const tableFormat = [
    "Date Added",
    "Company Name",
    "Company Size",
    "Company Website",
    "Primary Name",
    "Primary Email",
    "Status",
  ];

  const onComplete = async () => {
    updateCloseModal(false);
    setIsPageLoading(true);
    await getCompaniesFunc();
    updateCloseModal(true);
    setIsPageLoading(false);
  };

  return (
    <LoggedIn heading="Companies" loading={isPageLoading}>
      <Box sx={styles.actions}>
        <BasicModal
          closeModal={closeModal}
          updateModalState={updateCloseModal}
          buttonCy="new-company"
          buttonText="New Company"
          style={styles.actionButton}
          headingText="Add Company"
        >
          <NewOrganisationForm onComplete={onComplete} />
        </BasicModal>
      </Box>
      {!isPageLoading && (
        <Table
          data={companies}
          page={page}
          total={total}
          rowsPerPage={rowsPerPage}
          getDataHandler={getCompaniesHandler}
          noDataComponent={noResultsMessage || "There are no Companies"}
          tableFormat={tableFormat}
          optionActions={(data) => (
            <Options data={data} handleDeleteData={archiveCompany} />
          )}
        />
      )}
    </LoggedIn>
  );
};

export default Companies;
