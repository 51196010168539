import stylesConfig from "theme/config";

const styles = {
  container: {
    padding: stylesConfig.spacing.large,
  },
  logo: {
    maxWidth: "100%",
    alignSelf: "flex-start",
    marginBottom: stylesConfig.spacing.veryLarge,
  },
};

export default styles;
