import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Table from "components/data-display/Table/Table";
import Box from "@mui/material/Box";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import questionnaireService from "services/questionnaire/questionnaire-service";
import Options from "components/data-display/Table/Options/QuestionnaireOptions/QuestionnaireOptions";
import styles from "./QuestionnaireList.style";
import utils from "lib/utils";
import BUSINESS_SIZES from "lib/business-sizes";
const QuestionnaireList = ({ type, title, reRender, onComplete }) => {
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [tableData, setTableData] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getDataFunc = async (nextPage, updateMessage, type) => {
    const pageToSearch = nextPage || 0;
    const pageSize = 3;

    const params = {
      page: pageToSearch,
      pageSize: pageSize,
    };

    if (type) {
      params.type = type;
    }

    const response = await questionnaireService.getQuestionnaires(params);

    if (!response || response.message) {
      setNoResultsMessage(
        "There was a problem fetching from the server, please try again. If the issue persists, please contact the Resilience First team."
      );
      return;
    }
    setPage(nextPage);
    setTotal(response.total);
    setRowsPerPage(response.pageSize);
    const tableDataArray = response.questionnaires.map((data) => {
      return {
        id: data.id,
        name: data.name,
        dateCreated: data.dateCreated,
        status: data.active,
        type: data.type,
        fields: [
          utils.getUIDateString(data.dateCreated),
          data.name,
          utils.getUIQuestionnaireStatus(data.active),
          _.capitalize(BUSINESS_SIZES.sizes[data.forOrganisationSize]),
        ],
      };
    });
    setTableData(tableDataArray);
    if (updateMessage && response && response.users.length === 0)
      setNoResultsMessage("Your search did not match any questionnaires.");
  };

  useEffect(() => {
    const getData = async () => {
      await getDataFunc(page, false, type);
      setIsPageLoading(false);
    };

    if (type || reRender === type) {
      getData();
    }
  }, [type, reRender, page]);

  const getDataHandler = async (nextPage) => {
    await getDataFunc(nextPage, false, type);
  };

  const tableFormat = ["Date Created", "Name", "Status", "For Business Size"];

  return (
    <>
      {!isPageLoading && tableData.length > 0 && (
        <Box sx={styles.container} data-cy={`${type}-list`}>
          {title && <HeadingSmall text={title} sx={styles.heading} />}
          <Table
            data={tableData}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            getDataHandler={getDataHandler}
            noDataComponent={noResultsMessage || "There are no Questionnaires"}
            tableFormat={tableFormat}
            optionActions={(data) => (
              <Options data={data} onComplete={onComplete} />
            )}
          />
        </Box>
      )}
    </>
  );
};

QuestionnaireList.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  reRender: PropTypes.string,
};
export default QuestionnaireList;
