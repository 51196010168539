import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import styles from "./SidebarAccordion.styles";

const StyledAccordion = styled(Accordion)`
  margin: 0;
  &.Mui-expanded {
    margin: 0;
  }
`;

const SidebarAccordion = ({ title, Icon, children, childrenLinkTo, cy }) => {
  const location = useLocation();
  let active = {};
  let expanded = false;
  if (childrenLinkTo.includes(location.pathname)) {
    active = styles.active;
    expanded = true;
  }
  return (
    <StyledAccordion
      sx={{ ...styles.accordion, ...active }}
      defaultExpanded={expanded}
    >
      <AccordionSummary sx={styles.summary} data-cy={cy}>
        <Box sx={styles.icon}>
          <Icon />
        </Box>
        {title}
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

SidebarAccordion.defaultProps = {
  childrenLinkTo: [],
  cy: "accordion-open",
};

SidebarAccordion.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  childrenLinkTo: PropTypes.arrayOf(PropTypes.string),
  cy: PropTypes.string,
};

export default SidebarAccordion;
