import React, { useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import Close from "components/core/buttons/Close/Close";
import Box from "@mui/material/Box";

import registrationService from "services/registration/registration-service";

import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import InternalLink from "components/core/links/Internal/InternalLink";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import { GlobalContext } from "context/GlobalContext";
import styles from "../Options.style";

const isCurrent = (current, id) => {
  if (current === id) {
    return true;
  }
  return false;
};

const UserOptions = ({ data, handleDeleteData }) => {
  const { user } = useContext(GlobalContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmailSendModal, setShowEmailSendModal] = useState(false);

  const resendEmailNotification = async () => {
    await registrationService.resendRegistrationEmail(data.id);
    setShowEmailSendModal(true);
  };

  return (
    <>
      <InternalLink
        style={styles.editButtonStyle}
        to={`users/${data.id}`}
        cy="view-edit-button"
      >
        View/Edit
      </InternalLink>
      {data.status === "Pending" && (
        <>
          <PrimaryButton
            style={styles.deleteButtonStyle}
            text="Resend Email Invitation"
            onClick={resendEmailNotification}
            cy="resend-registration-email-button"
          />
          <Modal
            open={showEmailSendModal}
            onClose={() => setShowEmailSendModal(false)}
          >
            <Box sx={styles.modalStyle}>
              <Box sx={styles.header}>
                <HeadingSmall text="Resend Email Invitation" />
                <Close onClick={() => setShowEmailSendModal(false)} />
              </Box>
              <Paragraph>
                A new email invitation has been successfully sent to {data.name}
                .
              </Paragraph>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PrimaryButton
                  text="Close"
                  onClick={() => setShowEmailSendModal(false)}
                  style={{ border: "none" }}
                />
              </div>
            </Box>
          </Modal>
        </>
      )}
      {!isCurrent(user.id, data.id) && (
        <>
          <PrimaryButton
            style={styles.deleteButtonStyle}
            text="Delete User"
            onClick={() => setShowDeleteModal(true)}
            cy="delete-user-button"
          />
          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <Box sx={styles.modalStyle}>
              <Box sx={styles.header}>
                <HeadingSmall text="Remove User" />
                <Close onClick={() => setShowDeleteModal(false)} />
              </Box>
              <Paragraph>Are you sure you want to remove this user?</Paragraph>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PrimaryButton
                  style={{
                    background: "#C4104C 0% 0% no-repeat padding-box",
                    border: "none",
                  }}
                  text="Remove"
                  onClick={() => handleDeleteData(data.id)}
                />
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default UserOptions;
