import ORGANSATION_CONSTS from "lib/business-sizes";
import _ from "lodash";

const shapescoreDataForRadarGraph = (scoreData) => {
  const categories = scoreData.map((category) => category.name);
  const data = scoreData.map((category) => category.score);
  const sortedByMaxScore = scoreData.sort(
    (a, b) => b.maximumScore - a.maximumScore
  );
  const max = sortedByMaxScore[0].maximumScore;
  const min = 0;
  // must be a whole number
  const interval = Math.round(max / 10);

  return { categories, data, min, max, interval };
};

const shapeDataForResilienceScoreGraph = (questionnaireData) => {
  const QUESTIONNAIRE_SIZE_ORDER = [
    _.upperCase(ORGANSATION_CONSTS.sizes.SMALL),
    _.upperCase(ORGANSATION_CONSTS.sizes.MEDIUM),
    _.upperCase(ORGANSATION_CONSTS.sizes.LARGE),
  ];

  const orderedData = [];

  QUESTIONNAIRE_SIZE_ORDER.forEach((size) => {
    const sorted = questionnaireData.filter(
      (questionnaireData) => size === questionnaireData.size
    );

    if (sorted.length) {
      orderedData.push(sorted[0]);
    }
  });

  // existence of a category is what dictates whether a row appears in the graph
  const categories = orderedData.map((questionnaire) =>
    _.capitalize(questionnaire.size)
  );

  // results
  const low = grabDataByResultEnum(orderedData, "LOW");
  const medium = grabDataByResultEnum(orderedData, "MEDIUM");
  const high = grabDataByResultEnum(orderedData, "HIGH");
  const series = [
    { name: "Low Resilience", data: low },
    { name: "Medium Resilience", data: medium },
    { name: "High Resilience", data: high },
  ];

  return {
    categories,
    series,
  };
};

const grabDataByResultEnum = (orderedData, resultType) => {
  return orderedData.map((questionnaire) => {
    let numberOfResultsMatchingRessultType = 0;
    questionnaire.answers.forEach((answer) => {
      if (answer.result === resultType) {
        numberOfResultsMatchingRessultType =
          numberOfResultsMatchingRessultType += 1;
      }
    });
    return numberOfResultsMatchingRessultType;
  });
};

const lib = {
  shapescoreDataForRadarGraph,
  shapeDataForResilienceScoreGraph,
};

export default lib;
