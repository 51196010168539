import React, { useState, useEffect } from "react";
import RaisedBox from "components/core/raised-box/RaisedBox";
import Box from "@mui/material/Box/";
import CircularLoader from "components/core/loaders/circular/Circular";
import HeadingMedium from "components/core/typography/headings/medium/HeadingMedium";
import BarStacked100 from "components/core/graphs/BarStacked100/BarStacked100";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import reportsService from "services/reporting/reporting-service";
import scoreHelpers from "lib/score-helpers/score-helpers";
import styles from "./ResilienceOverview.style";
import options from "./Resilience.chart.options";
const ResilienceOverview = () => {
  const [loading, updateLoading] = useState(true);
  const [scoresData, updateScoresData] = useState({});
  const [noResults, updateNoResults] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const selfAssessmentData =
        await reportsService.getResilienceOverviewScore();

      if (!selfAssessmentData.length) {
        updateNoResults(true);
      } else {
        const shapedScores =
          scoreHelpers.shapeDataForResilienceScoreGraph(selfAssessmentData);
        updateScoresData(shapedScores);
      }

      updateLoading(false);
    };

    getData();
  }, []);

  let loadingStyles = {};
  if (loading) {
    loadingStyles = styles.loading;
  }
  return (
    <>
      <HeadingMedium sx={styles.heading}>
        Resilience Score Overview
      </HeadingMedium>
      <RaisedBox sx={{ ...loadingStyles, ...styles.container }}>
        {loading ? (
          <CircularLoader />
        ) : noResults ? (
          <Box sx={styles.noResults}>
            <Paragraph>
              There are currently no active self assessments or results to
              display.
            </Paragraph>
          </Box>
        ) : (
          <BarStacked100
            height={"280px"}
            series={scoresData.series}
            options={{
              ...options,
              xaxis: {
                categories: scoresData.categories,
              },
            }}
          />
        )}
      </RaisedBox>
    </>
  );
};

ResilienceOverview.propTypes = {};
export default ResilienceOverview;
