import React from "react";
import Box from "@mui/material/Box";

import styles from "./Bouncies.style.js";

const Loader = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.loader}></Box>
    </Box>
  );
};

Loader.propTypes = {};
export default Loader;
