import React, { useReducer, useState } from "react";
import reducers from "reducers/form-errors-reducer";
import PropTypes from "prop-types";
import HeadingVerySmall from "components/core/typography/headings/form/HeadingForm";
import Input from "components/core/form/Input/Input";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "./NewCollaborator.style";

import HTTPError from "lib/errors/http-error";
import errorHandler from "./NewCollaborator.error.handler";

const intialErrorState = {
  email: null,
  firstName: null,
  lastName: null,
};

const NewUser = ({ onComplete, service }) => {
  const [loading, updateLoading] = useState(false);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );
  const onSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);
    try {
      const data = {
        email: e.target.email.value,
        firstName: e.target.firstName.value,
        lastName: e.target.lastName.value,
      };

      await service(data);

      onComplete();
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
    updateLoading(false);
  };
  return (
    <>
      <HeadingVerySmall text="Contact Details" />
      <form onSubmit={onSubmit} style={styles.form}>
        <Input
          id="firstName"
          cy="first-name-field"
          label="First Name"
          autoComplete="given-name"
          required
          sx={styles.input}
          error={errorState.firstName ? true : false}
          onChange={() =>
            reducers.clearFieldError("firstName", errorState, errorDispatch)
          }
          helperText={errorState.firstName}
          inputProps={{ maxLength: 255 }}
        />
        <Input
          id="lastName"
          cy="last-name-field"
          label="Last Name"
          autoComplete="family-name"
          required
          sx={styles.input}
          error={errorState.lastName ? true : false}
          onChange={() =>
            reducers.clearFieldError("lastName", errorState, errorDispatch)
          }
          helperText={errorState.lastName}
          inputProps={{ maxLength: 255 }}
        />
        <Input
          id="email"
          cy="email-field"
          type="email"
          autoComplete="email"
          label="Email"
          required
          sx={styles.input}
          error={errorState.email ? true : false}
          onChange={() =>
            reducers.clearFieldError("email", errorState, errorDispatch)
          }
          helperText={errorState.email}
          inputProps={{ maxLength: 255 }}
        />
        <PrimaryButton
          type="submit"
          text="Add User"
          loading={loading}
          style={styles.button}
        />
      </form>
    </>
  );
};

NewUser.defaultProps = {
  onComplete: undefined,
  service: undefined,
};

NewUser.propTypes = {
  onComplete: PropTypes.func,
  service: PropTypes.func.isRequired,
};
export default NewUser;
