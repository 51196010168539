import stylesConfig from "theme/config";

const styles = {
  heading: {
    font: "normal normal bold 20px/34px Montserrat;",
    letterSpacing: "0.15px",
    color: stylesConfig.colors.black,
  },
};

export default styles;
