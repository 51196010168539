import React, { useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { Container } from "@mui/material";
import SinglePanel from "components/layouts/single-panel/SinglePanel.jsx";
import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import Img from "components/core/img/Img";
import Box from "@mui/material/Box";
import PrimaryUserInfoForm from "components/forms/reference/organisation/info/PrimaryUserInfo";
import styles from "./PrimaryUserInfo.style";
import userService from "services/user/user-service";

export const PrimaryUserInfo = () => {
  const { user, updateUserData } = useContext(GlobalContext);

  const onComplete = async () => {
    await userService.patchUser(user.id, { onboarded: true });
    updateUserData({ onboarded: true });
    window.location = "/";
  };
  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              maxWidth: { xs: "550px", md: "98%" },
            }}
          >
            <Img src={RFLogo} alt="Resilience First Logo" style={styles.logo} />
            <HeadingLarge text="A little bit about you." />
            <Paragraph text="Let us know a little bit more about you." />
            <PrimaryUserInfoForm onComplete={onComplete} />
          </Container>
        </Box>
      }
    />
  );
};

export default PrimaryUserInfo;
