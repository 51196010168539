import React from "react";

const PdfViewer = ({ url }) => {
  return (
    <div
      style={{
        aspectRatio: "1066/754",
        maxHeight: "100vh",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        alignItems: "center",
        paddingTop: "25px",
        paddingBottom: "25px",
        minWidth: "0px",
        flexShrink: 1,
      }}
    >
      <iframe
        src={`/pdfjs/web/viewer.html?file=${url}`}
        title="NEDs Guide"
        width="100%"
        height="100%"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
};

export default PdfViewer;
