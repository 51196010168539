import React, { useState, useEffect } from "react";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import IndividualUserManagementList from "components/user-list/IndividualUserManagement";

const IndividualUsersPage = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    setIsPageLoading(false);
  }, []);

  return (
    <LoggedIn heading="Non Executive Directors Access" loading={isPageLoading}>
      <IndividualUserManagementList />
    </LoggedIn>
  );
};

export default IndividualUsersPage;
