import stylesConfig from "theme/config";

const styles = {
  deleteButtonStyle: {
    color: "rgba(0, 0, 0, 0.87)",
    padding: "0.5rem",
    backgroundColor: "white",
    border: "none",
    fontWeight: "400",
    textAlign: "left",
    minWidth: "auto",
    width: "auto",
    height: "auto",
    font: "400 1rem Montserrat",
  },
  editButtonStyle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem",
    textDecoration: "none",
    padding: "0.5rem",
    backgroundColor: "white",
    "&:hover": { cursor: "pointer" },
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 2,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "8px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: stylesConfig.spacing.standard,
  },
};

export default styles;
