import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
const Select = ({
  id,
  cy,
  label,
  variant,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  options,
  disabled,
  value,
  autoComplete,
}) => {
  const [setValue, updateSetValue] = useState(value);
  return (
    <TextField
      id={id}
      autoComplete={autoComplete}
      helperText={helperText}
      error={error}
      select
      data-cy={cy}
      required={required}
      inputProps={{ name: id, ...inputProps }}
      label={label}
      variant={variant}
      disabled={disabled}
      onChange={(e) => {
        onChange();
        updateSetValue(e.target.value);
      }}
      value={setValue}
      sx={{ marginBottom: "1rem", ...sx }}
    >
      {options.map((option) => {
        return (
          <MenuItem key={`${id}-${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

Select.defaultProps = {
  variant: "standard",
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: () => {},
  options: [],
  disabled: false,
  value: undefined,
  autoComplete: undefined,
};

Select.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
};
export default Select;
