import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Close from "components/core/buttons/Close/Close";
import Box from "@mui/material/Box";
import questionniareService from "services/questionnaire/questionnaire-service";
import InternalLink from "components/core/links/Internal/InternalLink";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "../Options.style";

const QuestionnaireOptions = ({ data, onComplete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSetActiveModal, setShowSetActiveModal] = useState(false);

  const handleActive = async () => {
    const active = !data.status;
    await questionniareService.patchQuestionnaire(data.id, { active });
    setShowSetActiveModal(true);
    onComplete(data.type);
  };

  const activeUiLabel = data.status ? "Inactive" : "Active";

  const deleteQuestionnaire = async () => {
    await questionniareService.deleteQuestionnaire(data.id);
    setShowDeleteModal(false);
    onComplete(data.type);
  };

  return (
    <>
      <InternalLink
        style={styles.editButtonStyle}
        to={`questionnaire/${data.id}`}
        cy="view-edit-button"
      >
        View/Edit
      </InternalLink>

      <>
        <PrimaryButton
          style={styles.deleteButtonStyle}
          text={`Set ${activeUiLabel}`}
          onClick={handleActive}
          cy="set-active-button"
        />
        <Modal
          open={showSetActiveModal}
          onClose={() => setShowSetActiveModal(false)}
        >
          <Box sx={styles.modalStyle}>
            <Box sx={styles.header}>
              <HeadingSmall text={`Set As ${activeUiLabel}`} />
              <Close onClick={() => setShowSetActiveModal(false)} />
            </Box>
            <Paragraph>
              {data.name} has been set as {activeUiLabel}.
            </Paragraph>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                text="Close"
                onClick={() => setShowSetActiveModal(false)}
                style={{ border: "none" }}
              />
            </div>
          </Box>
        </Modal>
      </>

      <>
        <PrimaryButton
          style={styles.deleteButtonStyle}
          text="Delete"
          onClick={() => setShowDeleteModal(true)}
          cy="delete-user-button"
        />
        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <Box sx={styles.modalStyle}>
            <Box sx={styles.header}>
              <HeadingSmall text="Delete Questionnaire" />
              <Close onClick={() => setShowDeleteModal(false)} />
            </Box>
            <Paragraph>
              Are you sure you want to delete this questionnaire? All data will
              be permanently deleted and any company data associated with it
              will no longer exist.
            </Paragraph>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                style={{
                  background: "#C4104C 0% 0% no-repeat padding-box",
                  border: "none",
                }}
                text="Continue"
                onClick={() => deleteQuestionnaire(data.id)}
              />
            </div>
          </Box>
        </Modal>
      </>
    </>
  );
};

QuestionnaireOptions.defaultProps = {
  onComplete: () => {},
};
QuestionnaireOptions.proTypes = {
  data: PropTypes.shape({}),
  onComplete: PropTypes.func,
};

export default QuestionnaireOptions;
