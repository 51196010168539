import api from "../../lib/http";

const getResilienceFirstUsers = async (pageNo, pageSize) => {
  let pageQuery = `users/resilience-first-users?page=${pageNo}&pageSize=${pageSize}`;
  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const getResilienceFirstUser = async (id) => {
  let href = `users/resilience-first-users/${id}`;
  const result = await api("get", href, null, true, true, true);
  return result.data;
};

const updateResilienceFirstUser = async (
  id,
  firstName,
  lastName,
  email,
  company
) => {
  let href = `users/resilience-first-users/${id}/edit`;
  const result = await api(
    "patch",
    href,
    { firstName, lastName, email, company },
    true,
    true,
    true
  );
  return result;
};

const deleteResilienceFirstUser = async (id) => {
  let href = `users/resilience-first-users/${id}/delete`;
  const result = await api("delete", href, null, true, true, true);
  return result;
};

const getOrganisationUsers = async (pageNo, pageSize) => {
  let pageQuery = `users/organisation-users?page=${pageNo}&pageSize=${pageSize}`;
  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const getOrganisationUser = async (id) => {
  let href = `users/organisation-users/${id}`;
  const result = await api("get", href, null, true, true, true);
  return result.data;
};

const updateOrganisationUser = async (id, firstName, lastName, email) => {
  let href = `users/organisation-users/${id}/edit`;
  const result = await api(
    "patch",
    href,
    { firstName, lastName, email },
    true,
    true,
    true
  );
  return result;
};

const deleteOrganisationUser = async (id) => {
  let href = `users/organisation-users/${id}/delete`;
  const result = await api("delete", href, null, true, true, true);
  return result;
};

const createUser = async (data = {}) => {
  const result = await api("post", "users/new", data, true, true, true);
  return result.data;
};

const createIndividualUser = async (data = {}) => {
  const result = await api("post", "users/new-general-user", data, true);
  return result.data;
};

const getIndividualUsers = async (params) => {
  let pageQuery = `users/individual-users?page=${params.page}&pageSize=${params.pageSize}`;
  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const usersService = {
  getResilienceFirstUsers,
  getResilienceFirstUser,
  updateResilienceFirstUser,
  deleteResilienceFirstUser,
  getOrganisationUsers,
  getOrganisationUser,
  updateOrganisationUser,
  deleteOrganisationUser,
  createUser,
  createIndividualUser,
  getIndividualUsers,
};

export default usersService;
