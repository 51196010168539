import React, { useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import Img from "components/core/img/Img";
import style from "./Sidebar.style";
import RFWhiteLogo from "assets/Logo-RF-White.svg";
import SidebarLink from "components/core/links/SidebarLink/SidebarLink";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SuperAdminLinks from "components/core/navigation/user-links/super-admin/SuperAdminLinks";
import AdminLinks from "components/core/navigation/user-links/admin/AdminLinks";
import CollaboratorLinks from "../user-links/collaborator/CollaboratorLinks";
import IndividualLinks from "../user-links/individual/IndividualLinks";
import { userTypes } from "lib/permissions";
import ExternalLink from "components/core/links/External/ExternalLink";

const Sidebar = () => {
  const globalContext = useContext(GlobalContext);
  const { user, logout } = globalContext;
  return (
    <Drawer sx={style.drawer} variant="permanent" anchor="left">
      <Box sx={style.logoContainer}>
        <ExternalLink href="https://www.resiliencefirst.org/">
          <Img src={RFWhiteLogo} />
        </ExternalLink>
      </Box>
      {user.type === userTypes.SUPER_ADMIN && <SuperAdminLinks />}
      {user.type === userTypes.ADMIN && <AdminLinks />}
      {user.type === userTypes.COLLABORATOR && <CollaboratorLinks />}
      {user.type === userTypes.INDIVIDUAL && <IndividualLinks />}

      <Box sx={style.footer}>
        <SidebarLink
          text="Logout"
          cy="logout"
          Icon={LogoutOutlinedIcon}
          onClick={async () => {
            await logout();
          }}
        />
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {};
export default Sidebar;
