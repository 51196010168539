import stylesConfig from "theme/config";

const styles = {
  container: {
    marginBottom: stylesConfig.spacing.veryLarge,
  },
  heading: {
    marginBottom: stylesConfig.spacing.large,
  },
};

export default styles;
