import stylesConfig from "theme/config";

const styles = {
  link: {
    display: "flex",
    alignItems: "center",
    color: stylesConfig.colors.primary,
    textDecoration: "none",
    fontWeight: "bold",
  },
};

export default styles;
