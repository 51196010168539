import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import SinglePanel from "components/layouts/single-panel/SinglePanel.jsx";
import RFPanelImg from "assets/system.png";
import { RegistrationResponseWidget } from "../../../components/registration/registration-response-widget/RegistrationResponseWidget";

export const RegistrationCompletePage = () => {
  const [signInLink, updateSignInLink] = useState("/login");
  const location = useLocation();

  useEffect(() => {
    const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (qsToken.signInLink) {
      updateSignInLink(qsToken.signInLink);
    }
  }, [updateSignInLink, location.search]);

  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <RegistrationResponseWidget
          heading="Registration Complete"
          bodyText="Your password has been set successfully."
          href={signInLink}
        />
      }
    />
  );
};
