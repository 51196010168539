import stylesConfig from "theme/config";

const styles = {
  heading: {
    marginBottom: stylesConfig.spacing.large,
  },
  panel: {},
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
  },
  row: {
    display: "flex",
  },
  data: {
    color: stylesConfig.colors.description,
  },
  size: {
    fontWeight: "bold",
  },
};

export default styles;
