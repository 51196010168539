import React, { useState, useEffect, useContext, Fragment } from "react";
import Box from "@mui/material/Box";
import HeadingMedium from "components/core/typography/headings/medium/HeadingMedium";
import RaisedBox from "components/core/raised-box/RaisedBox";
import { SelfAssessmentContext } from "context/SelfAssessmentContext/SelfAssessmentContext";
import { GlobalContext } from "context/GlobalContext";
import ANSWER_UI_CONSTS from "lib/answer-field-types";
import styles from "./Questions.style";
import LikertScale from "components/answers/LikertScale/LikertScale";
import Notes from "components/answers/Notes/Notes";

const valueLabels = (value) => {
  const labelIndex = value - 1;
  const valueArray = [
    "Basic: Limited awareness and ad-hoc reaction if needed.",
    "Developing: Understand need and investing in improvement.",
    "Established: Formal ways of working in place.",
    "Advanced: Proactive approach which is integrated and validated.",
    "Leading: Long-term sustainability based on agility and adaptation.",
  ];
  return valueArray[labelIndex];
};

const Questions = ({ disableQuestions }) => {
  const [template, updateTemplate] = useState([]);

  const selfAssessmentContext = useContext(SelfAssessmentContext);
  const globalContext = useContext(GlobalContext);
  useEffect(() => {
    const templateData = Object.values(selfAssessmentContext.selfAssessment);
    updateTemplate(templateData);
  }, [selfAssessmentContext.selfAssessment]);

  const onChange = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    selfAssessmentContext.updateAnswer(id, value);
  };
  return (
    <>
      {!selfAssessmentContext.isLoading && (
        <form>
          {template.map((category) => {
            return (
              <Fragment key={category.id}>
                <HeadingMedium sx={styles.heading}>
                  {category.name}
                </HeadingMedium>
                {category.questions.map((question, index) => {
                  const answer = selfAssessmentContext.answers[question.id];
                  return (
                    <Box key={question.id} sx={styles.container}>
                      <RaisedBox key={question.id}>
                        {question.fieldType ===
                          ANSWER_UI_CONSTS.FIVE_POINT_LIKERT_SCALE && (
                          <LikertScale
                            name={question.id}
                            sx={styles.slider}
                            title={`${index + 1}. ${question.title}`}
                            value={answer.value}
                            lowLabel={question.lowestPointsValueLabel}
                            highLabel={question.highestPointsValueLabel}
                            onChange={onChange}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => valueLabels(value)}
                            disabled={disableQuestions ? true : false}
                          />
                        )}
                      </RaisedBox>
                      <Notes
                        id={answer.id}
                        currentUserId={globalContext.user.id}
                        notes={selfAssessmentContext.notes.filter((note) => {
                          return note.answerId === answer.id;
                        })}
                        onSubmit={(formData) =>
                          selfAssessmentContext.saveNote(
                            question.id,
                            formData.note
                          )
                        }
                      />
                    </Box>
                  );
                })}
              </Fragment>
            );
          })}
        </form>
      )}
    </>
  );
};
export default Questions;
