import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
const CheckboxField = ({
  id,
  cy,
  label,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  disabled,
  defaultChecked,
}) => {
  return (
    <FormControl error={error}>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            id={id}
            data-cy={cy}
            sx={sx}
            defaultChecked={defaultChecked}
            onChange={onChange}
            required={required}
          />
        }
        label={label}
      />
      <FormHelperText> {helperText} </FormHelperText>
    </FormControl>
  );
};

CheckboxField.defaultProps = {
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: undefined,
  disabled: false,
  autoComplete: undefined,
  defaultChecked: false,
};

CheckboxField.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  defaultChecked: PropTypes.bool,
};
export default CheckboxField;
