import React, { useReducer, useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import reducers from "reducers/form-errors-reducer";
import PropTypes from "prop-types";
import Input from "components/core/form/Input/Input";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "./EditUser.style";
import userService from "services/user/user-service";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./EditUser.error.handler";

import CircularLoader from "components/core/loaders/circular/Circular";

const intialErrorState = {
  firstName: null,
  lastName: null,
};

const PatchOrg = ({
  onComplete,
  disableAll,
  hideSaveButton,
  service,
  formId,
}) => {
  const [loading, updateLoading] = useState(false);
  const [dataLoading, updateDataLoading] = useState(true);
  const [data, updateData] = useState({});
  const { user } = useContext(GlobalContext);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  useEffect(() => {
    const getData = async () => {
      const data = await userService.getUser(user.id);
      updateData(data);
      updateDataLoading(false);
    };

    getData();
  }, [user.id]);

  const onSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);
    try {
      const payload = {
        firstName: e.target.firstName.value,
        lastName: e.target.lastName.value,
      };

      await service(user.id, payload);
      onComplete();
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
    updateLoading(false);
  };
  return (
    <>
      <form id={formId} onSubmit={onSubmit} style={styles.form}>
        {dataLoading ? (
          <CircularLoader contanierSx={styles.loader} />
        ) : (
          <>
            <Input
              id="firstName"
              cy="first-name-field"
              label="First Name"
              autoComplete="given-name"
              required
              sx={styles.input}
              error={errorState.firstName ? true : false}
              defaultValue={data.firstName}
              onChange={() =>
                reducers.clearFieldError("firstName", errorState, errorDispatch)
              }
              helperText={errorState.firstName}
              disabled={disableAll}
              inputProps={{ maxLength: 255 }}
            />
            <Input
              id="lastName"
              cy="last-name-field"
              label="Last Name"
              autoComplete="family-name"
              required
              sx={styles.input}
              defaultValue={data.lastName}
              error={errorState.lastName ? true : false}
              disabled={disableAll}
              onChange={() =>
                reducers.clearFieldError("lastName", errorState, errorDispatch)
              }
              helperText={errorState.lastName}
              inputProps={{ maxLength: 255 }}
            />
            {!hideSaveButton && (
              <PrimaryButton
                type="submit"
                text="Save"
                loading={loading}
                style={styles.button}
              />
            )}
          </>
        )}
      </form>
    </>
  );
};

PatchOrg.defaultProps = {
  onComplete: undefined,
  disableAll: false,
  hideSaveButton: false,
  service: (id, payload) => userService.patchUser(id, payload),
  formId: undefined,
};

PatchOrg.propTypes = {
  onComplete: PropTypes.func,
  disableAll: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
  service: PropTypes.func,
  formId: PropTypes.string,
};
export default PatchOrg;
