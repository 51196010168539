import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Close from "components/core/buttons/Close/Close";
import Box from "@mui/material/Box";
import organisationService from "services/organisation/organisation-service";
import InternalLink from "components/core/links/Internal/InternalLink";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "../Options.style";

const CompanyOptions = ({ data, handleDeleteData }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmailSendModal, setShowEmailSendModal] = useState(false);

  // TO DO
  // Write the edit company form
  // Hook the form up to the API
  const resendEmailNotification = async () => {
    await organisationService.reInviteOrganisation(data.id);
    setShowEmailSendModal(true);
  };

  return (
    <>
      <InternalLink
        style={styles.editButtonStyle}
        to={`companies/${data.id}`}
        cy="view-edit-button"
      >
        View/Edit
      </InternalLink>
      {data.status === "Pending" && (
        <>
          <PrimaryButton
            style={styles.deleteButtonStyle}
            text="Resend Email Invitation"
            onClick={resendEmailNotification}
            cy="resend-registration-email-button"
          />
          <Modal
            open={showEmailSendModal}
            onClose={() => setShowEmailSendModal(false)}
          >
            <Box sx={styles.modalStyle}>
              <Box sx={styles.header}>
                <HeadingSmall text="Resend Email Invitation" />
                <Close onClick={() => setShowEmailSendModal(false)} />
              </Box>
              <Paragraph>
                A new email invitation has been successfully sent to {data.name}
                .
              </Paragraph>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PrimaryButton
                  text="Close"
                  onClick={() => setShowEmailSendModal(false)}
                  style={{ border: "none" }}
                />
              </div>
            </Box>
          </Modal>
        </>
      )}
      {data.status !== "Archived" && (
        <>
          <PrimaryButton
            style={styles.deleteButtonStyle}
            text="Archive Company"
            onClick={() => setShowDeleteModal(true)}
            cy="delete-user-button"
          />
          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <Box sx={styles.modalStyle}>
              <Box sx={styles.header}>
                <HeadingSmall text="Archive Company" />
                <Close onClick={() => setShowDeleteModal(false)} />
              </Box>
              <Paragraph>
                Are you sure you want to archive this company? All data will be
                archived and any users associated with this company will no
                longer be able to login.
              </Paragraph>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PrimaryButton
                  style={{
                    background: "#C4104C 0% 0% no-repeat padding-box",
                    border: "none",
                  }}
                  text="Continue"
                  onClick={() => handleDeleteData(data.id)}
                />
              </div>
            </Box>
          </Modal>{" "}
        </>
      )}
    </>
  );
};

export default CompanyOptions;
