import React, { useContext } from "react";
import { SelfAssessmentContext } from "context/SelfAssessmentContext/SelfAssessmentContext";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SelfAssessmentContextProvider from "context/SelfAssessmentContext/SelfAssessmentContext";
import Questions from "components/context-aware/SelfAssessment/Questions/Questions";
import styles from "../self-assessment/SelfAssessment.style";
import Scores from "components/context-aware/SelfAssessment/Scores/Scores";
import Actions from "../actions/Actions";
const SelfAssessment = () => {
  const context = useContext(SelfAssessmentContext);

  return (
    <LoggedIn
      heading={
        context.activeSelfAssessment && !context.isComplete
          ? context.title
          : " "
      }
      loading={context.isLoading}
    >
      {!context.isLoading && (
        <>
          {context.activeSelfAssessment && !context.isComplete ? (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4} sx={styles.container}>
                  <Grid item xs={12} lg={8} xl={9}>
                    <Questions />
                  </Grid>
                  <Grid item xs={12} lg={4} xl={3} sx={styles.actionsContainer}>
                    <Box sx={styles.actions}>
                      <Scores sx={styles.scoresWidget} />
                      <Actions />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <Box sx={styles.inactive}>
              <Box>
                <h2>There are currently no active assessments</h2>
                <p> Check back later</p>
              </Box>
            </Box>
          )}
        </>
      )}
    </LoggedIn>
  );
};

const SelfAssessmentWithContext = () => {
  return (
    <SelfAssessmentContextProvider>
      <SelfAssessment />
    </SelfAssessmentContextProvider>
  );
};

export default SelfAssessmentWithContext;
