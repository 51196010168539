import React from "react";
import { RegistrationResponseWidget } from "../../../components/registration/registration-response-widget/RegistrationResponseWidget";
import SinglePanel from "components/layouts/single-panel/SinglePanel.jsx";
import RFPanelImg from "assets/system.png";

export const InvalidRegistrationTokenPage = () => (
  <SinglePanel
    img={RFPanelImg}
    content={
      <RegistrationResponseWidget
        heading="Complete Registration"
        bodyText="The registration token is either invalid, or has been used before."
        href={"/login"}
      />
    }
  />
);
