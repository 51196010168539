import api from "lib/http";

const createOrganisation = async (orgData) => {
  const result = await api(
    "post",
    "organisation/create",
    orgData,
    true,
    true,
    true
  );
  return result.data;
};

const createUser = async (orgId, data = {}) => {
  const result = await api(
    "post",
    `organisation/${orgId}/user/new`,
    data,
    true,
    true,
    true
  );
  return result.data;
};

const patchOrganisation = async (id, data = {}) => {
  // api expects these values to be a number
  if (data.employees) data.employees = parseInt(data.employees);
  if (data.industry) data.industry = parseInt(data.industry);
  if (data.turnover) data.turnover = parseInt(data.turnover);
  const result = await api(
    "patch",
    `organisation/${id}`,
    data,
    true,
    true,
    true
  );
  return result.data;
};

const patchOrganisations = async (id, data = {}) => {
  const result = await api(
    "patch",
    `organisations/${id}`,
    data,
    true,
    true,
    true
  );
  return result.data;
};

const getOrganisation = async (id) => {
  const result = await api("get", `organisation/${id}`, {}, true, true, true);
  return result.data;
};

const getOrganisations = async (pageNo, pageSize) => {
  let pageQuery = `organisations?page=${pageNo}&pageSize=${pageSize}`;
  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const reInviteOrganisation = async (id) => {
  const result = await api(
    "post",
    `organisations/${id}/resend-registration-email`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const service = {
  createOrganisation,
  createUser,
  patchOrganisation,
  patchOrganisations,
  getOrganisation,
  getOrganisations,
  reInviteOrganisation,
};

export default service;
