import React from "react";
import Box from "@mui/material/Box";
import styles from "./ScoreCard.style";
import PropTypes from "prop-types";

const ScoreCard = ({ label, score }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.score}>
        <Box sx={styles.scoreText}>{score}%</Box>
      </Box>
      <Box sx={styles.label} component="p">
        {label} Score
      </Box>
    </Box>
  );
};

ScoreCard.propTypes = {
  label: PropTypes.string,
  score: PropTypes.number,
};
export default ScoreCard;
