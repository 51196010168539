import React from "react";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";

const TextInputField = ({
  id,
  cy,
  label,
  variant,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  type,
  defaultValue,
  disabled,
}) => {
  return (
    <MuiPhoneNumber
      id={id}
      autoComplete="tel"
      defaultValue={defaultValue}
      helperText={helperText}
      error={error}
      data-cy={cy}
      required={required}
      inputProps={inputProps}
      label={label}
      variant={variant}
      onChange={onChange}
      sx={{
        marginBottom: "1rem",
        svg: { width: "44px", height: "auto" },
        ...sx,
      }}
      type={type}
      disabled={disabled}
      defaultCountry={"gb"}
      excludeCountries={["kz"]}
    />
  );
};

TextInputField.defaultProps = {
  variant: "standard",
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: undefined,
  type: "text",
  defaultValue: undefined,
  disabled: false,
};

TextInputField.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
};
export default TextInputField;
