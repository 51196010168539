import errorCodes from "lib/error-codes";
import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "email") {
      if (errorData.code === errorCodes.NOT_UNIQUE) {
        return reducers.createFormErrorsHandler(
          "email",
          "User email address already exists.",
          errorDispatch
        );
      } else if (errorData.code === errorCodes.INCORRECT_FORMAT) {
        return reducers.createFormErrorsHandler(
          "email",
          "User email address is not in the correct format.",
          errorDispatch
        );
      } else {
        return reducers.createFormErrorsHandler(
          "email",
          "Email address is not valid.",
          errorDispatch
        );
      }
    }

    if (errorData.field === "firstName") {
      return reducers.createFormErrorsHandler(
        "firstName",
        "Please try another first name.",
        errorDispatch
      );
    }

    if (errorData.field === "lastName") {
      return reducers.createFormErrorsHandler(
        "lastName",
        "Please try another last name.",
        errorDispatch
      );
    }
  } else {
    throw err;
  }
};

export default errorHandler;
