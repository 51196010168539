import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { QuestionnaireContext } from "context/QuestionnaireBuilderContext";
import PropTypes from "prop-types";
import Table from "components/data-display/Table/Table";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Options from "components/context-aware/QuestionnaireBuilder/QuestionOptions/QuestionOptions";
import styles from "./QuestionList.style";
import Modal from "components/core/modal/basic-modal/Modal";
import AddQuestionForm from "components/forms/questionnaires/add-question/AddQuestion";
import ALLOWED_QUESTIONS_BY_SIZE from "lib/self-assessment";

const numberOfAllowedQuestionsUiString = (size) => {
  return ALLOWED_QUESTIONS_BY_SIZE[size] * 4;
};

const numberOfMandatoryQuestionsCompleteUiString = (
  questions,
  categoryId,
  size
) => {
  const totalMandatoryQuestionsAdded = questions.filter((question) => {
    if (question.categoryId !== categoryId) return false;
    if (question.isOptional) return false;
    return true;
  });

  const numberOfAllowedQuestions = numberOfAllowedQuestionsUiString(size);

  if (totalMandatoryQuestionsAdded.length) {
    return (
      parseInt(numberOfAllowedQuestions) - totalMandatoryQuestionsAdded.length
    );
  } else return numberOfAllowedQuestions;
};

const themeUiString = (templateData, themeId) => {
  const searchForTheme = Object.values(templateData)
    .map(
      (template) => template.themes.filter((theme) => theme.id === themeId)[0]
    )
    .filter((themes) => themes !== undefined);

  return searchForTheme[0].name;
};

const QuestionList = ({ categoryId, categoryName }) => {
  const [tableData, updateTableData] = useState([]);
  const [closeModal, updateCloseModal] = useState(false);
  const tableFormat = ["Theme", "Statement", "Optional"];
  const [numberOfMandatoryQuestions, updateNumberOfMandatoryQuestions] =
    useState(undefined);
  const { updateQuestionsArray, questions, templateData, forOrganisationSize } =
    useContext(QuestionnaireContext);
  const onComplete = (questionData) => {
    updateCloseModal(false);
    updateQuestionsArray(questionData);
    updateCloseModal(true);
  };

  useEffect(() => {
    const setTableData = () => {
      const tableDataArray = questions
        .filter((question) => question.categoryId === categoryId)
        .map((question) => {
          return {
            id: question.id,
            question: question.question,
            themeId: question.themeId,
            isOptional: question.isOptional,
            highestPointsValueLabel: question.highestPointsValueLabel,
            lowestPointsValueLabel: question.lowestPointsValueLabel,
            categoryId: categoryId,
            hasBeenActive: question.hasBeenActive,
            scoreType: question.scoreType,
            fields: [
              themeUiString(templateData, question.themeId),
              question.question,
              question.isOptional ? "Yes" : "No",
            ],
          };
        });
      const mandatoryQuestionsCompleted =
        numberOfMandatoryQuestionsCompleteUiString(
          questions,
          categoryId,
          forOrganisationSize
        );
      updateNumberOfMandatoryQuestions(mandatoryQuestionsCompleted);
      updateTableData(tableDataArray);
    };

    setTableData();
  }, [questions, categoryId, templateData, forOrganisationSize]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4} sx={styles.container}>
          <Grid item xs={12} lg={9} xl={10}>
            <Box sx={styles.tableContainer} data-cy={`${categoryName}-list`}>
              <Table
                data={tableData}
                hidePageing={true}
                customFooter={
                  <Box sx={styles.footer}>
                    {"  "}
                    <Box
                      sx={styles.counter}
                      data-cy={`number-of-${_.kebabCase(
                        categoryName
                      )}-mandatory-questions`}
                    >
                      {"  "}
                      {numberOfMandatoryQuestions}{" "}
                    </Box>
                    of
                    <Box
                      sx={styles.counter}
                      data-cy={`number-of-${_.kebabCase(
                        categoryName
                      )}-allowed-questions`}
                    >
                      {numberOfAllowedQuestionsUiString(forOrganisationSize)}
                    </Box>
                    {"  "}
                    Statements Remaining{" "}
                  </Box>
                }
                noDataComponent={
                  <Box sx={styles.noData}>
                    Add a statement using the add button.
                  </Box>
                }
                tableFormat={tableFormat}
                optionActions={(data) => <Options data={data} />}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} xl={2}>
            <Modal
              headingText={`Add ${categoryName} Statement`}
              buttonText="Add Statement"
              modalStyle={styles.modal}
              buttonStyle={styles.button}
              closeModal={closeModal}
              buttonCy={`add-${_.kebabCase(categoryName)}-question-btn`}
              updateModalState={updateCloseModal}
            >
              <AddQuestionForm
                categoryId={categoryId}
                onComplete={onComplete}
              />
            </Modal>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

QuestionList.propTypes = {
  categoryId: PropTypes.number,
  categoryName: PropTypes.string,
  themes: PropTypes.arrayOf(PropTypes.shape({})),
};
export default QuestionList;
