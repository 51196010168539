import stylesConfig from "theme/config";

const styles = {
  heading: {
    marginBottom: stylesConfig.spacing.large,
  },
  title: {
    marginBottom: stylesConfig.spacing.small,
    color: stylesConfig.colors.black,
  },
  span: {
    color: stylesConfig.colors.description,
  },
  loader: {
    display: "flex",
    justifyContent: "center",
  },
  categories: {
    display: "flex",
    color: stylesConfig.colors.description,
    span: {
      marginRight: stylesConfig.spacing.standard,
      fontWeight: "bold",
    },
  },
  graphWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    minHeight: {
      xl: "400px",
      xs: "unset",
    },
  },
  graph: {
    position: {
      xl: "absolute",
    },
    zIndex: 0,
    width: "145%",
    ".apexcharts-plot-series": {
      ">line": {
        strokeWidth: 0.5,
      },
    },
  },
  resultTitle: {
    marginBottom: stylesConfig.spacing.small,
  },
  resultsCopy: {
    position: "relative",
    zIndex: 1,
  },
};

export default styles;
