import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoutes";

import { GlobalContext } from "context/GlobalContext";

import Dashboard from "pages/dashboards/admin/AdminDashboard";
import Onboarding from "pages/onboarding/organisation/CompanySize/CompanyOnboarding";
import PrimaryUserInfo from "pages/onboarding/organisation/PrimaryUserInfo/PrimaryUserInfo";
import UserManagement from "pages/users/UserManagement/Admin/AdminUserManagement";
import EditUser from "pages/users/UserManagement/Admin/AdminEditUser";
import SelfAssessment from "pages/self-assessment/self-assessment/SelfAssessment";
import NedsPage from "pages/neds/NedsPage";
import LogoutPage from "pages/authentication/logout-page/LogoutPage";
import NoRouteFound from "pages/errors/LoggedIn/404";
import LoggedOutNoRouteFound from "pages/errors/LoggedOut/404";
import Settings from "pages/settings/Settings";

import { activities } from "lib/permissions";

const AdminUserRoutes = () => {
  const globalContext = useContext(GlobalContext);
  const { user } = globalContext;

  if (!user.onboarded) {
    return (
      <Switch>
        <Route exact path="/" component={Onboarding} />
        <Route exact path="/info" component={PrimaryUserInfo} />
        <Route exact path="/logout" component={LogoutPage} />
        <Route path="*" component={LoggedOutNoRouteFound} />
      </Switch>
    );
  }
  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/users"
        component={UserManagement}
        activities={[activities.ADMIN_USER_MANAGEMENT]}
      />
      <ProtectedRoute
        exact
        path="/users/:id"
        component={EditUser}
        activities={[activities.ADMIN_USER_MANAGEMENT]}
      />
      <ProtectedRoute
        exact
        path="/self-assessment"
        component={SelfAssessment}
        activities={[activities.SURVEY_COMPLETION]}
      />
      <Route exact path="/neds-guide" component={NedsPage} />
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/settings" component={Settings} />
      <Route path="*" component={NoRouteFound} />
    </Switch>
  );
};

AdminUserRoutes.propTypes = {};
export default AdminUserRoutes;
