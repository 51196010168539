import React, { useState, useEffect } from "react";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import BasicModal from "components/core/modal/basic-modal/Modal";
import { Box } from "@mui/system";
import CreateNewQuestionnaireForm from "components/forms/questionnaires/create-new-questionnaire/CreateNewQuestionnaire";
import QuestionnaireList from "components/questionnaires/QuestionnaireList/QuestionnaireList";
import questionnaireConsts from "lib/questionnaire-types";
import styles from "./Questionnaires.style";
const Questionnaires = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [closeModal, updateCloseModal] = useState(false);
  const [typeToReRender, updateTypeToRerender] = useState(undefined);

  useEffect(() => {
    setIsPageLoading(false);
  }, []);

  const onComplete = (type) => {
    updateCloseModal(false);
    setIsPageLoading(true);
    updateTypeToRerender(type);
    updateCloseModal(true);
    updateTypeToRerender(undefined);
    setIsPageLoading(false);
  };

  return (
    <LoggedIn heading="Questionnaires" loading={isPageLoading}>
      <Box sx={styles.actions}>
        <BasicModal
          closeModal={closeModal}
          updateModalState={updateCloseModal}
          buttonCy="new-questionnire"
          buttonText="New Questionnaire"
          style={styles.actionButton}
          headingText="Create Assessment"
        >
          <CreateNewQuestionnaireForm onComplete={onComplete} hideType={true} />
        </BasicModal>
      </Box>
      <QuestionnaireList
        type={questionnaireConsts.types.SELF_ASSESSMENT}
        title="Self Assessments"
        onComplete={onComplete}
        reRender={typeToReRender}
      />
      <QuestionnaireList
        type={questionnaireConsts.types.SURVEY}
        title="Surveys"
        onComplete={onComplete}
        reRender={typeToReRender}
      />
      <QuestionnaireList
        type={questionnaireConsts.types.CAMPAIGN}
        title="Campaigns"
        onComplete={onComplete}
        reRender={typeToReRender}
      />
    </LoggedIn>
  );
};

export default Questionnaires;
