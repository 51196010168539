const colors = {
  primary: "#252B5B",
  secondary: "#125AA5",
  tertiary: "#76C9F2",
  accent: "#3b416c",
  white: "#FFF",
  toupe: "#FBFBFB",
  description: "#707070",
  black: "#000000DE",
  darkGrey: "#555555",
  lightGrey: "#F4F4F4",
  red: "#C4104C",
  inactive: "#9e9e9e",
};

const spacing = {
  small: "0.5rem",
  standard: "1rem",
  large: "1.5rem",
  veryLarge: "2rem",
};

const button = {
  borderRadius: "8px",
  textAlign: "center",
  font: "normal normal 600 16px/45px Montserrat",
  letterSpacing: "0px",

  height: "59px",
  minWidth: "161px",

  cursor: "pointer",
  paddingLeft: spacing.standard,
  paddingRight: spacing.standard,
};

const buttons = {
  primary: {
    ...button,
    background: `${colors.primary} 0% 0% no-repeat padding-box`,
    color: colors.white,
    border: `1px solid ${colors.primary}`,
  },
  secondary: {
    ...button,
    color: colors.black,
    borderWidth: "2px",
    background: "transparent",
  },
  close: {
    cursor: "pointer",
    background: "none",
    border: "0",
  },
  disabled: {
    cursor: "not-allowed",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "not-allowed",
  },
};

const stylesConfig = {
  spacing: {
    ...spacing,
  },
  colors: colors,
  sizes: {
    distanceFromTopToContent: "96px",
  },
  links: {
    default: {
      font: "normal normal 600 16px/45px Montserrat",
      letterSpacing: "0px",
      color: colors.primary,
      textDecoration: "none",
    },
    primaryButton: {
      ...buttons.primary,
      height: "unset",
      padding: "6px 28px",
      textDecoration: "none",
    },
    secondaryButton: {
      ...buttons.primary,
      color: colors.black,
      borderWidth: "2px",
      background: "transparent",
    },
  },
  shadows: {
    standard: "0px 3px 6px #00000029",
  },
  buttons: buttons,
  navigationAccent: {
    content: "' '",
    position: "absolute",
    right: "-4px",
    top: 0,
    height: "100%",
    width: "8px",
    background: colors.white,
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default stylesConfig;
