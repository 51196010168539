import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import stylesConfig from "theme/config";
const styles = stylesConfig.links;
const ExternalLink = ({ href, children, cy, theme, style, ...rest }) => {
  const themeStyles = styles[theme];
  return (
    <Link href={href} data-cy={cy} sx={{ ...themeStyles, ...style }} {...rest}>
      {children}
    </Link>
  );
};

ExternalLink.defaultProps = {
  style: undefined,
  theme: "",
  cy: undefined,
};

ExternalLink.propTypes = {
  href: PropTypes.string,
  cy: PropTypes.string,
  style: PropTypes.shape({}),
  theme: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default ExternalLink;
