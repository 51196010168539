import React from "react";
import { Container } from "@mui/material";
import SinglePanel from "components/layouts/single-panel/SinglePanel.jsx";
import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import LoginForm from "components/forms/auth/Login/LoginForm";
import Img from "components/core/img/Img";
import Box from "@mui/material/Box";
import InternalLink from "components/core/links/Internal/InternalLink.jsx";

import styles from "./LoginPage.styles.js";

export const LoginPage = () => {
  return (
    <SinglePanel
      styleOverride={styles.override}
      img={RFPanelImg}
      content={
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: { xs: "650px" },
          }}
        >
          <Box>
            <Img src={RFLogo} alt="Resilience First Logo" style={styles.logo} />
            <HeadingLarge text="Login" />
            <Paragraph
              sx={{ maxWidth: "500px" }}
              text="Please login to your Resilience First account to access our tools and guides."
            />
            <LoginForm />
            <Box sx={{ display: "flex", pt: 4, justifyContent: "center" }}>
              <InternalLink
                to="/self-register"
                theme="default"
                style={styles.signupLink}
              >
                Sign up for the Non-Executives Guide to Operational Resilience{" "}
                <u>here</u>.
              </InternalLink>
            </Box>
          </Box>
        </Container>
      }
    />
  );
};
