import React from "react";
import PropTypes from "prop-types";
import Sidebar from "components/core/navigation/sidebar/Sidebar";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Bold from "components/core/typography/bold/Bold";
import { Box } from "@mui/material";
import styles from "./LoggedIn.style";
import Loader from "components/core/loaders/bouncies/Bouncies";

const LoggedIn = ({
  children,
  heading,
  loading,
  header,
  smallHeading,
  smallHeadingStyles,
  usePlainContentWrapper,
}) => {
  return (
    <>
      <Box sx={styles.container}>
        <Sidebar />
        {usePlainContentWrapper ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
            }}
          >
            {children}
          </div>
        ) : (
          <Box component="main" sx={styles.main}>
            <Box sx={styles.pageSepcificNavigation}>{header}</Box>
            <Box sx={styles.headingsContainer}>
              {heading && <HeadingLarge text={heading} sx={styles.heading} />}
              {smallHeading && (
                <Bold sx={{ ...styles.smallHeading, ...smallHeadingStyles }}>
                  {" "}
                  {smallHeading}
                </Bold>
              )}
            </Box>
            {children}
          </Box>
        )}
      </Box>
      {loading && <Loader />}
    </>
  );
};

LoggedIn.defaultProps = {
  loading: false,
  header: undefined,
  smallHeading: undefined,
  smallHeadingStyles: undefined,
  usePlainContentWrapper: false,
};

LoggedIn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  heading: PropTypes.string,
  loading: PropTypes.bool,
  header: PropTypes.element,
  smallHeading: PropTypes.string,
  smallHeadingStyles: PropTypes.shape({}),
  usePlainContentWrapper: PropTypes.bool,
};
export default LoggedIn;
