import api from "lib/http";
// import _ from "lodash";

const createNewNote = async (answerId, orgId, questionId, body = {}) => {
  const requestBody = {
    dateCreated: new Date(),
    ...body,
  };
  const result = await api(
    "post",
    `notes/new/answer/${answerId}/organisation/${orgId}/question/${questionId}`,
    requestBody,
    true,
    true,
    true
  );
  return result.data;
};

const getNotes = async (orgId, answerId) => {
  const result = await api(
    "get",
    `notes/answer/${answerId}/organisation/${orgId}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};
const service = {
  createNewNote,
  getNotes,
};

export default service;
