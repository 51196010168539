import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Accordion from "components/core/accordion/Accordion";
import AddNewNoteForm from "components/forms/answers/AddNewNote/AddNewNote";
import styles from "./Notes.styles";

const Note = ({ note, odd, currentUserId }) => {
  let background = styles.note.primaryBackground;
  if (odd) {
    background = styles.note.secondaryBackground;
  }

  let nameString = note.userName;
  if (note.userId === currentUserId) {
    nameString = "Me";
  }
  return (
    <Box sx={{ ...styles.note, background }}>
      <Box component="span" sx={styles.note.userName}>
        {nameString}:{" "}
      </Box>
      {note.note}
    </Box>
  );
};

const Notes = ({ sx, notes, id, onSubmit, currentUserId }) => {
  const headingString = `Notes (${notes.length})`;
  return (
    <Accordion
      id={id}
      sx={sx}
      heading={<Box sx={styles.heading}>{headingString}</Box>}
      content={
        <>
          <AddNewNoteForm
            sx={{ ...styles.note, alignItems: "flex-end" }}
            onComplete={onSubmit}
          />
          {notes.map((note, index) => {
            return (
              <Note
                currentUserId={currentUserId}
                key={note.id}
                note={note}
                odd={index % 2 === 0 ? false : true}
              />
            );
          })}
        </>
      }
    />
  );
};

Notes.defaultProps = {
  onSubmit: () => {},
  sx: undefined,
  currentUserId: undefined,
};

Notes.propTypes = {
  onSubmit: PropTypes.func,
  sx: PropTypes.shape({}),
  currentUserId: PropTypes.number,
};
export default Notes;
