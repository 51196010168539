import React, { useState, useEffect, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import reducers from "reducers/form-errors-reducer";
import referenceDataService from "services/reference-data/reference-data";
import Tel from "components/core/form/Tel/Tel";
import Select from "components/core/form/Select/Select";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./PrimaryUserInfo.error.handler";
import styles from "./PrimaryUserInfo.style";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import userService from "services/user/user-service";
import { GlobalContext } from "context/GlobalContext";
import CircularLoader from "components/core/loaders/circular/Circular";
const initialErrorState = {
  position: null,
  tel: null,
};

const getQuestionData = async () => {
  const questions = await referenceDataService.getJobRoles();
  questions.options = questions.options.map((option) => {
    return {
      label: option.label,
      value: option.id,
    };
  });

  return questions;
};
const PrimaryUserInfo = ({ onComplete }) => {
  const { user } = useContext(GlobalContext);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    initialErrorState
  );
  const [data, updateData] = useState([]);
  const [dataLoading, updateDataLoading] = useState(true);
  const [loading, updateLoading] = useState(false);

  useEffect(() => {
    const getQuestions = async () => {
      const response = await getQuestionData();
      updateData(response);
      updateDataLoading(false);
    };

    getQuestions();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);
    try {
      const payload = {
        position: e.target.position.value,
        tel: e.target.tel.value,
      };

      // remove from pl if any numbers less than 5 chars
      if (payload.tel.length < 5) {
        delete payload.tel;
      }

      if (!payload.position.length) {
        delete payload.position;
      }

      // none of the values above are mandatory so if they are not set,
      // skip the service call and go straight to onComplete
      if (!_.isEmpty(payload)) {
        await userService.patchUser(user.id, payload);
      }
      updateLoading(false);
      onComplete();
    } catch (err) {
      updateLoading(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };
  return (
    <form onSubmit={onSubmit} style={styles.form}>
      {dataLoading ? (
        <CircularLoader contanierSx={styles.loader} />
      ) : (
        <>
          <Select
            id={data.fieldName}
            error={errorState[data.fieldName] ? true : false}
            label={data.questionText}
            cy={`${data.fieldName}-field`}
            options={data.options}
            onChange={() =>
              reducers.clearFieldError(
                data.fieldName,
                errorState,
                errorDispatch
              )
            }
            sx={styles.input}
            helperText={errorState[data.fieldName]}
            required={data.required}
          />
          <Tel
            id="tel"
            cy="tel-field"
            type="tel"
            label="Telephone Number"
            sx={styles.input}
            error={errorState.tel ? true : false}
            onChange={() =>
              reducers.clearFieldError("tel", errorState, errorDispatch)
            }
            helperText={errorState.tel}
            inputProps={{ maxLength: 255 }}
          />
          <PrimaryButton
            type="submit"
            text="Continue"
            loading={loading}
            style={styles.button}
          />
        </>
      )}
    </form>
  );
};

PrimaryUserInfo.defaultProps = {
  onComplete: () => {},
};

PrimaryUserInfo.propTypes = {
  onComplete: PropTypes.func,
};
export default PrimaryUserInfo;
