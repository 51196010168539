import React, { useState, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import referenceDataService from "services/reference-data/reference-data";
import questionnaireService from "services/questionnaire/questionnaire-service";

import { useParams } from "react-router";

export const QuestionnaireContext = React.createContext({
  templateData: undefined,
  isLoading: true,
  questions: [],
});

const QuestionnaireContextProvider = ({ children }) => {
  const [templateData, updateTemplateData] = useState(undefined);
  const [questions, updateQuestions] = useState([]);
  const [forOrganisationSize, updateForOrganisationSize] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  const id = params.id;
  useEffect(() => {
    const getData = async () => {
      const template = await referenceDataService.getQuestionnaireTemplate();
      const questionnaire = await questionnaireService.getQuestionnaire(id);
      const questions = Object.values(questionnaire.questions);
      updateQuestions(questions);
      updateForOrganisationSize(questionnaire.forOrganisationSize);
      updateTemplateData(template);
      setIsLoading(false);
    };

    getData();
  }, [id]);

  const updateQuestionsArray = (dataToAdd) => {
    const updatedData = [...questions, dataToAdd];
    updateQuestions(updatedData);
  };

  const patchQuestionsArray = (dataToPatch) => {
    var index = _.findIndex(questions, { id: dataToPatch.id });
    let newArray = [...questions];
    newArray[index] = { ...newArray[index], ...dataToPatch };
    updateQuestions(newArray);
  };

  const deleteQuestionFromArray = (id) => {
    var index = _.findIndex(questions, { id });
    let newArray = [...questions];
    newArray.splice(index, 1);
    updateQuestions(newArray);
  };

  return (
    <QuestionnaireContext.Provider
      value={{
        templateData,
        isLoading,
        questions,
        updateQuestionsArray,
        patchQuestionsArray,
        forOrganisationSize,
        deleteQuestionFromArray,
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};

QuestionnaireContextProvider.propTypes = {
  children: PropTypes.node,
};

export default QuestionnaireContextProvider;
