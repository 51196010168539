const fragment = {
  top: "30%",
  borderRadius: "50%",
  width: "2.5em",
  height: "2.5em",
  animationFillMode: "both",
  animation: "load7 1.9s infinite ease-in-out",
};

const styles = {
  container: {
    background: "rgba(0, 0, 0, 0.3)",
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    // higher than mui sidebar and modal zindex
    zIndex: 1301,
    transition: "0.2s ease-in",
  },
  loader: {
    color: "white",
    fontSize: "10px",
    margin: "80px auto",
    position: "relative",
    textIndent: "-9999em",

    transform: " translateZ(0)",
    animationDelay: "-0.16s",
    ...fragment,
    "&::before": {
      ...fragment,
      content: "''",
      position: "absolute",
      top: 0,
      left: "-3.5em",
      animationDelay: "-0.32s",
    },
    "&::after": {
      ...fragment,
      content: "''",
      position: "absolute",
      top: 0,
      left: "3.5em",
    },

    "@keyframes load7": {
      "0%": {
        boxShadow: " 0 2.5em 0 -1.3em",
      },
      "80%": {
        boxShadow: " 0 2.5em 0 -1.3em",
      },
      "100%": {
        boxShadow: " 0 2.5em 0 -1.3em",
      },
      "40%": {
        boxShadow: "0 2.5em 0 0",
      },
    },
  },
};

export default styles;
