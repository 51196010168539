import stylesConfig from "theme/config";

const styles = {
  form: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  input: {
    width: "100%",
    textAlign: "left",
  },
  button: {
    margin: "0 auto",
  },
  loader: {
    margin: "0 auto",
    paddingTop: stylesConfig.spacing.veryLarge,
  },
  displayOnly: {
    color: stylesConfig.colors.inactive,
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontWeight: "bold",
  },
};

export default styles;
