import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import RaisedBox from "components/core/raised-box/RaisedBox";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Button from "@mui/material/Button";

import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";

import styles from "./EditBox.style";

const EditBox = ({
  heading,
  onSave,
  editState,
  idleState,
  active,
  onActive,
  editCy,
  cancelCy,
}) => {
  const [isEditing, setIsEditing] = useState(active);

  useEffect(() => {
    setIsEditing(active);
  }, [active]);

  const cancel = () => {
    setIsEditing(false);
  };

  return (
    <>
      <RaisedBox>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <HeadingSmall sx={{ marginBottom: 0 }} text={heading} />
          {!isEditing && (
            <Button
              data-cy={editCy}
              variant="text"
              startIcon={<CreateOutlinedIcon fontSize="small" />}
              sx={styles.editButton}
              onClick={() => {
                setIsEditing(true);
                onActive(true);
              }}
            >
              Edit
            </Button>
          )}
          {isEditing && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                data-cy={cancelCy}
                variant="text"
                sx={styles.cancelButton}
                onClick={cancel}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {isEditing && editState}
          {!isEditing && idleState}
        </div>
      </RaisedBox>
    </>
  );
};

EditBox.defaultProps = {
  onSave: () => {},
  formId: undefined,
  onActive: () => {},
  editCy: "edit-button",
  cancelCy: "cancel-button",
};

EditBox.propTypes = {
  onSave: PropTypes.func,
  heading: PropTypes.string,
  formId: PropTypes.string,
  onActive: PropTypes.func,
  editCy: PropTypes.string,
  cancelCy: PropTypes.string,
};
export default EditBox;
