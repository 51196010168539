import React from "react";
import usersService from "services/users/users-service";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import HeadingMedium from "components/core/typography/headings/medium/HeadingMedium";
import SinglePanel from "components/layouts/single-panel/SinglePanel.jsx";
import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";
import Img from "components/core/img/Img";
import styles from "./SelfRegister.style";
import NewUser from "components/forms/users/new/NewUser";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import ExternalLink from "components/core/links/External/ExternalLink";
export const SelfRegister = () => {
  const history = useHistory();

  const setTokenHandler = (token) => {
    return history.push({
      pathname: "/complete-registration",
      search: `?token=${token}&autoLogin=true`,
    });
  };

  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <Container>
          <Img style={styles.logo} src={RFLogo} alt="Resilience First Logo" />
          <HeadingMedium cy="header">Sign Up</HeadingMedium>
          <Paragraph>
            To access the non executive directors guide please sign up here.
          </Paragraph>
          <NewUser
            service={(data) => usersService.createIndividualUser(data)}
            submitButtonText="Sign Up"
            company
            jobTitle
            onComplete={setTokenHandler}
          />
          <Paragraph sx={styles.policy}>
            To view our privacy and use of data policy{" "}
            <ExternalLink
              href="https://www.resiliencefirst.org/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              please click here
            </ExternalLink>
          </Paragraph>
        </Container>
      }
    />
  );
};
