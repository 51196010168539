import React from "react";
import { PermissionsGated } from "components/context-aware/PermissionsGated/PermissionsGated";
import Permissions from "lib/permissions";
import SidebarLink from "components/core/links/SidebarLink/SidebarLink";
import MenuBookOutlined from "@mui/icons-material/MenuBookOutlined";

const IndividualLinks = () => {
  return (
    <PermissionsGated activities={[Permissions.activities.NEDS_GUIDE]}>
      <SidebarLink
        to="/"
        text="Non Executive Directors Guide"
        Icon={MenuBookOutlined}
      />
    </PermissionsGated>
  );
};

IndividualLinks.propTypes = {};
export default IndividualLinks;
