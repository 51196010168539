import stylesConfig from "theme/config";

const styles = {
  label: {
    textAlign: "center",
  },
  container: {
    flex: 1,
  },
  scoreText: {
    paddingLeft: "6px",
    paddingRight: "6px",
  },
  score: {
    background: stylesConfig.colors.lightGrey,
    borderRadius: "8px",
    height: "74px",
    font: "normal normal bold 34px/36px Montserrat",
    color: stylesConfig.colors.black,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
