import stylesConfig from "theme/config";

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: stylesConfig.colors.white,
    boxShadow: stylesConfig.shadows.standard,
    borderRadius: "8px",
    p: 2,
    width: {
      xs: "calc(100% - 20px)",
      md: "496px",
    },
  },
  header: {
    display: "flex",
    marginBottom: stylesConfig.spacing.standard,
  },
  closeButton: {
    marginLeft: "auto",
  },
};

export default styles;
