import stylesConfig from "theme/config";

const styles = {
  heading: {
    marginBottom: stylesConfig.spacing.standard,
  },
  table: {
    tableCell: {
      verticalAlign: "text-top",
    },
  },
};

export default styles;
