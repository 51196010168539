import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
const PasswordInput = ({
  id,
  cy,
  label,
  variant,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  autoComplete,
}) => {
  const [showPassword, handleClickShowPassword] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <TextField
        id={id}
        helperText={helperText}
        error={error}
        type={showPassword ? "text" : "password"}
        data-cy={cy}
        required={required}
        inputProps={inputProps}
        label={label}
        variant={variant}
        onChange={onChange}
        sx={{ marginBottom: "1rem", ...sx }}
        autoComplete={autoComplete}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

PasswordInput.defaultProps = {
  variant: "standard",
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: undefined,
  autoComplete: undefined,
};

PasswordInput.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
};
export default PasswordInput;
