import stylesConfig from "theme/config";

const styles = {
  container: {
    position: "relative",
    "&:hover": {
      cursor: "pointer",
      background: stylesConfig.colors.accent,
      "&::after": {
        ...stylesConfig.navigationAccent,
      },
      fontWeight: "600",
    },
  },
  link: {
    width: "100%",
    display: "flex",
    color: stylesConfig.colors.white,
    textDecoration: "none",
    paddingLeft: "48px",
    paddingTop: "17px",
    paddingBottom: "17px",
    alignItems: "center",
  },
  button: {
    width: "100%",
    display: "flex",
    color: stylesConfig.colors.white,
    textDecoration: "none",
    textTransform: "none",
    justifyContent: "flex-start",
    paddingLeft: "48px",
    paddingTop: "17px",
    paddingBottom: "17px",
  },
  icon: {
    marginRight: stylesConfig.spacing.standard,
    minWidth: "24px",
    display: "flex",
  },
  active: {
    background: stylesConfig.colors.accent,
    "&::after": {
      ...stylesConfig.navigationAccent,
    },
    fontWeight: "600",
  },
};

export default styles;
