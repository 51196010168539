import { Routes } from "./routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalContextProvider from "./context/GlobalContext";

function App() {
  return (
    <GlobalContextProvider>
      <Router>
        <Routes />
      </Router>
    </GlobalContextProvider>
  );
}

export default App;
