import api from "lib/http";

const getOrganisationSizingQuestions = async () => {
  const result = await api(
    "get",
    "reference-data/organisation/sizing",
    {},
    true,
    true,
    true
  );
  return result.data;
};

const getJobRoles = async () => {
  const result = await api(
    "get",
    "reference-data/job-roles",
    {},
    true,
    true,
    true
  );
  return result.data;
};

const getSelfAssessmentThemes = async (query = {}) => {
  const queryStringArray = [];
  if (query.categoryId) {
    queryStringArray.push(`categoryId=${query.categoryId}`);
  }

  let queryString = "";
  if (queryStringArray.length) {
    queryString = `?${queryStringArray.join("&")}`;
  }

  const result = await api(
    "get",
    `reference-data/self-assessment/themes${queryString}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const getSelfAssessmentThemeLabels = async (query = {}) => {
  const result = await getSelfAssessmentThemes(query);
  return result.map((theme) => {
    return {
      value: theme.id,
      label: theme.name,
    };
  });
};

const getSelfAssessmentCategories = async () => {
  const result = await api(
    "get",
    "reference-data/self-assessment/categories",
    {},
    true,
    true,
    true
  );
  return result.data;
};

const getQuestionnaireTypes = async () => {
  const result = await api(
    "get",
    "reference-data/questionnaire/types",
    {},
    true,
    true,
    true
  );
  return result.data;
};

const getQuestionnaireTemplate = async () => {
  const result = await api(
    "get",
    "reference-data/questionnaire/template",
    {},
    true,
    true,
    true
  );
  return result.data;
};
const getSizeOptions = async () => {
  const result = await api(
    "get",
    "reference-data/organisation/sizes",
    {},
    true,
    true,
    true
  );
  return result.data;
};

const getScoringOptions = async () => {
  const result = await api(
    "get",
    "reference-data/self-assessment/scoring",
    {},
    true,
    true,
    true
  );
  return result.data;
};

const service = {
  getOrganisationSizingQuestions,
  getJobRoles,
  getSelfAssessmentThemes,
  getSelfAssessmentCategories,
  getQuestionnaireTypes,
  getSizeOptions,
  getQuestionnaireTemplate,
  getSelfAssessmentThemeLabels,
  getScoringOptions,
};

export default service;
