import stylesConfig from "theme/config";

const styles = {
  accordion: {
    background: stylesConfig.colors.primary,
    boxShadow: 0,
    paddingLeft: 0,
    paddingRight: 0,
    "&:hover": {
      background: stylesConfig.colors.accent,
      "&::after": {
        ...stylesConfig.navigationAccent,
      },
    },
    "&::before": {
      height: 0,
    },
    "&>.MuiButtonBase-root": {
      paddingLeft: "48px",
      paddingRight: 0,
      paddingTop: "4px",
      paddingBottom: "4px",
      color: stylesConfig.colors.white,
      minHeight: "56px",
    },
  },
  summary: {
    ".MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
  },

  details: {
    padding: "0 0 0 0",
  },
  icon: { marginRight: stylesConfig.spacing.standard, display: "flex" },
  active: {
    background: stylesConfig.colors.accent,
    "&::after": {
      ...stylesConfig.navigationAccent,
    },
  },
};

export default styles;
