import stylesConfig from "theme/config";

const chartProps = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    stackType: "100%",
    fontFamily: "Montserrat",
    toolbar: {
      show: false,
    },
  },
  colors: [
    stylesConfig.colors.primary,
    stylesConfig.colors.secondary,
    stylesConfig.colors.tertiary,
  ],
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  stroke: {
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    y: {
      formatter: function (val) {
        let s = "s";
        if (val === 1) {
          s = "";
        }
        return `${val} completed assessment${s}`;
      },
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "top",
    horizontalAlign: "center",
    offsetX: 40,
    fontFamily: "Montserrat",
  },
};

export default chartProps;
