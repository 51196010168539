import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { PermissionsGated } from "components/context-aware/PermissionsGated/PermissionsGated";
import Permissions from "lib/permissions";
import SidebarLink from "components/core/links/SidebarLink/SidebarLink";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";

const CollaboratorLinks = () => {
  return (
    <>
      <PermissionsGated
        activities={[Permissions.activities.COLLABORATOR_DASHBOARD]}
      >
        <SidebarLink to="/" text="Home" Icon={HomeOutlinedIcon} />
        <SidebarLink
          to="/neds-guide"
          text="Non Executive Directors Guide"
          Icon={MenuBookOutlinedIcon}
        />
        <SidebarLink
          to="/settings"
          text="Settings"
          Icon={SettingsOutlinedIcon}
        />
      </PermissionsGated>
    </>
  );
};

CollaboratorLinks.propTypes = {};
export default CollaboratorLinks;
