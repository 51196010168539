import React from "react";

import InputField from "components/core/form/Input/Input";
import Button from "components/core/buttons/Primary/Primary";

const ForgottenPasswordForm = ({ onComplete }) => {
  const onFormSubmit = async (e) => {
    e.preventDefault();
    onComplete(e.target.email.value);
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      onSubmit={onFormSubmit}
    >
      <InputField
        id="email"
        cy="email-field"
        label="Email"
        required
        autoComplete="email"
        inputProps={{ maxLength: 246 }}
        type="email"
      />
      <Button
        type="submit"
        text="Reset Password"
        style={{ marginBottom: "2rem" }}
      />
    </form>
  );
};

export default ForgottenPasswordForm;
