import api from "lib/http";
const getOrganisationSelfAssessmentResults = async (pageNo, pageSize) => {
  let pageQuery = `reports/organisation/results?page=${pageNo}&pageSize=${pageSize}`;
  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const getOrganisationOverview = async () => {
  const result = await api(
    "get",
    "reports/organisation/overview",
    null,
    true,
    true,
    true
  );
  return result.data;
};

const getResilienceOverviewScore = async () => {
  const result = await api(
    "get",
    "reports/self-assessments",
    null,
    true,
    true,
    true
  );
  return result.data;
};

const service = {
  getOrganisationSelfAssessmentResults,
  getOrganisationOverview,
  getResilienceOverviewScore,
};

export default service;
