import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import styles from "./Circular.style";
const CircularLoader = ({ sx, cy, contanierSx }) => {
  return (
    <Box sx={{ ...styles.box, ...contanierSx }} data-cy={cy}>
      <CircularProgress
        sx={{
          ...styles.loader,
          ...sx,
        }}
      />
    </Box>
  );
};

CircularLoader.defaultProps = {
  sx: undefined,
  cy: "circular-loader",
  contanierSx: undefined,
};
CircularLoader.propTypes = {
  sx: PropTypes.shape({}),
  contanierSx: PropTypes.shape({}),
  cy: PropTypes.string,
};

export default CircularLoader;
