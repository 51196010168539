import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import Info from "components/core/widgets/organisation/info/Info";
import RadarGraph from "components/context-aware/widgets/Results/Results";

const HomePage = () => {
  return (
    <LoggedIn heading="Home">
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6} xl={8}>
              <RadarGraph />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <Info />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </LoggedIn>
  );
};

export default HomePage;
