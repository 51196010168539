import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import stylesConfig from "theme/config";
const styles = stylesConfig.links;

const InternalLink = ({ to, text, style, theme, cy, children }) => {
  const themeStyles = styles[theme];

  return (
    <Link to={to} style={{ ...themeStyles, ...style }} data-cy={cy}>
      {text}
      {children}
    </Link>
  );
};

InternalLink.defaultProps = {
  style: undefined,
  theme: "",
  cy: undefined,
};

InternalLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.shape({}),
  theme: PropTypes.string,
  cy: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default InternalLink;
