import stylesConfig from "theme/config";
const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    minHeight: "250px",
  },
  input: {
    width: "100%",
  },
  loader: {
    margin: "0 auto",
    paddingTop: stylesConfig.spacing.veryLarge,
  },
  button: {
    alignSelf: "flex-end",
    marginTop: "auto",
  },
};

export default styles;
