import stylesConfig from "theme/config";

const styles = {
  font: {
    font: "font: normal normal normal 16px/19px Montserrat",
    color: stylesConfig.colors.description,
    marginBottom: stylesConfig.spacing.standard,
  },
};

export default styles;
