import stylesConfig from "theme/config";

const styles = {
  container: {
    flexGrow: 1,
    flexDirection: { xs: "column-reverse", lg: "row" },
  },
  actionsContainer: {
    background: "white",
    zIndex: "1201",
    position: { xs: "sticky", lg: "relative" },
    top: { xs: 0 },
  },
  actions: {
    marginTop: { lg: "50px" },
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: "10px",
  },
  scoresWidget: {
    marginBottom: stylesConfig.spacing.standard,
  },
  primaryButton: {
    marginBottom: stylesConfig.spacing.standard,
  },
  secondaryButton: {
    ...stylesConfig.buttons.secondary,
  },
  error: {
    marginTop: stylesConfig.spacing.standard,
    marginBottom: stylesConfig.spacing.standard,
  },
  inactive: {
    height: "calc(100vh - 200px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
};

export default styles;
