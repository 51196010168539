import React, { useState, useEffect, useContext, useReducer } from "react";
import reducers from "reducers/form-errors-reducer";
import { GlobalContext } from "context/GlobalContext";
import PropTypes from "prop-types";
import questionsService from "services/questions/questions-service";
import organisationService from "services/organisation/organisation-service";
import QUESTIONNAIRE_CONSTS from "lib/questionnaire-types";
import ORGANISATION_CONSTS from "lib/business-sizes";
import answerService from "services/answers/answers-service";
import refereneDataService from "services/reference-data/reference-data";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./SelfAssessmentContext.error.handler";
import answerSetHelpers from "lib/answer-helpers/answer-set-helpers";
import notesService from "services/notes/notes-service";
const intialErrorState = {
  general: null,
};

const ErrorQuestionList = ({ questions }) => {
  return (
    <>
      <p>
        The following questons must be answered before you can submit this
        self-assessment.
      </p>
      <p>
        Hint: If you have not attempted each of the following questions please
        do so. Each slider must be manually set to a value.
      </p>
      <div>
        {questions.map((question) => {
          return <div>{question.title}</div>;
        })}
      </div>
    </>
  );
};

export const SelfAssessmentContext = React.createContext({
  title: "",
  isLoading: true,
  selfAssessment: [],
  answers: [],
  answersId: undefined,
  activeSelfAssessment: false,
  questionSet: {},
  updateAnswer: () => {},
  saveAnswers: () => {},
  notes: [],
  errorState: intialErrorState,
  isComplete: false,
  saveNote: () => {},
});

const getOrganisationSize = (value) => {
  const object = ORGANISATION_CONSTS.sizes;
  return Object.keys(object).find((key) => object[key] === value);
};

const shapeQuestions = (questions) => {
  const questionObject = {};
  questions.map((question) => {
    questionObject[question.id] = {
      id: question.id,
      title: question.title,
      isOptional: question.isOptional,
      fieldType: question.fieldType,
    };
    return question;
  });
  return questionObject;
};

const shapeAnswers = (answers) => {
  const answersObject = {};
  answers.map((answer) => {
    answersObject[answer.id] = {
      id: answer.id,
      value: answer.value,
    };
    return answer;
  });
  return answersObject;
};

// const shapeNotes = (answers) => {
//   const notes = {};
//   answers.map((answer) => {
//     notes[answer.id] = answer.notes;
//     return answer;
//   });

//   return notes;
// };

const SelfAssessmentContextProvider = ({ children }) => {
  const [selfAssessment, updateSelfAssessment] = useState({});
  const [questionSet, updateQuestionSet] = useState({});
  const [answers, updateAnswers] = useState();
  const [notes, updateNotes] = useState([]);
  const [title, updateTitle] = useState("");
  const [answerSetId, updateAnswerSetId] = useState(undefined);
  const [activeSelfAssessment, updateActiveSelfAssessment] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [showSubmissionLoader, setButtonLoader] = useState({
    save: false,
    submit: false,
  });
  const context = useContext(GlobalContext);

  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const orgId = context.user.organisation;
        const organisation = await organisationService.getOrganisation(orgId);
        // get the questionnaire
        const questionnaireResp = await questionsService.getQuestions({
          forOrganisationSize: getOrganisationSize(organisation.size),
          type: QUESTIONNAIRE_CONSTS.types.SELF_ASSESSMENT,
          current: true,
        });

        // if there is an active questionnaire
        if (questionnaireResp.length) {
          const [questionnaire] = questionnaireResp;
          const questions = questionnaire.questions;
          const questionnaireId = questionnaire.id;
          let answerData = [];
          const answerRequest = await answerService.getAnswerSet(orgId, {
            questionnaireId: questionnaireId,
          });

          if (!answerRequest.length) {
            const createAnswerSetRequest = await answerService.createAnswerSet(
              orgId,
              questionnaireId
            );

            const getCreatedAnswersRequest = await answerService.getAnswerSet(
              orgId,
              {
                answerId: createAnswerSetRequest.id,
              }
            );

            answerData = getCreatedAnswersRequest;
          } else {
            answerData = answerRequest;
          }

          const templateResp =
            await refereneDataService.getQuestionnaireTemplate();

          const categoryKeys = Object.keys(templateResp);
          // sorting the questions into our template structure
          categoryKeys.map((categoryKey) => {
            const category = templateResp[categoryKey];
            const themeIds = category.themes.map((theme) => theme.id);
            const questionsByCategory = [];
            // loop through the questions, and add them to the category based on their themeId
            questions.map((question) => {
              if (themeIds.includes(question.themeId)) {
                questionsByCategory.push(question);
              }
              return (templateResp[categoryKey].questions =
                questionsByCategory);
            });
            return category;
          });

          const notes = await notesService.getNotes(orgId, answerData[0].id);

          updateQuestionSet(shapeQuestions(questions));
          updateAnswers(shapeAnswers(answerData[0].answers));
          updateNotes(notes);
          updateSelfAssessment(templateResp);
          updateTitle(questionnaire.name);
          updateAnswerSetId(answerData[0].id);
          if (answerData[0].complete) {
            setIsComplete(true);
            updateTitle("");
          }
        } else {
          updateActiveSelfAssessment(false);
        }

        setIsLoading(false);
      } catch (err) {
        if (err instanceof HTTPError) {
          errorHandler(err, () => {
            updateActiveSelfAssessment(false);
            setIsLoading(false);
            setIsComplete(false);
          });
        } else {
          throw err;
        }
      }
    };

    getData();
  }, [context.user.organisation]);

  const updateAnswer = (id, value) => {
    // clear off any general errors
    reducers.clearFieldError("general", errorState, errorDispatch);
    const currentAnswer = answers[id];
    currentAnswer.value = value;
    const updatedAnswer = { [id]: currentAnswer };
    updateAnswers({ ...answers, ...updatedAnswer });
  };

  const saveNote = async (questionId, note) => {
    const orgId = context.user.organisation;
    await notesService.createNewNote(answerSetId, orgId, questionId, {
      note,
      userId: context.user.id,
    });
    const notes = await notesService.getNotes(orgId, answerSetId);
    updateNotes(notes);
  };

  const saveAnswers = async (params = {}, type) => {
    setButtonLoader({ ...showSubmissionLoader, [type]: true });
    // clear off any general errors
    reducers.clearFieldError("general", errorState, errorDispatch);
    const orgId = context.user.organisation;
    const answersToSave = Object.values(answers);

    if (type === "submit") {
      const requiredAnswersCompleteTest = answerSetHelpers.isAnswerSetComplete(
        answersToSave,
        questionSet
      );

      if (!requiredAnswersCompleteTest.complete) {
        reducers.createFormErrorsHandler(
          "general",
          <ErrorQuestionList questions={requiredAnswersCompleteTest.errors} />,
          errorDispatch
        );
        return setButtonLoader({ ...showSubmissionLoader, submit: false });
      }
    }

    try {
      await answerService.patchAnswerSet(answerSetId, orgId, {
        ...params,
        answers: answersToSave,
      });

      if (params.complete) {
        setIsComplete(true);
        updateTitle("");
      }
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
    setButtonLoader({ ...showSubmissionLoader, [type]: false });
  };

  return (
    <SelfAssessmentContext.Provider
      value={{
        isLoading,
        selfAssessment,
        title,
        activeSelfAssessment,
        questionSet,
        answerSetId,
        answers,
        updateAnswer,
        saveAnswers,
        notes,
        errorState,
        showSubmissionLoader,
        isComplete,
        saveNote,
      }}
    >
      {children}
    </SelfAssessmentContext.Provider>
  );
};

SelfAssessmentContextProvider.propTypes = {
  children: PropTypes.node,
};

export default SelfAssessmentContextProvider;
