import stylesConfig from "theme/config";
const styles = {
  accordion: {
    boxShadow: "unset",
    ".MuiAccordionSummary-root": {
      padding: "6px",
    },
  },
  categories: {
    padding: "6px",
  },
  headingContainer: {
    display: "flex",
  },
  result: {
    display: "flex",
    marginBottom: stylesConfig.spacing.large,
  },
  questionnaireDetails: {
    color: stylesConfig.colors.black,
  },
  divider: {
    height: "74px",
    width: "2px",
    background: stylesConfig.colors.lightGrey,
    marginRight: " 12px",
  },
  scores: {
    display: "flex",
    "> div": {
      marginRight: "12px",
    },
  },
  heading: {
    color: stylesConfig.colors.black,
    fontWeight: "600",
    marginRight: stylesConfig.spacing.small,
  },
};

export default styles;
