import React, { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Button from "@mui/material/Button";
import Input from "components/core/form/Input/Input";
import Loader from "components/core/loaders/bouncies/Bouncies";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import RaisedBox from "components/core/raised-box/RaisedBox";

import usersService from "services/users/users-service";

import styles from "./AdminEditUser.style";

import reducers from "reducers/form-errors-reducer";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./AdminEditUser.error.handler";
const intialErrorState = {
  firstName: null,
  lastName: null,
};

const AdminEditUserForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );
  const { id } = useParams();
  const getUser = async () => {
    setIsLoading(true);
    const returnedUser = await usersService.getOrganisationUser(id);
    setFirstName(returnedUser.firstName);
    setLastName(returnedUser.lastName);
    setIsLoading(false);
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const updateTextField = (fieldName, fieldValue) => {
    reducers.clearFieldError(fieldName, errorState, errorDispatch);
    switch (fieldName) {
      case "firstName":
        setFirstName(fieldValue);
        break;
      case "lastName":
        setLastName(fieldValue);
        break;
      default:
        break;
    }
  };

  const cancelEditing = async () => {
    setIsEditing(false);
    await getUser();
    reducers.clearFieldError("firstName", errorState, errorDispatch);
    reducers.clearFieldError("lastName", errorState, errorDispatch);
  };

  const saveUser = async () => {
    setIsLoading(true);
    try {
      await usersService.updateOrganisationUser(id, firstName, lastName);
      setIsEditing(false);
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <RaisedBox>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <HeadingSmall sx={{ marginBottom: 0 }} text="Personal Details" />
            {!isEditing && (
              <Button
                variant="text"
                startIcon={<CreateOutlinedIcon fontSize="small" />}
                sx={styles.editButton}
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            )}
            {isEditing && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="text"
                  startIcon={<CreateOutlinedIcon fontSize="small" />}
                  sx={styles.editButton}
                  onClick={saveUser}
                >
                  Save
                </Button>
                |
                <Button
                  variant="text"
                  sx={styles.cancelButton}
                  onClick={cancelEditing}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Input
              id="firstName"
              cy="first-name-field"
              label="First Name"
              autoComplete="given-name"
              value={firstName}
              disabled={!isEditing}
              error={errorState.firstName ? true : false}
              onChange={(event) =>
                updateTextField("firstName", event.target.value)
              }
              helperText={errorState.firstName}
              inputProps={{ maxLength: 255 }}
            />
            <Input
              id="lastName"
              cy="last-name-field"
              label="Last Name"
              autoComplete="family-name"
              value={lastName}
              disabled={!isEditing}
              error={errorState.lastName ? true : false}
              onChange={(event) =>
                updateTextField("lastName", event.target.value)
              }
              helperText={errorState.lastName}
              inputProps={{ maxLength: 255 }}
            />
          </div>
        </RaisedBox>
      )}
    </>
  );
};

export default AdminEditUserForm;
