import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import styles from "./RaisedBox.style";

const RaisedBox = ({ children, sx }) => {
  return (
    <Paper elevation={4} sx={{ ...styles.root, ...sx }}>
      {children}
    </Paper>
  );
};

RaisedBox.defaultProps = {
  sx: undefined,
};

RaisedBox.propTypes = {
  sx: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default RaisedBox;
