import stylesConfig from "theme/config";

const styles = {
  form: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  input: {
    width: "100%",
    textAlign: "left",
    marginBottom: stylesConfig.spacing.small,
  },
  charCounter: {
    marginBottom: stylesConfig.spacing.standard,
  },
};

export default styles;
