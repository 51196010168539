const styles = {
  form: {
    textAlign: "center",
  },
  input: {
    width: "100%",
  },
  button: {
    margin: "0 auto",
  },
};

export default styles;
