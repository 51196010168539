import React, { useContext } from "react";
import { QuestionnaireContext } from "context/QuestionnaireBuilderContext";
import QuestionnaireContextProvider from "context/QuestionnaireBuilderContext";
import CircularLoader from "components/core/loaders/circular/Circular";
import CategoryPanel from "../CategoryPanel/CategoryPanel";

const Builder = () => {
  const { isLoading, templateData } = useContext(QuestionnaireContext);
  return (
    <>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          {Object.keys(templateData).map((cat) => {
            const category = templateData[cat];
            return <CategoryPanel key={category.id} categoryData={category} />;
          })}
        </>
      )}
    </>
  );
};

const BuilderWithContext = () => {
  return (
    <QuestionnaireContextProvider>
      <Builder />
    </QuestionnaireContextProvider>
  );
};

export default BuilderWithContext;
