import * as React from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ControlledAccordions({ sx, cy, content, heading, id }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      data-cy={cy}
      id={id}
      sx={sx}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="accrdion-content"
        id="accordion-header"
      >
        {heading}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{content}</AccordionDetails>
    </Accordion>
  );
}

ControlledAccordions.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.shape({}),
  cy: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
