import stylesConfig from "theme/config";

const styles = {
  heading: {
    marginBottom: stylesConfig.spacing.standard,
  },
  scale: {
    marginLeft: stylesConfig.spacing.small,
    marginRight: stylesConfig.spacing.small,
  },
  labels: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export default styles;
