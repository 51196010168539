import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import Img from "components/core/img/Img";

const SplitPanel = ({ img, content }) => {
  return (
    <Grid container>
      <Grid
        item
        sx={{
          display: { xs: "none", sm: "block" },
          maxHeight: "100%",
        }}
        sm={3}
      >
        <Img
          src={img}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>
      <Grid
        item
        sx={{
          maxHeight: "100%",
        }}
        xs={12}
        sm={9}
      >
        {content}
      </Grid>
    </Grid>
  );
};

SplitPanel.propTypes = {
  img: PropTypes.string,
  content: PropTypes.instanceOf(Object),
};
export default SplitPanel;
