import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { PermissionsGated } from "components/context-aware/PermissionsGated/PermissionsGated";
import Permissions from "lib/permissions";
import SidebarLink from "components/core/links/SidebarLink/SidebarLink";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SidebarAccordion from "../../sidebar-accordion/SidebarAccordion";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import PersonOutlined from "@mui/icons-material/PersonOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";

const SuperAdminLinks = () => {
  return (
    <>
      <PermissionsGated
        activities={[Permissions.activities.SUPER_ADMIN_DASHBOARD]}
      >
        <SidebarLink
          to="/"
          text="Home"
          Icon={HomeOutlinedIcon}
          cy="super-admin-home"
        />
      </PermissionsGated>
      <PermissionsGated
        activities={[Permissions.activities.COMPANY_MANAGEMENT]}
      >
        <SidebarLink
          to="/companies"
          text="Companies"
          Icon={BusinessOutlinedIcon}
          cy="super-admin-companies"
        />
      </PermissionsGated>
      <PermissionsGated
        activities={[Permissions.activities.SUPER_ADMIN_USER_MANAGEMENT]}
      >
        <SidebarLink
          to="/individuals"
          text="Non-Exec Directors"
          Icon={PersonOutlined}
          cy="super-admin-neds"
        />
      </PermissionsGated>
      <PermissionsGated activities={[Permissions.activities.SURVEY_CREATION]}>
        <SidebarAccordion
          title="Content"
          childrenLinkTo={["/questionnaires"]}
          Icon={InsertChartOutlinedIcon}
          cy="super-admin-content-accordion"
        >
          <>
            <SidebarLink
              to="/questionnaires"
              text="- Questionnaires"
              cy="super-admin-questionniares"
            />
          </>
        </SidebarAccordion>
      </PermissionsGated>
      <SidebarAccordion
        title="Guides"
        childrenLinkTo={["/neds-guide"]}
        Icon={MenuBookOutlinedIcon}
        cy="super-admin-guides-accordion"
      >
        <>
          <SidebarLink
            to="/neds-guide"
            text="- Non Executive Directors Guide"
          />{" "}
        </>
      </SidebarAccordion>
      <SidebarAccordion
        title="Settings"
        childrenLinkTo={["/users", "/settings"]}
        Icon={SettingsOutlinedIcon}
        cy="super-admin-settings-accordion"
      >
        <>
          <PermissionsGated
            activities={[Permissions.activities.SUPER_ADMIN_USER_MANAGEMENT]}
          >
            <SidebarLink
              to="/users"
              text="- Users &amp; Permissions"
              cy="super-admin-user-management"
            />
          </PermissionsGated>
          <SidebarLink to="/settings" text="- Account Settings" />
        </>
      </SidebarAccordion>
    </>
  );
};

SuperAdminLinks.propTypes = {};
export default SuperAdminLinks;
