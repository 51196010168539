import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import Modal from "@mui/material/Modal";
import { QuestionnaireContext } from "context/QuestionnaireBuilderContext";
import Close from "components/core/buttons/Close/Close";
import Box from "@mui/material/Box";
import questionniareService from "services/questionnaire/questionnaire-service";
import PatchQuestionsForm from "components/forms/questionnaires/patch-question/PatchQuestion";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "./QuestionOptions.style";
import errorCodes from "lib/error-codes";
import HTTPError from "lib/errors/http-error";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";

const QuestionnaireOptions = ({ data }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteError, updateDeleteError] = useState("");
  const { patchQuestionsArray, deleteQuestionFromArray } =
    useContext(QuestionnaireContext);
  const params = useParams();
  const id = params.id;

  const deleteQuestion = async () => {
    updateDeleteError("");
    try {
      await questionniareService.deleteQuestion(id, data.id);
      deleteQuestionFromArray(data.id);
    } catch (err) {
      if (err instanceof HTTPError) {
        if (err.statusCode === 422) {
          const errorData = err.data;
          if (
            errorData.code ===
            errorCodes.QUESTIONNAIRE_HAS_ALREADY_BEEN_MADE_ACTIVE
          ) {
            updateDeleteError(
              "Statement cannot be deleted as this questionnaire has been previously marked as active."
            );
          } else {
            throw err;
          }
        }
      } else {
        throw err;
      }
    }
  };

  const onComplete = (questionData) => {
    patchQuestionsArray(questionData);
  };

  return (
    <>
      <>
        <PrimaryButton
          style={styles.deleteButtonStyle}
          text="View/Edit"
          onClick={() => setShowEditModal(true)}
          cy="view-edit-button"
        />
        <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
          <Box sx={{ ...styles.modalStyle, ...styles.editModal }}>
            <Box sx={styles.header}>
              <HeadingSmall text="Edit Statement" />
              <Close onClick={() => setShowEditModal(false)} />
            </Box>
            <PatchQuestionsForm
              data={data}
              categoryId={data.categoryId}
              onComplete={onComplete}
            />
          </Box>
        </Modal>
      </>

      <>
        <PrimaryButton
          style={styles.deleteButtonStyle}
          text="Delete"
          onClick={() => setShowDeleteModal(true)}
          cy="delete-user-button"
        />
        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <Box sx={styles.modalStyle}>
            <Box sx={styles.header}>
              <HeadingSmall text="Delete Statement" />
              <Close onClick={() => setShowDeleteModal(false)} />
            </Box>
            <Paragraph>
              Are you sure you want to delete this statement? It's data will be
              permanently deleted and all reference to it will be removed from
              this questionnaire.
            </Paragraph>
            {deleteError && (
              <ErrorHelperText text={deleteError} sx={styles.error} />
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                style={{
                  background: "#C4104C 0% 0% no-repeat padding-box",
                  border: "none",
                }}
                text="Continue"
                onClick={() => deleteQuestion(data.id)}
              />
            </div>
          </Box>
        </Modal>
      </>
    </>
  );
};

QuestionnaireOptions.defaultProps = {
  onComplete: () => {},
};
QuestionnaireOptions.proTypes = {
  data: PropTypes.shape({}),
  onComplete: PropTypes.func,
};

export default QuestionnaireOptions;
