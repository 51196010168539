import React, { useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import RaisedBox from "components/core/raised-box/RaisedBox";
import { SelfAssessmentContext } from "context/SelfAssessmentContext/SelfAssessmentContext";
import HeadingVerySmall from "components/core/typography/headings/very-small/HeadingVerySmall";
import Box from "@mui/material/Box";
import CircularLoader from "components/core/loaders/circular/Circular";
import styles from "./Scores.style";
import answerHelpers from "lib/answer-helpers/answer-set-helpers";

const Scores = ({ sx }) => {
  const [maxiumSurveyScore, updpateMaximumSurveyScore] = useState(0);
  const [questions, updateQuestions] = useState([]);
  const [isLoading, updateIsLoading] = useState(true);
  const selfAssessmentContext = useContext(SelfAssessmentContext);

  useEffect(() => {
    // get all the questions that are required (not optional)
    const questionsArray = Object.values(selfAssessmentContext.questionSet);
    updateQuestions(questionsArray);
    const maxScore =
      answerHelpers.calculateMaximumQuestionTotal(questionsArray);
    updpateMaximumSurveyScore(maxScore);
    updateIsLoading(false);
  }, [selfAssessmentContext.questionSet]);

  const calculateScore = useCallback(
    (questionsArray) => {
      return answerHelpers.calculateScore(
        questionsArray,
        selfAssessmentContext.answers
      );
    },
    [selfAssessmentContext.answers]
  );

  return (
    <RaisedBox sx={sx}>
      <Box>
        {isLoading ? (
          <Box>
            <Box>
              <HeadingVerySmall>Assessment Score</HeadingVerySmall>
            </Box>
            <Box sx={styles.loaderContainer}>
              <CircularLoader />
            </Box>
          </Box>
        ) : (
          <>
            <HeadingVerySmall sx={styles.headingContainer}>
              <Box component="span" sx={styles.scoreContainer}>
                <Box component="span" sx={styles.heading}>
                  Assessment Score
                </Box>
                <Box component="span" sx={styles.totalScore}>
                  {calculateScore(questions)}/{maxiumSurveyScore}
                </Box>
              </Box>
            </HeadingVerySmall>
            {Object.values(selfAssessmentContext.selfAssessment).map(
              (category) => {
                const currentScore = calculateScore(category.questions);
                const totalPotentialScore =
                  answerHelpers.calculateMaximumQuestionTotal(
                    category.questions
                  );
                return (
                  <Box
                    sx={{
                      ...styles.scoreContainer,
                      ...styles.categoryContainer,
                    }}
                    key={category.id}
                  >
                    <Box component="span" sx={styles.scoreCategoryName}>
                      {category.name}:
                    </Box>
                    <Box component="span" sx={styles.score}>
                      {currentScore} out of {totalPotentialScore}
                    </Box>
                  </Box>
                );
              }
            )}
          </>
        )}
      </Box>
    </RaisedBox>
  );
};

Scores.propTypes = { sx: PropTypes.shape({}) };
export default Scores;
