import React from "react";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";

import SinglePanel from "components/layouts/single-panel/SinglePanel";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import InternalLink from "components/core/links/Internal/InternalLink";
import Img from "components/core/img/Img";

import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";

import styles from "./ForgottenPasswordPage.styles.js";

export const ForgottenPasswordLinkSentPage = () => {
  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: { xs: "550px" },
            }}
          >
            <Img src={RFLogo} alt="Resilience First Logo" style={styles.logo} />
            <HeadingLarge text="Password Link Sent" />
            <Paragraph text="You should receive an email shortly with a link to reset your password." />
            <InternalLink
              to="/login"
              text="Return to Sign In"
              theme="default"
              style={{ textAlign: "center" }}
            />
          </Container>
        </Box>
      }
    />
  );
};
