import reducers from "reducers/form-errors-reducer";
import errorCodes from "lib/error-codes";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "name") {
      if (errorData.code === errorCodes.NOT_UNIQUE) {
        return reducers.createFormErrorsHandler(
          "name",
          "This name is already taken.",
          errorDispatch
        );
      }

      return reducers.createFormErrorsHandler(
        "name",
        "Please try another name.",
        errorDispatch
      );
    }

    if (errorData.field === "website") {
      return reducers.createFormErrorsHandler(
        "website",
        "Website error, pleasae try a different url.",
        errorDispatch
      );
    }
  } else {
    throw err;
  }
};

export default errorHandler;
