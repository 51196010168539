const styles = {
  actions: {
    width: "100%",
    display: "flex",
    marginBottom: "2rem",
  },
  actionButton: {
    marginLeft: "auto",
  },
};

export default styles;
