import React, { useReducer, useState } from "react";
import reducers from "reducers/form-errors-reducer";
import PropTypes from "prop-types";
import Input from "components/core/form/Input/Input";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "./CreateNewQuestionnaire.style";
import questionnaireService from "services/questionnaire/questionnaire-service";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./CreateNewQuestionnaire.error.handler";
import ReferenceDataSelect from "components/core/form/ReferenceDataSelect/ReferenceDataSelect";
import referenceDataService from "services/reference-data/reference-data";
import QUESTIONNAIRE_CONSTS from "lib/questionnaire-types";

const intialErrorState = {
  name: null,
  type: null,
  size: null,
};

const NewQuestionnaire = ({ onComplete, hideType }) => {
  const [loading, updateLoading] = useState(false);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );
  const onSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);
    try {
      const payload = {
        name: e.target.name.value,
        type: hideType
          ? QUESTIONNAIRE_CONSTS.types.SELF_ASSESSMENT
          : e.target.type.value,
        size: e.target.size.value,
      };

      await questionnaireService.createNewQuestionnaire(payload);
      onComplete(payload.type);
    } catch (err) {
      updateLoading(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };
  return (
    <>
      <form onSubmit={onSubmit} style={styles.form}>
        <Input
          id="name"
          cy="name-field"
          label="Name"
          required
          sx={styles.input}
          error={errorState.name ? true : false}
          onChange={() =>
            reducers.clearFieldError("name", errorState, errorDispatch)
          }
          helperText={errorState.name}
          inputProps={{ maxLength: 255 }}
        />

        {!hideType && (
          <ReferenceDataSelect
            id="type"
            error={errorState.type ? true : false}
            label={"Type"}
            cy={`type-field`}
            onChange={() =>
              reducers.clearFieldError("type", errorState, errorDispatch)
            }
            sx={styles.input}
            helperText={errorState.type}
            service={referenceDataService.getQuestionnaireTypes}
            value=""
            required
          />
        )}
        <ReferenceDataSelect
          id="size"
          error={errorState.size ? true : false}
          label={"Company Size"}
          cy={`size-field`}
          service={referenceDataService.getSizeOptions}
          onChange={() =>
            reducers.clearFieldError("size", errorState, errorDispatch)
          }
          sx={styles.input}
          helperText={errorState.size}
          value=""
          required
        />

        <PrimaryButton
          type="submit"
          text="Create"
          loading={loading}
          style={styles.button}
        />
      </form>
    </>
  );
};

NewQuestionnaire.defaultProps = {
  onComplete: undefined,
  hideType: false,
};

NewQuestionnaire.propTypes = {
  onComplete: PropTypes.func,
  hideType: PropTypes.bool,
};
export default NewQuestionnaire;
