import React from "react";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import Loader from "components/core/loaders/bouncies/Bouncies";
import PdfViewer from "components/data-display/Pdf/PdfViewer";

const NedsPage = () => {
  const isLoading = false;

  return (
    <LoggedIn usePlainContentWrapper={true}>
      {isLoading && <Loader />}
      {!isLoading && (
        <div style={{ height: "100%", width: "100%" }}>
          <PdfViewer url="/rf_neds_july2022_final_final.pdf" />
        </div>
      )}
    </LoggedIn>
  );
};

export default NedsPage;
