import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import InternalLink from "components/core/links/Internal/InternalLink";
import InputField from "components/core/form/Input/Input";
import PasswordField from "components/core/form/PasswordInput/PasswordInput";
import Button from "components/core/buttons/Primary/Primary";
import styles from "./LoginForm.styles";
import { GlobalContext } from "context/GlobalContext";
import AuthenticationError from "lib/errors/authentication-error";

const LoginForm = () => {
  const { login } = useContext(GlobalContext);
  const history = useHistory();
  const [loginError, updateLoginError] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(e.target.email.value, e.target.password.value);
      history.push("/");
    } catch (err) {
      if (err instanceof AuthenticationError) {
        updateLoginError("There was a problem logging in.");
      } else {
        throw err;
      }
    }
  };
  return (
    <form
      onSubmit={onSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <InputField
        id="email"
        cy="email-field"
        label="Email"
        type="email"
        required
        autoComplete="email"
        error={loginError ? true : false}
        onChange={() => updateLoginError("")}
        inputProps={{ maxLength: 246 }}
      />
      <PasswordField
        id="password"
        cy="password-field"
        label="Password"
        required
        autoComplete="current-password"
        inputProps={{ maxLength: 500 }}
        sx={{ paddingBottom: "1.5rem" }}
        error={loginError ? true : false}
        onChange={() => updateLoginError("")}
        helperText={loginError}
      />
      <Box sx={styles.formFooter}>
        <Box sx={styles.internalLink}>
          <InternalLink
            to="/forgotten-password"
            text="Forgot Password?"
            theme="default"
          />
        </Box>
        <Button type="submit" text="Login" />
      </Box>
    </form>
  );
};

export default LoginForm;
