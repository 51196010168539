import stylesConfig from "theme/config";

const styles = {
  policy: {
    pt: 3,
    textAlign: "center",
    width: 1,
  },
  logo: {
    maxWidth: "100%",
    alignSelf: "flex-start",
    marginBottom: stylesConfig.spacing.veryLarge,
  },
};

export default styles;
