import reducers from "reducers/form-errors-reducer";
import errorCodes from "lib/error-codes";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;
    if (errorData.code === errorCodes.NO_QUESTIONS_IN_QUESTIONNAIRE) {
      return errorDispatch();
    }

    if (errorData.code === errorCodes.INVALID_RESOURCE) {
      return reducers.createFormErrorsHandler(
        "general",
        "Could not be saved because the answer or questionnare no longer exists.",
        errorDispatch
      );
    }

    if (errorData.code === errorCodes.ANSWER_SET_IS_COMPLETE) {
      return reducers.createFormErrorsHandler(
        "general",
        "Could not update the answers as they have already been marked as complete.",
        errorDispatch
      );
    }

    if (errorData.code === errorCodes.ANSWER_ID_NOT_FOUND_IN_QUESTIONNARE) {
      return reducers.createFormErrorsHandler(
        "general",
        "Could not be saved because one of the answers could not be found in the questionnare.",
        errorDispatch
      );
    }

    if (errorData.code === errorCodes.ANSWER_SET_IS_NOT_COMPLETE) {
      return reducers.createFormErrorsHandler(
        "general",
        "Could not be saved because all the required questions have not been answered.",
        errorDispatch
      );
    }

    throw err;
  } else {
    throw err;
  }
};

export default errorHandler;
