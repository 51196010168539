import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import qs from "qs";

import authenticationService from "services/authentication/authentication-service";

import SinglePanel from "components/layouts/single-panel/SinglePanel";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import NewPasswordForm from "components/forms/auth/NewPassword/NewPasswordForm";
import Img from "components/core/img/Img";

import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";

import styles from "./NewPasswordPage.styles.js";

export const NewPasswordPage = () => {
  const [token, setToken] = useState("false");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const validateAndSetToken = async () => {
      // Redirect to invalid token page if no token in query params.
      const parsedQs = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      if (!parsedQs.token) {
        history.push("/invalid-password-reset-token");
        // we return a clean up function to stop further state changes
        return () => {};
      }

      // Redirect to invalid token page if the token is not valid
      const { valid } = await authenticationService.validatePasswordResetToken(
        parsedQs.token
      );

      if (!valid) {
        history.push("/invalid-password-reset-token");
        return () => {};
      }

      // All appears OK - Set the token!
      setToken(parsedQs.token);
    };

    validateAndSetToken();
  }, [setToken, location.search, history]);

  const newPasswordSet = async (password) => {
    try {
      const newPasswordResponse = await authenticationService.newPassword(
        token,
        password
      );

      return history.push(
        `/password-reset-success?signInLink=${newPasswordResponse.signInLink}`
      );
    } catch (passwordError) {
      return passwordError;
    }
  };

  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: { xs: "550px" },
            }}
          >
            <Img src={RFLogo} alt="Resilience First Logo" style={styles.logo} />
            <HeadingLarge text="Reset Your Password" />
            <Paragraph text="Please enter and confirm your new password below." />
            <NewPasswordForm onComplete={newPasswordSet} />
          </Container>
        </Box>
      }
    />
  );
};
