import stylesConfig from "theme/config";

const styles = {
  container: {
    marginBottom: stylesConfig.spacing.large,
  },
  heading: {
    marginBottom: stylesConfig.spacing.standard,
  },
  slider: {
    zIndex: 1201,
  },
};

export default styles;
