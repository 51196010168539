import React, { useContext, useEffect } from "react";

import { GlobalContext } from "../../../context/GlobalContext";

const LogoutPage = () => {
  const { logout } = useContext(GlobalContext);
  useEffect(() => {
    const doLogout = async () => {
      await logout();
    };
    doLogout();
  });

  return <div>Logging Out ...</div>;
};

export default LogoutPage;
