import React, { useReducer, useState } from "react";
import reducers from "reducers/form-errors-reducer";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Input from "components/core/form/Input/Input";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "./AddNewNote.style";

import HTTPError from "lib/errors/http-error";
import errorHandler from "./AddNewNote.error.handler";

const intialErrorState = {
  note: null,
};

const AddNewNote = ({ onComplete, id, sx }) => {
  const [loading, updateLoading] = useState(false);
  const [note, updateNote] = useState("");

  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!note.length) {
      return reducers.createFormErrorsHandler(
        "note",
        "Note field cannot be empty.",
        errorDispatch
      );
    }
    updateLoading(true);
    try {
      const formData = {
        note: note,
      };

      await onComplete(formData);
      updateNote("");
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
    updateLoading(false);
  };
  return (
    <Box id={id} style={{ ...styles.form, ...sx }}>
      <Input
        id="note"
        cy="note-field"
        label="Add a Note"
        required
        multiline
        sx={styles.input}
        error={errorState.note ? true : false}
        onChange={(e) => {
          reducers.clearFieldError("note", errorState, errorDispatch);
          updateNote(e.target.value);
        }}
        value={note}
        helperText={errorState.note}
        inputProps={{ maxLength: 500 }}
      />
      <Box component="span" sx={styles.charCounter}>
        {note.length} / 500 characters
      </Box>
      <PrimaryButton
        type="submit"
        text="Add Note"
        loading={loading}
        style={styles.button}
        onClick={onSubmit}
      />
    </Box>
  );
};

AddNewNote.defaultProps = {
  onComplete: undefined,
  sx: undefined,
  id: undefined,
};

AddNewNote.propTypes = {
  onComplete: PropTypes.func,
  sx: PropTypes.shape({}),
  id: PropTypes.string,
};
export default AddNewNote;
