import React, { useContext, useEffect, useState, Fragment } from "react";
import scoresService from "services/scores/scores-service";
import { SelfAssessmentContext } from "context/SelfAssessmentContext/SelfAssessmentContext";
import { GlobalContext } from "context/GlobalContext";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import HeadingVerySmall from "components/core/typography/headings/form/HeadingForm";
import Paragrph from "components/core/typography/description/Description";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import RadarChart from "components/core/graphs/RadarGraph/RadarGraph";
import styles from "../Results.styles";
import chartProps from "../Results.chart.options";
import scoreHelpers from "lib/score-helpers/score-helpers";
import CircularLoader from "components/core/loaders/circular/Circular";
const Feedback = () => {
  const selfAssessmentContext = useContext(SelfAssessmentContext);
  const globalContext = useContext(GlobalContext);
  const [scoreData, updateScoreData] = useState([]);
  const [responseData, updateResponsData] = useState({});
  const [isLoading, updateIsLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      if (selfAssessmentContext.answerSetId) {
        const orgId = globalContext.user.organisation;
        const answerId = selfAssessmentContext.answerSetId;

        const scoresResp = await scoresService.getScores(orgId, answerId);
        const scores = scoreHelpers.shapescoreDataForRadarGraph(
          scoresResp.categories
        );

        updateResponsData(scoresResp);
        updateScoreData(scores);
        updateIsLoading(false);
      }
    };

    getData();
  }, [globalContext.user.organisation, selfAssessmentContext.answerSetId]);

  return (
    <>
      {isLoading ? (
        <CircularLoader contanierSx={styles.loader} />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} xl={6} sx={styles.graphWrapper}>
              <RadarChart
                cy="self-assessment-radar-chart"
                sx={styles.graph}
                options={{
                  chart: {
                    id: "seld-assesment-radar-chart",
                    toolbar: chartProps.toolbar,
                    stroke: {
                      show: false,
                    },
                  },
                  stroke: chartProps.stroke,
                  fill: chartProps.fill,
                  markers: chartProps.markers,
                  plotOptions: chartProps.plotOptions,
                  xaxis: {
                    categories: scoreData.categories,
                    labels: {
                      style: {
                        colors: scoreData.categories.map(() => "#000"),
                        fontFamily: "Montserrat",
                      },
                    },
                  },
                  yaxis: {
                    min: scoreData.min,
                    max: scoreData.max,
                    tickAmount: scoreData.interval,
                    show: false,
                  },
                }}
                series={[
                  {
                    name: "results",
                    data: scoreData.data,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} xl={6}>
              <HeadingSmall sx={styles.heading}>
                <Box sx={styles.resultTitle}>
                  Your overall resilience score is:
                </Box>
                <Box>
                  {responseData.result}{" "}
                  <Box component="span" sx={styles.span}>
                    {responseData.percentage}%
                  </Box>
                </Box>
              </HeadingSmall>
              <HeadingVerySmall sx={styles.title}>
                About these results
              </HeadingVerySmall>
              <Paragrph sx={styles.resultsCopy}>
                {responseData.resultsCopy}
              </Paragrph>
              <HeadingVerySmall sx={styles.title}>
                Score Breakdown
              </HeadingVerySmall>
              {}
              <Grid container spacing={1}>
                {responseData.categories.map((category, index) => {
                  const name = category.name;
                  const percentage = category.percentage;

                  return (
                    <Fragment key={category.id}>
                      <Grid item xs={12} xl={6}>
                        <Box sx={styles.categories}>
                          <Box component={"span"}> {name}: </Box> {percentage}
                          {"%"}
                        </Box>
                      </Grid>
                    </Fragment>
                  );
                })}
                <Grid item xs={12} xl={6}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

Feedback.propTypes = {};

export default Feedback;
