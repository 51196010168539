import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import LoggedIn from "components/layouts/logged-in/LoggedIn";
import AdminEditUserForm from "components/forms/user/AdminEditUser/AdminEditUser";
import InternalLink from "components/core/links/Internal/InternalLink";

import styles from "./AdminEditUser.style";

const AdminEditUserPage = () => {
  return (
    <LoggedIn
      heading="View Collaborator"
      header={
        <InternalLink style={styles.link} to="/users">
          <KeyboardArrowLeftIcon />
          Back
        </InternalLink>
      }
    >
      <AdminEditUserForm />
    </LoggedIn>
  );
};

export default AdminEditUserPage;
