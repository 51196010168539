import stylesConfig from "theme/config";

const styles = {
  logo: {
    maxWidth: "100%",
    alignSelf: "flex-start",
    marginBottom: stylesConfig.spacing.veryLarge,
  },
  signupLink: {
    marginTop: stylesConfig.spacing.veryLarge,
    font: "normal normal bold 16px/15px Montserrat",
  },
  override: {
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
};

export default styles;
