import stylesConfig from "theme/config";

const styles = {
  container: {
    minHeight: "300px",
  },
  noResults: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "300px",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    marginBottom: stylesConfig.spacing.standard,
  },
};

export default styles;
