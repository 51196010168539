import stylesConfig from "theme/config";

const styles = {
  tableContainer: {
    marginBottom: stylesConfig.spacing.veryLarge,
  },
  container: {
    flexGrow: 1,
    flexDirection: { xs: "column-reverse", lg: "row" },
  },
  heading: {
    marginBottom: stylesConfig.spacing.large,
  },
  noData: {
    marginTop: stylesConfig.spacing.standard,
    marginBottom: stylesConfig.spacing.standard,
    color: stylesConfig.colors.description,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
  },
  counter: {
    marginLeft: "4px",
    marginRight: "4px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    color: stylesConfig.colors.black,
    fontWeight: "bold",
  },
  button: {
    ...stylesConfig.links.secondaryButton,
    width: "100%",
  },
  modal: {
    width: { xs: "calc(100vw - 20px)", lg: "1016px" },
  },
};

export default styles;
