import api from "lib/http";

const getScores = async (orgId, answerId) => {
  const result = await api(
    "get",
    `scores/${answerId}/organisation/${orgId}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const service = {
  getScores,
};

export default service;
