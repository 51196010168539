const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    marginBottom: "16px",
  },
  themeSelect: {
    marginBottom: 0,
    width: "100%",
  },
  input: {
    width: "100%",
  },
  button: {
    margin: "0 auto",
  },
};

export default styles;
