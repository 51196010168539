import React from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import SinglePanel from "components/layouts/single-panel/SinglePanel.jsx";
import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import Img from "components/core/img/Img";
import Box from "@mui/material/Box";
import InternalLink from "components/core/links/Internal/InternalLink";
import styles from "./404.style.js";

const LoggedOutNoRouteFound = ({ link, linkText }) => {
  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: { xs: "550px" },
            }}
          >
            <Img src={RFLogo} alt="Resilience First Logo" style={styles.logo} />
            <HeadingLarge text="404" />
            <Paragraph text="This page doesn't exist." />
            <Paragraph>
              <InternalLink to={link} text={linkText} cy="home" />
            </Paragraph>
          </Container>
        </Box>
      }
    />
  );
};

LoggedOutNoRouteFound.defaultProps = {
  link: "/",
  linkText: "Home",
};

LoggedOutNoRouteFound.propTypes = {
  link: PropTypes.string,
  linkText: PropTypes.string,
};

export default LoggedOutNoRouteFound;
