import errorCodes from "lib/error-codes";
import reducers from "reducers/form-errors-reducer";

const renderError = (name, errorData, errorDispatch) => {
  if (errorData.code === errorCodes.INCORRECT_DATA_TYPE) {
    return reducers.createFormErrorsHandler(
      name,
      "Field value should be a number.",
      errorDispatch
    );
  } else if (errorData.code === errorCodes.BELOW_MIN_VALUE) {
    return reducers.createFormErrorsHandler(
      name,
      "Field value is below the minimum that can be accepted.",
      errorDispatch
    );
  } else if (errorData.code === errorCodes.ABOVE_MAX_VALUE) {
    return reducers.createFormErrorsHandler(
      name,
      "Field value is above the maximum that can be accepted.",
      errorDispatch
    );
  } else {
    return reducers.createFormErrorsHandler(
      name,
      "Field value is not valid.",
      errorDispatch
    );
  }
};

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;
    if (errorData.field === "employees") {
      renderError("employees", errorData, errorDispatch);
    }

    if (errorData.field === "industry") {
      renderError("industry", errorData, errorDispatch);
    }

    if (errorData.field === "turnover") {
      renderError("turnover", errorData, errorDispatch);
    }
  } else {
    throw err;
  }
};

export default errorHandler;
