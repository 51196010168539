import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import EditBox from "components/core/edit-box/EditBox";
import Loader from "components/core/loaders/bouncies/Bouncies";

import EditUserForm from "components/forms/user/EditUser/EditUser";

const Settings = () => {
  const [personalDetails, updatePersonalDetails] = useState(false);

  const isLoading = false;
  return (
    <LoggedIn heading="Settings">
      {isLoading && <Loader />}
      {!isLoading && (
        <Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <EditBox
                  heading={"Personal Details"}
                  onActive={updatePersonalDetails}
                  active={personalDetails}
                  idleState={
                    <EditUserForm disableAll={true} hideSaveButton={true} />
                  }
                  editState={
                    <EditUserForm
                      onComplete={() => updatePersonalDetails(false)}
                    />
                  }
                ></EditBox>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </LoggedIn>
  );
};

export default Settings;
