import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import InternalLink from "components/core/links/Internal/InternalLink";
import styles from "./SidebarLink.style";

const SidebarLink = ({ to, text, Icon, cy, onClick }) => {
  const location = useLocation();
  let active = {};
  if (location.pathname === to) {
    active = styles.active;
  }

  return (
    <Box sx={{ ...styles.container, ...active }}>
      {to ? (
        <InternalLink to={to} style={styles.link} cy={cy}>
          <Box sx={styles.icon}>{Icon && <Icon />}</Box>
          {text}
        </InternalLink>
      ) : (
        <Button style={styles.button} onClick={onClick} data-cy={cy}>
          <Box sx={styles.icon}>{Icon && <Icon />}</Box>
          {text}
        </Button>
      )}
    </Box>
  );
};

SidebarLink.propTypes = {
  text: PropTypes.string,
  Icon: PropTypes.shape({}),
  cy: PropTypes.string,
};
export default SidebarLink;
