import api from "lib/http";

const getQuestions = async (queryParams = {}) => {
  const wheres = [];

  if (queryParams.type) {
    wheres.push(`type=${queryParams.type}`);
  }

  if (queryParams.current) {
    wheres.push(`current=${queryParams.current}`);
  }

  if (queryParams.forOrganisationSize) {
    wheres.push(`forOrganisationSize=${queryParams.forOrganisationSize}`);
  }

  let whereQuery = "";
  if (wheres.length) {
    whereQuery = `?${wheres.join("&")}`;
  }

  const result = await api(
    "get",
    `questions${whereQuery}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const service = {
  getQuestions,
};

export default service;
