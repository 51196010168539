import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "../../../context/GlobalContext";
import Permissions from "../../../lib/permissions";

export const PermissionsGated = ({ activities, children }) => {
  const globalContext = useContext(GlobalContext);
  let { user } = globalContext;

  if (!user) {
    return <></>;
  }

  const hasPermission = Permissions.doesUserHaveActivities(
    user.activities,
    activities
  );

  if (!hasPermission) {
    return <></>;
  }

  return <>{children}</>;
};

PermissionsGated.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};
