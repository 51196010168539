import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import InternalLink from "components/core/links/Internal/InternalLink";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import questionnaireService from "services/questionnaire/questionnaire-service";
import styles from "./Questionnaire.style";
import QuestionnaireBuilder from "components/context-aware/QuestionnaireBuilder/QuestionnaireBuilder/QuestionnaireBuilder";
import questionnairreHelpers from "lib/questionnaire-helpers";

const Questionnaires = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, updateData] = useState({});

  const params = useParams();
  useEffect(() => {
    const getData = async () => {
      const response = await questionnaireService.getQuestionnaire(params.id);
      updateData(response);
      setIsPageLoading(false);
    };

    getData();
  }, [params.id]);

  return (
    <LoggedIn
      heading={data.name}
      smallHeading={questionnairreHelpers.uiStringBusinessSize(
        data.forOrganisationSize
      )}
      header={
        <InternalLink style={styles.link} to="/questionnaires">
          <KeyboardArrowLeftIcon />
          Back
        </InternalLink>
      }
      loading={isPageLoading}
    >
      <QuestionnaireBuilder />
    </LoggedIn>
  );
};

export default Questionnaires;
