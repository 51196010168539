import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import NoRouteFound from "pages/errors/LoggedOut/404";
import { LoginPage } from "pages/authentication/login-page/LoginPage";
import { ForgottenPasswordPage } from "pages/authentication/forgotten-password/ForgottenPasswordPage";
import { ForgottenPasswordLinkSentPage } from "pages/authentication/forgotten-password/ForgottenPasswordLinkSentPage";
import { NewPasswordPage } from "pages/authentication/new-password/NewPasswordPage";
import { InvalidPasswordResetTokenPage } from "pages/authentication/new-password/InvalidPasswordResetTokenPage";
import { PasswordSuccessfullyResetPage } from "pages/authentication/password-successfully-reset/PasswordSuccessfullyResetPage";
import { CompleteRegistrationPage } from "pages/registration/complete-registration/CompleteRegistrationPage";
import { InvalidRegistrationTokenPage } from "pages/registration/complete-registration/InvalidRegistrationTokenPage";
import { RegistrationCompletePage } from "pages/registration/complete-registration/RegistrationCompletePage";
import { SelfRegister } from "pages/registration/self-register/SelfRegisterPage";

export const LoggedOutRoutes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route
        exact
        path="/forgotten-password"
        component={ForgottenPasswordPage}
      />
      <Route
        exact
        path="/forgotten-password/link-sent"
        component={ForgottenPasswordLinkSentPage}
      />
      <Route exact path="/new-password" component={NewPasswordPage} />
      <Route
        exact
        path="/invalid-password-reset-token"
        component={InvalidPasswordResetTokenPage}
      />
      <Route
        exact
        path="/password-reset-success"
        component={PasswordSuccessfullyResetPage}
      />
      <Route
        exact
        path="/complete-registration"
        component={CompleteRegistrationPage}
      />
      <Route
        exact
        path="/invalid-registration-token"
        component={InvalidRegistrationTokenPage}
      />
      <Route
        exact
        path="/registration-completed"
        component={RegistrationCompletePage}
      />
      <Route exact path="/self-register" component={SelfRegister} />
      <Redirect exact from="/" to="/login" />
      <Route
        path="*"
        render={() => <NoRouteFound link="/login" linkText="Login" />}
      />
    </Switch>
  );
};
