import stylesConfig from "theme/config";

const chartProps = {
  stroke: {
    show: false,
  },
  fill: {
    opacity: 0.5,
    colors: [stylesConfig.colors.primary],
  },
  markers: {
    colors: [stylesConfig.colors.primary],
  },
  zoom: {
    enabled: false,
  },
  toolbar: {
    show: false,
  },
  plotOptions: {
    radar: {
      polygons: {
        strokeColors: stylesConfig.colors.black,
        connectorColors: stylesConfig.colors.black,
        strokeWidth: 0.5,
        fill: {
          colors: ["#fff"],
        },
      },
    },
  },
};

export default chartProps;
