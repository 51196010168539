import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";

import SinglePanel from "components/layouts/single-panel/SinglePanel";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import Img from "components/core/img/Img";
import InternalLink from "components/core/links/Internal/InternalLink";

import RFPanelImg from "assets/system.png";
import RFLogo from "assets/Logo-RF-Colour.svg";

import styles from "./PasswordSuccessfullyResetPage.styles.js";

export const PasswordSuccessfullyResetPage = () => {
  const [signInLink, updateSignInLink] = useState("/login");
  const location = useLocation();

  useEffect(() => {
    const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (qsToken.signInLink) {
      updateSignInLink(qsToken.signInLink);
    }
  }, [updateSignInLink, location.search]);

  return (
    <SinglePanel
      img={RFPanelImg}
      content={
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: { xs: "550px" },
            }}
          >
            <Img src={RFLogo} alt="Resilience First Logo" style={styles.logo} />
            <HeadingLarge text="Password Reset" />
            <Paragraph text="Your password has been reset successfully." />
            <InternalLink
              to={signInLink}
              text="Return to Sign In"
              theme="default"
              style={{ textAlign: "center" }}
            />
          </Container>
        </Box>
      }
    />
  );
};
