import api from "lib/http";
import _ from "lodash";

const createNewQuestionnaire = async (data = {}) => {
  const result = await api(
    "post",
    `questionnaires/new`,
    data,
    true,
    true,
    true
  );
  return result.data;
};

const getQuestionnaires = async (queryParams = {}) => {
  const wheres = [];
  if (queryParams.type) {
    wheres.push(`type=${queryParams.type}`);
  }

  if (queryParams.page) {
    wheres.push(`page=${queryParams.page}`);
  }

  if (queryParams.pageSize) {
    wheres.push(`pageSize=${queryParams.pageSize}`);
  }

  let whereQuery = "";
  if (wheres.length) {
    whereQuery = `?${wheres.join("&")}`;
  }

  const result = await api(
    "get",
    `questionnaires${whereQuery}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const patchQuestionnaire = async (id, data = {}) => {
  const result = await api(
    "patch",
    `questionnaires/${id}`,
    data,
    true,
    true,
    true
  );
  return result.data;
};

const getQuestionnaire = async (id) => {
  const result = await api("get", `questionnaires/${id}`, {}, true, true, true);
  return result.data;
};

const deleteQuestionnaire = async (id) => {
  const result = await api(
    "delete",
    `questionnaires/${id}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const addAQuestionToQuestionnaire = async (id, categoryId, data = {}) => {
  if (_.has(data, "themeId")) data.themeId = parseInt(data.themeId);

  const result = await api(
    "post",
    `questionnaires/${id}/category/${categoryId}/question/add`,
    data,
    true,
    true,
    true
  );
  return result.data;
};

const patchQuestion = async (questionnaireId, questionId, data = {}) => {
  if (_.has(data, "themeId")) data.themeId = parseInt(data.themeId);
  const result = await api(
    "patch",
    `questionnaires/${questionnaireId}/question/${questionId}`,
    data,
    true,
    true,
    true
  );
  return result.data;
};

const deleteQuestion = async (questionnaireId, questionId) => {
  const result = await api(
    "delete",
    `questionnaires/${questionnaireId}/question/${questionId}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const service = {
  createNewQuestionnaire,
  getQuestionnaires,
  getQuestionnaire,
  patchQuestionnaire,
  deleteQuestionnaire,
  addAQuestionToQuestionnaire,
  patchQuestion,
  deleteQuestion,
};

export default service;
