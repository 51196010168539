import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import Img from "components/core/img/Img";
import styles from "./SinglePanel.styles";
const SinglePanel = ({ content, img, styleOverride }) => {
  return (
    <Box sx={styles.background}>
      <Container>
        <Box sx={styles.card}>
          {img && (
            <Box sx={styles.imgContainer}>
              <Img src={img} style={styles.img} alt="skyscrapers" />
            </Box>
          )}
          <Box
            sx={{
              ...styles.content,
              ...(styleOverride && styleOverride.content),
            }}
          >
            {content}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

SinglePanel.propTypes = {
  content: PropTypes.instanceOf(Object),
};
export default SinglePanel;
