import React, { useContext } from "react";
import { SelfAssessmentContext } from "context/SelfAssessmentContext/SelfAssessmentContext";
import PirmaryButton from "components/core/buttons/Primary/Primary";
import styles from "../self-assessment/SelfAssessment.style";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";

const BoilerPlate = () => {
  const context = useContext(SelfAssessmentContext);
  return (
    <>
      <PirmaryButton
        text="Save"
        loading={context.showSubmissionLoader.save}
        disabled={context.showSubmissionLoader.submit}
        style={styles.primaryButton}
        onClick={() => context.saveAnswers({}, "save")}
      />
      <PirmaryButton
        text="Submit"
        loading={context.showSubmissionLoader.submit}
        disabled={context.showSubmissionLoader.save}
        style={styles.secondaryButton}
        onClick={() => context.saveAnswers({ complete: true }, "submit")}
      />
      {context.errorState.general && (
        <ErrorHelperText text={context.errorState.general} sx={styles.error} />
      )}
    </>
  );
};

BoilerPlate.propTypes = {};
export default BoilerPlate;
