import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Accordion from "components/core/accordion/Accordion";
import Paragraph from "components/core/typography/description/Description";
import styles from "./ScoresAccordion.style";
import ScoreCard from "components/reporting/ScoreCard/ScoreCard";
import utils from "lib/utils";
const ScoresAccordion = ({ id, sx, data, value }) => {
  return (
    <Accordion
      id={id}
      sx={styles.accordion}
      heading={<Box>{value}</Box>}
      content={data.map((result, index) => {
        return (
          <Box sx={styles.categories} key={result.name}>
            {index > 0 && (
              <Box component="p" sx={styles.result}>
                {result.result}
              </Box>
            )}
            <Box sx={styles.headingContainer}>
              <Paragraph sx={styles.heading}>{result.name}</Paragraph> -{" "}
              {utils.getUIDateString(result.questionnaireCreated)}
            </Box>
            <Paragraph sx={styles.questionnaireDetails}>
              Assessment Results:{" "}
            </Paragraph>
            <Box sx={styles.scores}>
              <ScoreCard label={"Total Resilience"} score={result.percentage} />
              <Box component="span" sx={styles.divider}></Box>
              {result.categories.map((category) => (
                <ScoreCard
                  key={category.name}
                  label={category.name}
                  score={category.percentage}
                />
              ))}
            </Box>
          </Box>
        );
      })}
    />
  );
};

ScoresAccordion.propTypes = {
  id: PropTypes.number,
  sx: PropTypes.shape({}),
  headingString: PropTypes.string,
};
export default ScoresAccordion;
