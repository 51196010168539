import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import styles from "./Slider.style";

const BasicSlider = ({
  marks,
  step,
  min,
  max,
  valueLabelDisplay,
  aria,
  defaultValue,
  name,
  onChange,
  value,
  valueLabelFormat,
  disabled,
  sx,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Slider
        key={name}
        sx={{ ...styles.slider, ...sx }}
        name={name}
        aria-label={aria}
        value={value}
        defaultValue={defaultValue}
        getAriaValueText={(value) => value}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
        step={step}
        marks={marks}
        min={min}
        max={max}
        onChange={(e) => onChange(e)}
        disabled={disabled}
      />
    </Box>
  );
};
BasicSlider.defaultProps = {
  marks: false,
  step: 1,
  min: 1,
  max: 5,
  valueLabelDisplay: undefined,
  aria: undefined,
  value: undefined,
  defaultValue: undefined,
  name: "slider",
  onChange: () => {},
  valueLabelFormat: () => {},
  disabled: false,
  sx: undefined,
};

BasicSlider.propTypes = {
  sx: PropTypes.shape({}),
  marks: PropTypes.bool,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  valueLabelDisplay: PropTypes.string,
  aria: PropTypes.string,
  defaultValue: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
  valueLabelFormat: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BasicSlider;
