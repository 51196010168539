import React from "react";
import { Route, Switch } from "react-router-dom";
import Settings from "pages/settings/Settings";
import NoRouteFound from "pages/errors/LoggedIn/404";
import SelfAssessment from "pages/self-assessment/collaborator-self-assessment/CollaboratorSelfAssessment";
import NedsPage from "pages/neds/NedsPage";

const CollaboratorUserRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={SelfAssessment} />
      <Route exact path="/neds-guide" component={NedsPage} />
      <Route exact path="/settings" component={Settings} />
      <Route path="*" component={NoRouteFound} />
    </Switch>
  );
};

CollaboratorUserRoutes.propTypes = {};
export default CollaboratorUserRoutes;
