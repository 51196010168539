import React from "react";
import { Route, Switch } from "react-router-dom";
import { activities } from "lib/permissions";
import ProtectedRoute from "../ProtectedRoutes";
import SuperAdminDashboard from "pages/dashboards/super-admin/SuperAdminDashboard";
import UserManagement from "pages/users/UserManagement/SuperAdmin/SuperAdminUserManagement";
import EditUser from "pages/users/UserManagement/SuperAdmin/SuperAdminEditUser";
import Companies from "pages/companies/companies/Companies";
import EditCompany from "pages/companies/companies/EditCompany";
import NoRouteFound from "pages/errors/LoggedIn/404";
import Settings from "pages/settings/Settings";
import Questionnaires from "pages/questionnaires/questionnaires/Questionnaires";
import Questionaire from "pages/questionnaires/questionnaire/Questionnaire";
import IndividualUsersPage from "pages/users/UserManagement/SuperAdmin/IndividualUsersPage";
import NedsPage from "pages/neds/NedsPage";

const SuperAdminRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/users"
        component={UserManagement}
        activities={[activities.SUPER_ADMIN_USER_MANAGEMENT]}
      />
      <ProtectedRoute
        exact
        path="/companies"
        component={Companies}
        activities={[activities.COMPANY_MANAGEMENT]}
      />
      <ProtectedRoute
        exact
        path="/companies/:id"
        component={EditCompany}
        activities={[activities.COMPANY_MANAGEMENT]}
      />
      <ProtectedRoute
        exact
        path="/users/:id"
        component={EditUser}
        activities={[activities.COMPANY_MANAGEMENT]}
      />
      <ProtectedRoute
        exact
        path="/questionnaires"
        component={Questionnaires}
        activities={[activities.SURVEY_CREATION]}
      />
      <ProtectedRoute
        exact
        path="/questionnaire/:id"
        component={Questionaire}
        activities={[activities.SURVEY_CREATION]}
      />
      <ProtectedRoute
        exact
        path="/individuals"
        component={IndividualUsersPage}
        activities={[activities.SUPER_ADMIN_USER_MANAGEMENT]}
      />
      <ProtectedRoute
        exact
        path="/"
        component={SuperAdminDashboard}
        activities={[activities.SUPER_ADMIN_DASHBOARD]}
      />
      <Route exact path="/neds-guide" component={NedsPage} />
      <Route exact path="/settings" component={Settings} />
      <Route path="*" component={NoRouteFound} />
    </Switch>
  );
};

SuperAdminRoutes.propTypes = {};
export default SuperAdminRoutes;
