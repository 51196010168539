import reducers from "reducers/form-errors-reducer";
import errorCodes from "lib/error-codes";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "lastName") {
      return reducers.createFormErrorsHandler(
        "lastName",
        "There was an issue with the last name field.",
        errorDispatch
      );
    }

    if (errorData.field === "firstName") {
      return reducers.createFormErrorsHandler(
        "firstName",
        "There was an issue with the first name field.",
        errorDispatch
      );
    }

    if (errorData.field === "email") {
      if (errorData.code === errorCodes.NOT_UNIQUE) {
        return reducers.createFormErrorsHandler(
          "email",
          "This email is not unique, please use another.",
          errorDispatch
        );
      }

      return reducers.createFormErrorsHandler(
        "email",
        "There was an issue with the email field.",
        errorDispatch
      );
    }
  } else {
    throw err;
  }
};

export default errorHandler;
