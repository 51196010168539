import stylesConfig from "theme/config";

const styles = {
  heading: {
    color: stylesConfig.colors.black,
    fontWeight: "600",
  },
  note: {
    primaryBackground: stylesConfig.colors.white,
    secondaryBackground: stylesConfig.colors.toupe,
    padding: `calc(${stylesConfig.spacing.standard} + 6px) calc(${stylesConfig.spacing.small} + 8px)`,
    borderTop: `1px solid #DEE2E4`,
    userName: {
      fontWeight: "600",
      color: stylesConfig.colors.black,
    },
  },
};

export default styles;
