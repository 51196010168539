import React, { useState, useEffect, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import reducers from "reducers/form-errors-reducer";
import referenceDataService from "services/reference-data/reference-data";
import Select from "components/core/form/Select/Select";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./OnboardingForm.error.handler";
import styles from "./OnboardingForm.style";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import organisationService from "services/organisation/organisation-service";
import { GlobalContext } from "context/GlobalContext";
import CircularLoader from "components/core/loaders/circular/Circular";
const intialErrorState = {
  employees: null,
  turnover: null,
  industry: null,
};

const getQuestionData = async () => {
  const questions = await referenceDataService.getOrganisationSizingQuestions();
  return questions.map((question) => {
    return {
      ...question,
      options: question.options.map((option) => {
        return {
          label: option.label,
          value: option.id,
        };
      }),
    };
  });
};
const Onboarding = ({ onComplete }) => {
  const { user } = useContext(GlobalContext);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );
  const [data, updateData] = useState([]);
  const [dataLoading, updateDataLoading] = useState(true);
  const [loading, updateLoading] = useState(false);

  useEffect(() => {
    const getQuestions = async () => {
      const response = await getQuestionData();
      updateData(response);
      updateDataLoading(false);
    };

    getQuestions();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);
    try {
      const payload = {
        employees: e.target.employees.value,
        turnover: e.target.turnover.value,
        industry: e.target.industry.value,
      };
      await organisationService.patchOrganisation(user.organisation, payload);
      onComplete();
    } catch (err) {
      updateLoading(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };
  return (
    <form onSubmit={onSubmit} style={styles.form}>
      {dataLoading ? (
        <CircularLoader contanierSx={styles.loader} />
      ) : (
        data.length &&
        data.map((question) => {
          return (
            <div key={question.fieldName}>
              <Select
                id={question.fieldName}
                error={errorState[question.fieldName] ? true : false}
                label={question.questionText}
                cy={`${question.fieldName}-field`}
                options={question.options}
                onChange={() =>
                  reducers.clearFieldError(
                    question.fieldName,
                    errorState,
                    errorDispatch
                  )
                }
                sx={styles.input}
                helperText={errorState[question.fieldName]}
                required={question.required}
              />
            </div>
          );
        })
      )}
      <PrimaryButton
        type="submit"
        text="Continue"
        loading={loading}
        style={styles.button}
      />
    </form>
  );
};

Onboarding.defaultProps = {
  onComplete: () => {},
};

Onboarding.propTypes = {
  onComplete: PropTypes.func,
};
export default Onboarding;
