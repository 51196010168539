import React, { useState, useEffect, useCallback } from "react";
import Table from "components/data-display/Table/Table";
import ScoresAccordion from "../ScoresAccordion/ScoresAccordion";
import reportsService from "services/reporting/reporting-service";
import HeadingMedium from "components/core/typography/headings/medium/HeadingMedium";
import styles from "./OrganisaionScoresTable.style";
import _ from "lodash";

const Companies = () => {
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companies, setCompanies] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getCompaniesFunc = useCallback(
    async (nextPage = 0, updateMessage) => {
      const response =
        await reportsService.getOrganisationSelfAssessmentResults(
          nextPage,
          rowsPerPage
        );
      if (!response || response.message) {
        setNoResultsMessage(
          "There was a problem fetching from the server, please try again. If the issue persists, please contact the Resilience First team."
        );
        return;
      }
      setPage(nextPage);
      setTotal(response.total);
      setRowsPerPage(response.pageSize);
      const companiesArray = response.result.map((company) => {
        return {
          id: company.id,
          name: company.name,
          size: company.size,
          userId: company.userId,
          categories: company.categories,
          fields: [
            company.name,
            _.capitalize(company.size),
            {
              Component: ScoresAccordion,
              value: company.results[0].result,
              data: company.results,
            },
          ],
        };
      });
      setCompanies(companiesArray);
      if (updateMessage && response && response.users.length === 0)
        setNoResultsMessage(
          "There are currently no companies with completed self assessments."
        );
    },
    [rowsPerPage]
  );

  useEffect(() => {
    const getCompanies = async () => {
      await getCompaniesFunc();
      setIsPageLoading(false);
    };

    getCompanies();
  }, [getCompaniesFunc]);

  const getCompaniesHandler = async (nextPage) => {
    await getCompaniesFunc(nextPage);
  };

  const tableFormat = ["Company Name", "Company Size", "Resilience Result"];

  return (
    <>
      {!isPageLoading && (
        <>
          <HeadingMedium sx={styles.heading}> Results By Company</HeadingMedium>
          <Table
            sx={styles.table}
            data={companies}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            getDataHandler={getCompaniesHandler}
            noDataComponent={
              noResultsMessage ||
              "There are currently no companies with completed self assessments."
            }
            tableFormat={tableFormat}
            hideOptions
          />
        </>
      )}
    </>
  );
};

export default Companies;
