import React, { useEffect, useState } from "react";
import usersService from "services/users/users-service";
import Table from "components/data-display/Table/Table";
import utils from "lib/utils";
import ExportCSV from "components/reporting/ExportCSV/ExportCSV";
import { Stack } from "@mui/material";

const IndividualUserManagementList = () => {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [noResultsMessage, setNoResultsMessage] = useState("");

  const headers = [
    { label: "Date Added", key: "dateCreated" },
    { label: "Company Name", key: "organisation" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email Address", key: "email" },
    { label: "Job Title", key: "jobTitle" },
  ];

  const dataProvider = async (pageOnly) => {
    const params = pageOnly
      ? { page: page, pageSize: rowsPerPage }
      : { page: 0, pageSize: total };
    const resp = await usersService.getIndividualUsers(params);

    const dataArray = resp.users.map((data) => {
      return {
        dateCreated: utils.getUIDateString(data.dateCreated),
        organisation: data.organisation,
        jobTitle: data.jobTitle,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      };
    });

    return dataArray;
  };

  const getDataFunc = async (nextPage = 0, updateMessage) => {
    const params = {
      page: nextPage,
      pageSize: rowsPerPage,
    };

    const response = await usersService.getIndividualUsers(params);

    if (!response || response.message) {
      setNoResultsMessage(
        "There was a problem fetching from the server, please try again. If the issue persists, please contact the Resilience First team."
      );
      return;
    }
    setPage(nextPage);
    setTotal(response.total);
    setRowsPerPage(response.pageSize);

    const dataArray = response.users.map((data) => {
      return {
        id: data.id,
        name: data.name,
        dateCreated: data.dateCreated,
        status: data.active,
        fields: [
          utils.getUIDateString(data.dateCreated),
          data.organisation,
          data.firstName,
          data.lastName,
          data.jobTitle,
          data.email,
        ],
      };
    });

    setTableData(dataArray);

    if (updateMessage && response && response.users.length === 0)
      setNoResultsMessage("No Non-Executive Directors found.");
  };

  useEffect(() => {
    const getData = async () => {
      await getDataFunc();
      setIsPageLoading(false);
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const getDataHandler = async (nextPage) => {
    await getDataFunc(nextPage);
  };

  const tableFormat = [
    "Date Added",
    "Company Name",
    "First Name",
    "Last Name",
    "Job Title",
    "Email Address",
  ];

  return (
    <>
      {!isPageLoading && tableData.length > 0 && (
        <>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ pb: 1 }}
          >
            <ExportCSV
              text="Export"
              headers={headers}
              filename="non-exec-export-all.csv"
              dataProvider={dataProvider}
              cy="cy-csv-download-all"
            />
            <ExportCSV
              text="Export Page Only"
              headers={headers}
              filename={`non-exec-export-page${page + 1}.csv`}
              dataProvider={dataProvider}
              cy="cy-csv-download-page"
              pageOnly
            />
          </Stack>
          <Table
            data={tableData}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            getDataHandler={getDataHandler}
            noDataComponent={
              noResultsMessage || "There are no Non-Exec Directors."
            }
            tableFormat={tableFormat}
            hideOptions
          />
        </>
      )}
    </>
  );
};

export default IndividualUserManagementList;
