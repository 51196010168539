import api from "lib/http";

const patchOrganisation = async (id, data = {}) => {
  const result = await api(
    "patch",
    `organisations/${id}`,
    data,
    true,
    true,
    true
  );
  return result.data;
};

const getOrganisationUsers = async (id) => {
  const result = await api(
    "get",
    `organisations/${id}/users`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const switchPrimaryUser = async (id, body = {}) => {
  const result = await api(
    "post",
    `organisations/${id}/switch-primary`,
    body,
    true,
    true,
    true
  );
  return result.data;
};

const archiveOrganisation = async (id) => {
  const result = await api(
    "delete",
    `organisations/${id}`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const service = {
  patchOrganisation,
  getOrganisationUsers,
  switchPrimaryUser,
  archiveOrganisation,
};

export default service;
