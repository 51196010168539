import errorCodes from "lib/error-codes";
import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "user") {
      if (errorData.code === errorCodes.NOT_UNIQUE) {
        return reducers.createFormErrorsHandler(
          "user",
          "User email address already exists.",
          errorDispatch
        );
      } else if (errorData.code === errorCodes.INCORRECT_FORMAT) {
        return reducers.createFormErrorsHandler(
          "user",
          "User email address is not in the correct format.",
          errorDispatch
        );
      } else {
        return reducers.createFormErrorsHandler(
          "user",
          "Email address is not valid.",
          errorDispatch
        );
      }
    }
  } else {
    throw err;
  }
};

export default errorHandler;
