import stylesConfig from "theme/config";

const styles = {
  noResultsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: stylesConfig.spacing.standard,
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
  },
  heading: {
    marginBottom: stylesConfig.spacing.large,
  },
  p: {
    marginBottom: 0,
  },
};

export default styles;
