import React, { useReducer, useState } from "react";
import { useParams } from "react-router";
import reducers from "reducers/form-errors-reducer";
import PropTypes from "prop-types";
import Input from "components/core/form/Input/Input";
import Checkbox from "components/core/form/Checkbox/Checkbox";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import styles from "./AddQuestion.style";
import questionnaireService from "services/questionnaire/questionnaire-service";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./AddQuestion.error.handler";
import ReferenceDataSelect from "components/core/form/ReferenceDataSelect/ReferenceDataSelect";
import referenceDataService from "services/reference-data/reference-data";
import Grid from "@mui/material/Grid";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
const intialErrorState = {
  themeId: null,
  isOptional: null,
  question: null,
  lowestPointsValueLabel: null,
  highestPointsValueLabel: null,
  scoreType: null,
};

const AddQuestion = ({ onComplete, categoryId }) => {
  const [loading, updateLoading] = useState(false);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const params = useParams();
  const id = params.id;

  const getThemeOptions = async () => {
    const options = await referenceDataService.getSelfAssessmentThemeLabels({
      categoryId,
    });
    return options;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    reducers.clearFieldError("general", errorState, errorDispatch);
    updateLoading(true);
    try {
      const payload = {
        themeId: e.target.themeId.value,
        isOptional: e.target.isOptional.checked,
        question: e.target.question.value,
        lowestPointsValueLabel: e.target.lowestPointsValueLabel.value,
        highestPointsValueLabel: e.target.highestPointsValueLabel.value,
        scoreType: e.target.scoreType.value,
      };
      const question = await questionnaireService.addAQuestionToQuestionnaire(
        id,
        categoryId,
        payload
      );
      onComplete({ ...payload, id: question.id, categoryId: categoryId });
    } catch (err) {
      updateLoading(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };
  return (
    <>
      <form onSubmit={onSubmit} style={styles.form}>
        <Grid container spacing={2} sx={styles.container}>
          {errorState.general && (
            <Grid item xs={12}>
              <ErrorHelperText
                sx={styles.generalError}
                text={errorState.general}
              />
            </Grid>
          )}
          <Grid item xs={12} lg={10}>
            <ReferenceDataSelect
              id="themeId"
              error={errorState.themeId ? true : false}
              label={"Theme"}
              cy="theme-field"
              onChange={() =>
                reducers.clearFieldError("themeId", errorState, errorDispatch)
              }
              sx={styles.themeSelect}
              helperText={errorState.themeId}
              service={getThemeOptions}
              value=""
              required
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <Checkbox
              id="isOptional"
              label="Is optional?"
              cy="is-optional-checkbox"
              helperText={errorState.isOptional}
              error={errorState.isOptional ? true : false}
              onChange={() =>
                reducers.clearFieldError(
                  "isOptional",
                  errorState,
                  errorDispatch
                )
              }
            />
          </Grid>
        </Grid>
        <Input
          id="question"
          cy="question-field"
          label="Statement"
          required
          multiline
          sx={styles.input}
          error={errorState.question ? true : false}
          onChange={() =>
            reducers.clearFieldError("question", errorState, errorDispatch)
          }
          helperText={errorState.question}
          inputProps={{ maxLength: 500 }}
        />
        <Input
          id="lowestPointsValueLabel"
          cy="lowest-points-value-field"
          label="Lowest Points Value"
          required
          sx={styles.input}
          error={errorState.lowestPointsValueLabel ? true : false}
          onChange={() =>
            reducers.clearFieldError(
              "lowestPointsValueLabel",
              errorState,
              errorDispatch
            )
          }
          helperText={errorState.lowestPointsValueLabel}
          inputProps={{ maxLength: 50 }}
        />
        <Input
          id="highestPointsValueLabel"
          cy="highest-points-value-field"
          label="Highest Points Value"
          required
          sx={styles.input}
          error={errorState.highestPointsValueLabel ? true : false}
          onChange={() =>
            reducers.clearFieldError(
              "highestPointsValueLabel",
              errorState,
              errorDispatch
            )
          }
          helperText={errorState.highestPointsValueLabel}
          inputProps={{ maxLength: 50 }}
        />
        <ReferenceDataSelect
          id="scoreType"
          error={errorState.scoreType ? true : false}
          label={"Weighting"}
          cy="score-type-field"
          onChange={() =>
            reducers.clearFieldError("scoreType", errorState, errorDispatch)
          }
          helperText={errorState.scoreType}
          service={referenceDataService.getScoringOptions}
          value="DEFAULT"
          required
        />
        <PrimaryButton
          type="submit"
          text="Save"
          loading={loading}
          style={styles.button}
        />
      </form>
    </>
  );
};

AddQuestion.defaultProps = {
  onComplete: () => {},
};

AddQuestion.propTypes = {
  onComplete: PropTypes.func,
  categoryId: PropTypes.number.isRequired,
};
export default AddQuestion;
