import React, { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import InternalLink from "components/core/links/Internal/InternalLink";
import EditBox from "components/core/edit-box/EditBox";
import Loader from "components/core/loaders/bouncies/Bouncies";
import styles from "./EditCompany.style";
import PatchOrgForm from "components/forms/organisations/PatchOrg/PatchOrg";
import PatchPrimaryUser from "components/forms/organisations/SwitchPrimaryUser/SwitchPrimaryUser";
import PrimaryUserDisplayOnly from "components/forms/organisations/SwitchPrimaryUser/PrimaryUserDisplayOnly";
import { useParams } from "react-router-dom";

const EditCompany = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const [editCompanyDetail, updateEditCompanyDetail] = useState(false);
  const [editPrimaryUser, updateEditPrimaryUser] = useState(false);
  const { id } = useParams();

  const isLoading = false;
  return (
    <LoggedIn
      heading="View Company"
      header={
        <InternalLink style={styles.link} to="/companies">
          <KeyboardArrowLeftIcon />
          Back
        </InternalLink>
      }
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} xl={8}>
                <EditBox
                  heading={"Company Details"}
                  onActive={updateEditCompanyDetail}
                  active={editCompanyDetail}
                  idleState={
                    <PatchOrgForm
                      data={{ id: id }}
                      disableAll={true}
                      hideSaveButton={true}
                    />
                  }
                  editState={
                    <PatchOrgForm
                      data={{ id: id }}
                      onComplete={() => updateEditCompanyDetail(false)}
                    />
                  }
                ></EditBox>
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <EditBox
                  heading={"Primary User"}
                  onActive={updateEditPrimaryUser}
                  active={editPrimaryUser}
                  idleState={<PrimaryUserDisplayOnly id={id} />}
                  editState={
                    <PatchPrimaryUser
                      data={{ id: id }}
                      onComplete={() => updateEditPrimaryUser(false)}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </LoggedIn>
  );
};

export default EditCompany;
