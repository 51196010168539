import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import BasicSlider from "components/core/form/Slider/Slider";
import HeadingMedium from "components/core/typography/headings/medium/HeadingMedium";
import styles from "./LikertScale.style";
const LikertScale = ({
  sx,
  marks,
  step,
  min,
  max,
  valueLabelDisplay,
  aria,
  defaultValue,
  title,
  lowLabel,
  highLabel,
  name,
  onChange,
  value,
  valueLabelFormat,
  disabled,
}) => {
  return (
    <Box>
      <Box sx={styles.heading}>
        <HeadingMedium>{title}</HeadingMedium>
      </Box>
      <Box sx={styles.scale}>
        <BasicSlider
          sx={sx}
          name={name}
          marks={marks}
          step={step}
          min={min}
          max={max}
          valueLabelDisplay={valueLabelDisplay}
          aria={aria}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          valueLabelFormat={valueLabelFormat}
          disabled={disabled}
        />
        {lowLabel && highLabel && (
          <Box sx={styles.labels}>
            {lowLabel && <Box> {lowLabel} </Box>}
            {highLabel && <Box> {highLabel} </Box>}
          </Box>
        )}
      </Box>
    </Box>
  );
};

LikertScale.defaultProps = {
  marks: false,
  step: 1,
  min: 1,
  max: 5,
  valueLabelDisplay: undefined,
  aria: undefined,
  defaultValue: undefined,
  value: undefined,
  onChange: () => {},
  valueLabelFormat: () => {},
  disabled: false,
  sx: undefined,
};

LikertScale.propTypes = {
  sx: PropTypes.shape({}),
  marks: PropTypes.bool,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  valueLabelDisplay: PropTypes.string,
  aria: PropTypes.string,
  defaultValue: PropTypes.number,
  value: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  valueLabelFormat: PropTypes.func,
  disabled: PropTypes.bool,
};

export default LikertScale;
