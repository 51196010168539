import React, { useRef } from "react";
import PropTypes from "prop-types";
import Cookie from "universal-cookie";
import _ from "lodash";
import authenticationService from "../services/authentication/authentication-service";
import userService from "../services/user/user-service";
import userTypes from "../lib/user-types";
const AUTH_COOKIE_NAME = "rf-token";
const cookies = new Cookie();

export const GlobalContext = React.createContext({
  user: null,
  loginHandler: () => {},
  getUserFromLocalStorage: () => {},
});

const storeUserInLocalStorage = (userData) => {
  if (!userData) {
    localStorage.removeItem("user");
    return;
  }

  // Only storing what we actually need just now.
  const redactedUserData = {
    id: userData.id,
    email: userData.email,
    activities: userData.activities,
    type: userData.type,
    onboarded: userData.onboarded,
  };

  if (_.has(userData, "organisation")) {
    redactedUserData.organisation = userData.organisation;
  }

  localStorage.setItem("user", JSON.stringify(redactedUserData));
};

const getUserFromLocalStorage = () => {
  const user = localStorage.getItem("user");

  if (user) {
    return JSON.parse(user);
  }

  return undefined;
};

const GlobalContextProvider = ({ children }) => {
  const user = useRef(getUserFromLocalStorage());

  const logoutHandler = async () => {
    storeUserInLocalStorage(undefined);
    await cookies.remove(AUTH_COOKIE_NAME);

    user.current = null;

    window.location.replace("/login");
  };

  // login handler, utilised by the login screen.
  const loginHandler = async (email, password) => {
    await clearUser();

    const response = await authenticationService.login(email, password);
    await cookies.set(AUTH_COOKIE_NAME, response.data.token, { path: "/" });

    const userData = {
      id: response.data.id,
      email: response.data.email,
      type: response.data.type,
      activities: response.data.activities,
      onboarded: response.data.onboarded,
    };

    // super admins will not have orgs so we'll check for the key before we add
    if (_.has(response.data, "organisation")) {
      userData.organisation = response.data.organisation.id;
    }

    // set user to onboarded
    // admins must complete onboarding questions before they are onboarded
    if (!response.data.onboarded && userData.type !== userTypes.ADMIN) {
      await userService.patchUser(userData.id, { onboarded: true });
      userData.onboarded = true;
    }

    user.current = userData;
    storeUserInLocalStorage(userData);
    window.location.replace("/");

    return userData;
  };

  const automaticLoginHandler = async (authenticatedUser) => {
    await clearUser();

    const response = { data: authenticatedUser };
    await cookies.set(AUTH_COOKIE_NAME, response.data.token, { path: "/" });

    const userData = {
      id: response.data.id,
      email: response.data.email,
      type: response.data.type,
      activities: response.data.activities,
      onboarded: response.data.onboarded,
    };

    // super admins will not have orgs so we'll check for the key before we add
    if (_.has(response.data, "organisation")) {
      userData.organisation = response.data.organisation.id;
    }

    user.current = userData;
    storeUserInLocalStorage(userData);

    return userData;
  };

  const clearUser = async () => {
    // Clear up any existing sessions before attempting to login
    storeUserInLocalStorage(undefined);
    await cookies.remove(AUTH_COOKIE_NAME);
    user.current = null;
  };

  const updateUserData = (data = {}) => {
    const updatedUser = user.current;
    // we only need to update onboarded status
    // more keys can be added as required
    if (_.has(data, "onboarded")) {
      updatedUser.onboarded = data.onboarded;
    }
    user.current = updatedUser;
    storeUserInLocalStorage(updatedUser);
  };

  return (
    <GlobalContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        updateUserData,
        automaticLoginHandler,
        user: user.current,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node,
};

export default GlobalContextProvider;
