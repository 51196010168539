import BUSINESS_SIZES from "./business-sizes";

const uiStringBusinessSize = (size) => {
  return BUSINESS_SIZES.sizes[size];
};

const lib = {
  uiStringBusinessSize,
};

export default lib;
