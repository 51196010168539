import reducers from "reducers/form-errors-reducer";
import errorCodes from "lib/error-codes";
const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "themeId") {
      if (errorData.code === errorCodes.THEME_LIMIT_ALREADY_REACHED) {
        return reducers.createFormErrorsHandler(
          "themeId",
          "You cannot add any more mandatory statements of this theme to this category.",
          errorDispatch
        );
      }
      return reducers.createFormErrorsHandler(
        "themeId",
        "Is not a valid theme.",
        errorDispatch
      );
    }

    if (errorData.field === "isOptional") {
      return reducers.createFormErrorsHandler(
        "isOptional",
        'Failed on "isOptional" field.',
        errorDispatch
      );
    }

    if (errorData.field === "scoreType") {
      return reducers.createFormErrorsHandler(
        "scoreType",
        "Is not a recognised weighting.",
        errorDispatch
      );
    }

    if (errorData.field === "question") {
      return reducers.createFormErrorsHandler(
        "question",
        "Is not a valid statement",
        errorDispatch
      );
    }

    if (errorData.field === "lowestPointsValueLabel") {
      return reducers.createFormErrorsHandler(
        "lowestPointsValueLabel",
        "Is not a valid value for the lowest points label.",
        errorDispatch
      );
    }

    if (errorData.field === "highestPointsValueLabel") {
      return reducers.createFormErrorsHandler(
        "highestPointsValueLabel",
        "Is not a valid value for the highest points label.",
        errorDispatch
      );
    }

    if (errorData.code === errorCodes.INVALID_TYPE) {
      return reducers.createFormErrorsHandler(
        "general",
        "You cannot modify a questionnarie that is not a self assessment.",
        errorDispatch
      );
    }

    if (
      errorData.code === errorCodes.QUESTIONNAIRE_HAS_ALREADY_BEEN_MADE_ACTIVE
    ) {
      return reducers.createFormErrorsHandler(
        "general",
        "You cannot make further changes to this questionnaire as it has been previously set to active (published).",
        errorDispatch
      );
    }
  } else {
    throw err;
  }
};

export default errorHandler;
