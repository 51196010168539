import React, { useContext, useState, useEffect } from "react";
import SelfAssessmentContextProvider, {
  SelfAssessmentContext,
} from "context/SelfAssessmentContext/SelfAssessmentContext";
import RaisedBox from "components/core/raised-box/RaisedBox";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import styles from "./Results.styles";
import NoResults from "./NoResults/NoResults";
import Feedback from "./Feedback/Feedback";
import CircularLoader from "components/core/loaders/circular/Circular";

const Results = () => {
  const selfAssessmentContext = useContext(SelfAssessmentContext);
  const [showFeedBack, updateShowFeedback] = useState(false);

  useEffect(() => {
    const showFeedBack =
      selfAssessmentContext.activeSelfAssessment &&
      selfAssessmentContext.isComplete;
    updateShowFeedback(showFeedBack);
  }, [
    selfAssessmentContext.activeSelfAssessment,
    selfAssessmentContext.isComplete,
  ]);

  return (
    <>
      <HeadingSmall text="Self Assessment" sx={styles.heading} />
      <RaisedBox sx={{ padding: showFeedBack ? "1em" : 0 }}>
        {selfAssessmentContext.isLoading ? (
          <CircularLoader contanierSx={styles.loader} />
        ) : (
          <>
            {showFeedBack ? (
              <Feedback />
            ) : (
              <NoResults
                showButton={selfAssessmentContext.activeSelfAssessment}
              />
            )}
          </>
        )}
      </RaisedBox>
    </>
  );
};

Results.propTypes = {};

const ResultsWithContext = () => {
  return (
    <SelfAssessmentContextProvider>
      <Results />
    </SelfAssessmentContextProvider>
  );
};

export default ResultsWithContext;
