import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import utils from "lib/utils";
import Options from "./Options/Options";
import _ from "lodash";

const DataRow = ({ sx, data, optionActions, tableFormat, hideOptions }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handlePopoverClick = (event) => {
    setAnchorElement(event.target);
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setAnchorElement(null);
  };

  return (
    <TableRow key={`table-row-${data.id}`}>
      {data.fields.map((value, index) => {
        const column = _.kebabCase(tableFormat[index]);
        return (
          <TableCell
            sx={{ padding: "12px", ...sx }}
            key={`${data.id}-${utils.uniqueString()}`}
            data-cy={`row-${column}-${data.id}`}
          >
            {_.isObject(value) ? (
              <value.Component data={value.data} value={value.value} />
            ) : (
              value
            )}
          </TableCell>
        );
      })}
      {!hideOptions && (
        <TableCell key="options">
          <>
            <IconButton
              onClick={handlePopoverClick}
              data-cy={`row-action-${data.id}`}
            >
              <MoreVertIcon sx={{ color: "black" }} />
            </IconButton>

            <Options
              anchorElement={anchorElement}
              popoverOpen={popoverOpen}
              handlePopoverClose={handlePopoverClose}
              data={data}
            >
              {optionActions(data)}
            </Options>
          </>
        </TableCell>
      )}
    </TableRow>
  );
};

export default DataRow;
