import stylesConfig from "theme/config";

const styles = {
  background: {
    backgroundColor: stylesConfig.colors.primary,
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: stylesConfig.colors.white,
  },
  content: {
    width: "100%",
    padding: {
      xs: `${stylesConfig.spacing.veryLarge} ${stylesConfig.spacing.standard}`,
      md: 0,
    },
  },
  imgContainer: {
    display: { xs: "none", md: "flex" },
    maxWidth: "452px",
  },
  img: {
    borderRadius: "8px 0 0 8px",
    width: "452px",
    objectFit: "cover",
    height: "633px",
  },
};

export default styles;
