import React from "react";
import SplitPanel from "components/core/split-panel/SplitPanel";
import Box from "@mui/material/Box";
import RFPanelImg from "assets/blue-hi-res.png";
import styles from "./NoResults.style";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import InternalLink from "components/core/links/Internal/InternalLink";
const NoResults = ({ showButton }) => {
  return (
    <SplitPanel
      img={RFPanelImg}
      content={
        <Box sx={styles.noResultsContainer}>
          <HeadingSmall sx={styles.heading}>
            {" "}
            Start your resilience journey today.
          </HeadingSmall>

          <Paragraph sx={styles.p}>
            Resilience First, in conjunction with EY and Arup, is pleased to
            offer this <strong>Resilience Self-Assessment Tool</strong> to our
            members to assess their own level of resilience according to five
            categories – People, Place, Process, Performance and Preparation.
            The assessment can be completed by any organisation, of whatever
            size or industry/trade sector. It can also be revisited in the
            future to measure your resilience journey, and provides some useful
            tips on how to improve your resilience. This tool will enable you
            to:
          </Paragraph>

          <ul>
            <li>Understand your current organisational resilience status;</li>
            <li>Identify areas of strengths and for improvements;</li>
            <li>
              Track your organisational resilience ranking on an annual basis;
            </li>
            <li>Benchmark your position against your industry/trade peers.</li>
          </ul>

          <Paragraph>
            The information captured is confidential and for your unique use.
            Please click{" "}
            <a
              href="https://www.resiliencefirst.org/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to also view our privacy statement. So, do get started and see how
            you measure up!
          </Paragraph>

          {showButton && (
            <Box sx={styles.button}>
              <InternalLink theme="primaryButton" to={"/self-assessment"}>
                Self Assessment
              </InternalLink>
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default NoResults;
